import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './module-import.guard';
import { ErrorStateMatcher } from '@angular/material/core';
import { ErrorMatcher } from './services/error-matcher';

@NgModule({
  providers: [{ provide: ErrorStateMatcher, useClass: ErrorMatcher }],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
