// https://github.com/angular/components/issues/13770#issuecomment-553193486

import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[nexCancelCdkDrag]',
})
export class CancelCdkDragDirective implements OnInit, OnDestroy {
  $element: HTMLElement;

  constructor(el: ElementRef) {
    this.$element = el.nativeElement;
  }

  fireMouseUp($event: MouseEvent) {
    $event.cancelBubble = true;
  }

  ngOnDestroy(): void {
    this.$element.removeEventListener('mousedown', this.fireMouseUp);
  }

  ngOnInit(): void {
    this.$element.addEventListener('mousedown', this.fireMouseUp);
  }
}
