import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService, RestService, ServerResponse, ProtocolType, ResponseCode, StorageService } from '@core';

const TIMER = 'timer';

@Component({
  selector: 'nex-policy-dialog',
  template: `
    <h1 matDialogTitle>{{ (data === protocolType.Term ? 'REG_TERMS_OF_SERVICE' : 'REG_PRIVACY_POLICY') | translate }}</h1>

    <mat-dialog-content>
      <div *ngIf="protocolData && !isLoading" [innerHTML]="protocolData.body"></div>
      <nex-loading *ngIf="isLoading && !protocolData"></nex-loading>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button (click)="closeDialog($event, false)">{{ 'BUTTON_CANCEL' | translate }}</button>
      <button mat-button color="primary" (click)="closeDialog($event, true)" [disabled]="timer > 0 || !protocolData">
        {{ timer > 0 ? '(' + timer + ')' : '' }}
        {{ 'REG_AGREE_PROTOCOL' | translate }}
      </button>
    </mat-dialog-actions>
  `,
})
export class PolicyDialogComponent implements OnInit {
  timer = 0;
  auditInterval: number;
  isLoading = true;
  protocolData: { body: string; lang: string; version: string; _id: string };

  get protocolType() {
    return ProtocolType;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProtocolType,
    public dialogRef: MatDialogRef<PolicyDialogComponent>,
    private utils: UtilsService,
    private rest: RestService,
    private storage: StorageService,
  ) {}

  ngOnInit(): void {
    if (!this.data) {
      return;
    }

    this.initTimer();
    this.getProtocolData();
  }

  initTimer() {
    const _timer = this.storage.get(TIMER) || {};
    const haveRead = _timer[this.data];
    if (!haveRead) {
      this.timer = 10;
      this.auditInterval = window.setInterval(() => {
        if (this.timer === 0) {
          this.storage.set(TIMER, Object.assign(_timer, { [this.data]: true }));
          window.clearInterval(this.auditInterval);
        }
        this.timer--;
      }, 1000);
    }
  }

  getProtocolData() {
    const requrl = 'v2/user/' + this.data + '?lang=' + this.utils.getSelectedLang().code;

    this.utils.showSpinner(true);
    this.rest.getJson(requrl, null, false).subscribe((res: ServerResponse) => {
      this.utils.showSpinner(false);
      if (res.code === ResponseCode.Success) {
        setTimeout(() => {
          this.protocolData = res.data;
          this.isLoading = false;
        }, 200);
      }
    });
  }

  closeDialog(ev: MouseEvent, agree: boolean): void {
    ev.preventDefault();
    this.dialogRef.close(agree);
  }
}
