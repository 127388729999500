<div class="tree-table-container">
  <table #treetable mat-table [dataSource]="dataSource" [ngClass]="formatElevation()">
    <ng-container *ngFor="let column of displayedColumns; first as isFirst; index as i">
      <ng-container matColumnDef="{{ column }}">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{ 'vertical-separator': options.verticalSeparator }">
          {{ !!options.i18nColumns && options.i18nColumns.length > 0 ? options.i18nColumns[i] : (column | titlecase) }}
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ 'vertical-separator': options.verticalSeparator }">
          <div *ngIf="isFirst">
            <div class="value-cell">
              <div [innerHTML]="formatIndentation(element)"></div>
              <mat-icon
                [ngStyle]="{ visibility: element.children.length ? 'visible' : 'hidden' }"
                (click)="$event.stopPropagation(); onNodeClick(element)"
              >
                {{ element.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
              </mat-icon>
              <div>{{ element.value[column] }}</div>
            </div>
          </div>
          <div *ngIf="!isFirst">
            {{ element.value[column] }}
          </div>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="onRowClick(row)"
      [ngClass]="{ 'highlight-on-hover': options.highlightRowOnHover, 'highlight-on-click': row.isSelected }"
    ></tr>
  </table>
</div>
