export const GlobalVariable = Object.freeze({
  //#region 注册修改绑定的用户名长度
  REG_USER_NAME_MIN: 8,
  REG_USER_NAME_MAX: 40,
  //#endregion

  //#region 注册修改绑定的密码长度
  REG_USER_PWD_MIN: 8,
  REG_USER_PWD_MAX: 16,
  //#endregion

  //#region 设备AP密码长度
  AP_PWD_MIN: 8,
  AP_PWD_MAX: 16,
  //#endregion

  VERIFY_TYPE_PHONE: 'phone',
  VERIFY_TYPE_EMAIL: 'email',

  VERIFY_COUNTER: 30,
});

export const EsignConfig = Object.freeze({
  width: 76,
  height: 48,
  limitImage: 6,
  limitText: 10,
});

export const PosterConfig = Object.freeze({
  limitImage: 8, // 海报屏素材数量
  limitText: 8, // 海报屏文字数量
  limitSlide: 20,
  previewMaxWidth: 384,
  previewMinWidth: 270, // 根据3比4的比例，取了一个与小屏幕尺寸相近的数值
});

export const COLOR_ITEMS = [
  '#ea986c', '#eeb174', '#f3ca7e', '#f9f28b', '#c8db8c', '#aad08f', '#87c38f', '#83c6c2', // 1 or 1
  '#e16632', '#e98f36', '#efb336', '#ede308', '#afcd51', '#7cba59', '#36ab60', '#1baba8', // 3 or 2
  '#d81e06', '#e0620d', '#ea9518', '#f5d32c', '#8cbb1a', '#2ba515', '#0e932e', '#0c9890', // 5 or 3
  '#7dc5eb', '#87a7d6', '#8992c8', '#a686ba', '#bd8cbb', '#be8dbd', '#e89abe', '#e8989a', // 2 or 4
  '#17ace3', '#3f81c1', '#4f68b0', '#594d9c', '#82529d', '#a4579d', '#db649b', '#dd6572', // 4 or 5
  '#1295db', '#0061b2', '#0061b0', '#004198', '#122179', '#88147f', '#d3227b', '#d6204b', // 6 or 6
  '#ff0000', '#f7f700', '#1afa29', '#037cfa', '#a005ea', '#6A4A23', '#986F36', '#c9b258', // diy
  '#ffffff', '#e6e6e6', '#dbdbdb', '#cdcdcd', '#9a9a9a', '#707070', '#515151', '#000000'// black and white
];


export const FontaLanguages = { zh: '中文', en: '英文', fr: '法语', ja: '日语', es: '西班牙语', de: '德语' };