<h1 matDialogTitle>{{ 'DESIGNER_AUDIT_DIALOG_TITLE' | translate }}</h1>
<mat-dialog-content>
  <div class="esign-previewer" *ngIf="[ContentType.PoTemplate, ContentType.PoGallery].indexOf(data.type) < 0">
    <nex-previewer [contentType]="data.type" [contentData]="data.item"> </nex-previewer>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="16px">
    <div fxFlex>
      <form [formGroup]="dialogForm">
        <mat-form-field
          class="full-width"
          style="margin-bottom: 16px;"
          hintLabel="{{ 'DESIGNER_TEMPLATES_HINT_NAME' | translate: { limit: nameMaxLength } }}"
        >
          <input
            #input
            matInput
            formControlName="name"
            autocomplete="off"
            required
            placeholder="{{ 'DESIGNER_TEMPLATES_NAME' | translate }}"
          />
          <mat-hint align="end">{{ input.value?.length || 0 }}/{{ nameMaxLength }}</mat-hint>
          <mat-error *ngIf="dialogForm.get('name')?.hasError('required')">
            {{ 'DESIGNER_TEMPLATES_ERROR_NAME' | translate }}
          </mat-error>
          <mat-error *ngIf="dialogForm.get('name')?.hasError('maxlength')">
            {{ 'DESIGNER_TEMPLATES_HINT_NAME' | translate: { limit: nameMaxLength } }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" style="margin-bottom: 16px;">
          <input
            matInput
            formControlName="price"
            type="text"
            placeholder="{{ 'DESIGNER_TEMPLATES_PRICE' | translate }}"
            required
          />
          <mat-hint>{{ 'DESIGNER_PRICE_HINT_LABEL' | translate: { limit: priceLimit } }}</mat-hint>
          <mat-error *ngIf="dialogForm.get('price')?.hasError('required')">
            {{ 'DESIGNER_PRICE_REQUIRED' | translate }}
          </mat-error>
          <mat-error
            *ngIf="dialogForm.get('price')?.hasError('maxlength') || dialogForm.get('price')?.hasError('pattern')"
          >
            {{ 'DESIGNER_PRICE_HINT_LABEL' | translate: { limit: priceLimit } }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          *ngIf="[ContentType.PoTemplate, ContentType.EsTemplate].indexOf(data.type) >= 0"
          class="full-width"
        >
          <input
            matInput
            type="text"
            [value]="itemData | templateTypeText | async"
            readonly
            placeholder="{{ 'DESIGNER_TEMPLATES_TYPE' | translate }}"
          />
        </mat-form-field>
        <ng-container *ngIf="data.type === ContentType.PoGallery">
          <mat-form-field class="full-width">
            <input
              matInput
              type="text"
              [value]="itemData.type | galleryTypeText | async"
              readonly
              placeholder="{{ 'DESIGNER_GALLERIES_TYPE' | translate }}"
            />
          </mat-form-field>
          <mat-form-field class="full-width">
            <input
              matInput
              type="text"
              placeholder="{{ 'DESIGNER_TEMPLATES_IS_CUSTOMIZE' | translate }}"
              value="{{ itemData.customization | convertBool: 'COMMON_TEXT_YES':'COMMON_TEXT_NO' | translate }}"
              readonly
            />
          </mat-form-field>
          <mat-form-field class="full-width" *ngIf="itemData.customization">
            <input
              matInput
              type="text"
              placeholder="{{ 'DESIGNER_GALLERIES_INPUT_CUSTOM_TEXT' | translate }}"
              value="{{ (itemData.text || []).join(',') }}"
              readonly
            />
          </mat-form-field>
        </ng-container>
        <mat-form-field
          *ngIf="[ContentType.PoTemplate, ContentType.EsTemplate].indexOf(data.type) >= 0"
          class="full-width"
        >
          <input
            matInput
            type="textarea"
            [value]="itemData.description"
            readonly
            placeholder="{{ 'DESIGNER_TEMPLATES_DESCRIPTION' | translate }}"
          />
        </mat-form-field>

        <ng-container
          *ngIf="
            [ContentType.EsTemplate, ContentType.EsAnimatedGallery, ContentType.EsBackgroundGallery].indexOf(
              data.type
            ) >= 0
          "
        >
          <mat-form-field class="full-width" *ngIf="data.type !== ContentType.EsTemplate">
            <input
              matInput
              type="text"
              [value]="itemData.contentType | esGalleryContentTypeText | async"
              readonly
              placeholder="{{ 'ESIGN_TEXT_CONTENT_TYPE' | translate }}"
            />
          </mat-form-field>
          <mat-form-field class="full-width">
            <input
              matInput
              type="text"
              placeholder="{{ 'ESIGN_SCREEN_ORIEN' | translate }}"
              value="{{ itemData.screenOrientation | screenOrientationText | async }}"
              readonly
            />
          </mat-form-field>
          <mat-form-field class="full-width">
            <input
              matInput
              type="text"
              placeholder="{{ 'ESIGN_SCREEN_COUNT' | translate }}"
              value="{{ itemData.screenCount }}"
              readonly
            />
          </mat-form-field>
        </ng-container>

        <label id="audit-group-label">
          {{ 'TEMPLATES_TEXT_CHOOSE_AUDIT' | translate }}
          <span style="color: red;">*</span>
        </label>
        <mat-radio-group aria-labelledby="audit-group-label" class="audit-radio-group" formControlName="auditStatus">
          <mat-radio-button
            color="primary"
            *ngFor="let item of auditRadioList"
            class="audit-radio-button"
            [value]="item.value"
          >
            {{ item.text | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-form-field
          class="full-width"
          style="margin-bottom: 16px;"
          hintLabel="{{ 'COMMON_TEXT_PARAGRAPH_LIMIT' | translate }}"
        >
          <textarea
            #textarea
            class="audit-reason"
            matInput
            formControlName="message"
            placeholder="{{ 'TEMPLATES_TEXT_AUDIT_REJECTION_REASON' | translate }}"
          ></textarea>
          <mat-hint align="end">{{ textarea.value?.length || 0 }}/4~100</mat-hint>
          <mat-error *ngIf="dialogForm.get('message')?.hasError('required')"
            >{{ getNameErrorMessage() | translate }}
          </mat-error>
        </mat-form-field>
      </form>
      <p class="label-category">{{ 'DESIGNER_CATEGORY' | translate }} <span style="color: red;">*</span></p>
      <ul class="checkbox-list" *ngIf="isCategoryLoaded; else loadingTpl">
        <!-- <li *ngFor="let parent of categories">
          <mat-checkbox
            color="primary"
            [checked]="parent.checked"
            [indeterminate]="parent.indeterminate"
            (change)="onParentChange($event, parent)"
          >
            {{ parent.className }}
          </mat-checkbox>
          <div class="child">
            <div class="child-item" *ngFor="let child of parent.childs">
              <mat-checkbox color="primary" [checked]="child.checked" (change)="onChildChange($event, child, parent)">
                {{ child.className }}
              </mat-checkbox>
            </div>
          </div>
        </li> -->
        <li *ngFor="let parent of categories">
          <span *ngIf="isFont && parent.key === 'LANGUAGE'; else checkboxParent"> {{ parent.className }}</span>
          <ng-template #checkboxParent>
            <mat-checkbox
              color="primary"
              [checked]="parent.checked"
              [indeterminate]="parent.indeterminate"
              (change)="onParentChange($event, parent)"
            >
              {{ parent.className }}
            </mat-checkbox>
          </ng-template>
          <div class="child">
            <div class="child-item" *ngFor="let child of parent.childs">
              <ng-container *ngIf="isFont && parent.key === 'LANGUAGE'; else checkboxItem">
                <mat-radio-button
                  color="primary"
                  [checked]="child.checked"
                  (change)="onChildChange($event, child, parent)"
                >
                  {{ child.className }}
                </mat-radio-button>
              </ng-container>
              <ng-template #checkboxItem>
                <mat-checkbox color="primary" [checked]="child.checked" (change)="onChildChange($event, child, parent)">
                  {{ child.className }}
                </mat-checkbox>
              </ng-template>
            </div>
          </div>
        </li>
      </ul>
      <ng-template #loadingTpl>
        <nex-loading></nex-loading>
      </ng-template>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div fxFlex="32">
      <div *ngIf="[ContentType.PoGallery, ContentType.PoTemplate].indexOf(data.type) >= 0" style="margin-bottom: 16px;">
        <nex-previewer [contentType]="data.type" [contentData]="data.item" [scale]="0.65"> </nex-previewer>
      </div>
      <div class="log-item" *ngFor="let log of reviewLog; last as isLast">
        <p>
          {{ 'LOG_REVIEW_AUDIT_STATUS' | translate }}<label>{{ log.auditText | translate }}</label>
        </p>
        <p>{{ 'LOG_REVIEW_CHANGE_BY' | translate }} {{ log.changeByName }}</p>
        <p>{{ 'LOG_REVIEW_CHANGE_AT' | translate }} {{ log.changeDate | date: 'short' }}</p>
        <p>{{ 'LOG_REVIEW_MESSAGE' | translate }} {{ log.message }}</p>
        <mat-divider *ngIf="!isLast"></mat-divider>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button matDialogClose>{{ 'BUTTON_CANCEL' | translate }}</button>
  <button mat-raised-button color="primary" (click)="done()">{{ 'BUTTON_OK' | translate }}</button>
</mat-dialog-actions>
