import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { environment } from '@env';

import { LayoutDefaultComponent } from '../layout/default/default.component';
import { LayoutPassportComponent } from '../layout/passport/passport.component';
import { LayoutStartupComponent } from '../layout/startup/startup.component';
import { LayoutBlankComponent } from '../layout/blank/blank.component';

import { BindComponent } from './authorization/bind/bind.component';
import { LoginComponent } from './authorization/login/login.component';
import { SignupComponent } from './authorization/signup/signup.component';

import { CallbackComponent } from './callback/callback.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { PasswordComponent } from './startup/password/password.component';
import { TwoFactorComponent } from './startup/two-factor/two-factor.component';
import { DeviceModeComponent } from './startup/device-mode/device-mode.component';
import { ForgetPasswordComponent } from './authorization/forget-password/forget-password.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'audit',
        loadChildren: () => import('./audit/audit.module').then((m) => m.AuditModule),
      },
      {
        path: 'review',
        loadChildren: () => import('./review/review.module').then((m) => m.ReviewModule),
      },
      {
        path: 'play',
        loadChildren: () => import('./play/play.module').then((m) => m.PlayModule),
      },
      {
        path: 'templates',
        loadChildren: () => import('./usercontent/templates/templates.module').then((m) => m.TemplatesModule),
      },
      {
        path: 'gallery',
        loadChildren: () => import('./usercontent/gallery/gallery.module').then((m) => m.GalleryModule),
      },
      {
        path: 'device',
        loadChildren: () => import('./devices/devices.module').then((m) => m.DevicesModule),
      },
      {
        path: 'designer',
        loadChildren: () => import('./designer/designer.module').then((m) => m.DesignerModule),
      },
      {
        path: 'distributor',
        loadChildren: () => import('./distributor/distributor.module').then((m) => m.DistributorModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'custom',
        loadChildren: () => import('./custom/custom.module').then((m) => m.CustomModule),
      },
      {
        path: 'configure',
        loadChildren: () => import('./sysconfig/configure.module').then((m) => m.ConfigureModule),
      },
      {
        path: 'exception',
        loadChildren: () => import('./exception/exception.module').then((m) => m.ExceptionModule)
      },
      {
        path: 'mini',
        loadChildren: () => import('./mini/mini.module').then((m) => m.MiniModule)
      },
    ],
  },
  {
    path: 'diyeditor',
    component: LayoutBlankComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./studio/studio.module').then((m) => m.StudioModule),
  },
  {
    path: 'startup',
    component: LayoutStartupComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'change-password', component: PasswordComponent },
      { path: 'verify', component: TwoFactorComponent },
      { path: 'mode', component: DeviceModeComponent },
    ],
  },
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'bind', component: BindComponent },
      { path: 'forget-password', component: ForgetPasswordComponent },
    ],
  },
  { path: 'callback', component: CallbackComponent },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: environment.enableTrac })],
  exports: [RouterModule],
})
export class PageRoutingModule { }
