<div class="two-factor">
  <h3>{{ 'COMMON_TEXT_VALIDATE' | translate }}{{ validTypeI18nText }}</h3>
  <div class="tips" *ngIf="!loginUser.appuser">
    {{ 'TWO_FACTORY_TIP_CONTACT_ADMIN' | translate: { type: validTypeI18nText } }}
  </div>
  <p>
    <span *ngIf="!counter">
      {{ 'TWO_FACTORY_MSG_READY_SEND' | translate: { number: validAccount, type: validTypeI18nText } }}
    </span>
    <span *ngIf="counter > 0">
      {{ 'TWO_FACTORY_MSG_CODE_SENT' | translate: { number: validAccount, type: validTypeI18nText, minute: minute } }}
    </span>
  </p>
  <div fxLayout fxLayoutAlign="space-between baseline">
    <mat-form-field fxFlex="60%">
      <input matInput [formControl]="codeFormControl" placeholder="{{ 'COMMON_TEXT_VERIFICATION_CODE' | translate }}" required />
      <mat-hint> {{ 'TWO_FACTORY_VERIFICATION_CODE' | translate }} </mat-hint>
      <mat-error *ngIf="codeFormControl.hasError('required')">{{ 'LOGIN_TEXT_CAPTCHA' | translate }}</mat-error>
      <mat-error *ngIf="codeFormControl.hasError('pattern')">{{ 'TWO_FACTORY_VERIFICATION_CODE' | translate }}</mat-error>
    </mat-form-field>
    <div fxFlex="37px">
      <img *ngIf="isLoading" style="vertical-align: middle;" src="./assets/img/spinner_37_1s.gif" />
    </div>
    <button mat-stroked-button (click)="send()" [disabled]="isLoading || counter > 0">
      {{ 'REGISTER_GET_VALIDATE_CODE' | translate }} <span *ngIf="counter > 0">({{ counter }})</span>
    </button>
  </div>
  <div class="actions">
    <button mat-raised-button color="primary" (click)="validCode()" [disabled]="codeFormControl.invalid">
      {{ 'COMMON_TEXT_VALIDATE' | translate }}
    </button>
    <span style="margin-left: 16px;">{{ 'COMMON_TEXT_OR' | translate }} </span>
    <button mat-button color="primary" (click)="gotoLogin()">{{ 'GOBACK_TO_LOGIN_PAGE' | translate }}</button>
  </div>
</div>
