import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  /**
   * Rounding, formatting numbers
   * {{value|numformat}} |　{{value|numformat:2}}
   */
  transform(value: any, ...args: any[]): any {
    const length = args.length > 0 ? parseInt(args[0], 10) : 0;
    let num = parseFloat(value);
    if (isNaN(num)) {
      num = 0;
    }
    return num === 0 ? 0 : num.toFixed(length);
  }
}
