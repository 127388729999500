<div class="page">
  <p>
    {{ 'APP_DASHBOARD_WELCOME' | translate: { name: user?.displayName } }}
  </p>
  <!-- <div class="cover">
    <h3>默认（广告屏）</h3>
    <p>60 * 80</p>
    <div class="cover-box default-cover">
      <img src="https://cdn.nexnovo.mobi/thumbs/preview/6611347709603348481577668412687CRs3Dg4AO" />
    </div>

    <h3>水平方向</h3>
    <p>96 * 96</p>
    <div class="cover-box horizontal-cover">
      <img src="https://cdn.nexnovo.mobi/images/preview/7154910630405734401590627962610nkS4RiB79" />
    </div>
    <p>152 * 96</p>
    <div class="cover-box horizontal-cover">
      <img src="https://cdn.nexnovo.mobi/images/preview/69817802731343052815865002132328I7pfLXfq" />
    </div>
    <p>304 * 96</p>
    <div class="cover-box horizontal-cover">
      <img src="https://cdn.nexnovo.mobi/images/preview/6981829037496729601586501375865ChmNbRmCv" />
    </div>
    <p>456 * 96</p>
    <div class="cover-box horizontal-cover">
      <img src="https://cdn.nexnovo.mobi/images/preview/6981848372692910081586501836852XZBheV6As" />
    </div>
    <p>608 * 96</p>
    <div class="cover-box horizontal-cover">
      <img src="https://cdn.nexnovo.mobi/images/preview/6981870550578626561586502365614D2C2TAzpu" />
    </div>
    <p>760 * 96</p>
    <div class="cover-box horizontal-cover">
      <img src="https://cdn.nexnovo.mobi/images/preview/6981884387377807361586502695509qmI8BtNpU" />
    </div>

    <h3>垂直方向</h3>

    <p>96 * 152</p>
    <div class="cover-box vertical-cover">
      <img src="https://cdn.nexnovo.mobi/images/preview/6978414946644131841586419977596bfiN4yq9U" />
    </div>
    <p>96 * 304</p>
    <div class="cover-box vertical-cover">
      <img src="https://cdn.nexnovo.mobi/images/preview/69784683201691648015864212501203QpUdA1Xw" />
    </div>
    <p>96 * 456</p>
    <div class="cover-box vertical-cover">
      <img src="https://cdn.nexnovo.mobi/images/preview/6978504931158261761586422122994nA8PJPrBj" />
    </div>
  </div> -->
</div>
