import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationFilter',
})
export class DurationFilterPipe implements PipeTransform {
  constructor() {}

  transform(value: any, unit = 's'): any {
    let ts = parseInt(value, 10);
    if (!isNaN(ts)) {
      if (unit === 'ms') {
        ts = Math.floor(ts / 1000);
      }
      const d = Math.floor(ts / (3600 * 24));
      ts = ts % (3600 * 24);
      const h = Math.floor(ts / 3600);
      ts = ts % 3600;
      const m = Math.floor(ts / 60);
      ts = ts % 60;
      const s = ts;
      let res = '';
      if (d) {
        res += d.toString() + ' days ';
      }
      if (h) {
        res += h.toString() + ' h ';
      }
      if (m) {
        res += m.toString() + ' m ';
      }
      if (s) {
        res += s.toString() + ' s';
      }
      return res;
    }
    return value;
  }
}
