import { Component } from '@angular/core';
import { AuthService } from '@core';

@Component({
  selector: 'nex-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  user: any = null;

  constructor(private auth: AuthService) {
    this.user = this.auth.getUser();
  }

  getUserName() {
    const userName = this.user ? this.user.displayName || this.user.email : 'Guest';
    return { name: userName };
  }
}
