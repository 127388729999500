
export class PermissionNode {
  children: PermissionNode[];
  enable: boolean;
  _id: string;
  key: string;
  title: string;
  granted: boolean;
}

export class PermissionFlatNode {
  _id: string;
  key: string;
  title: string;
  granted: boolean;
  enable: boolean;
  level: number;
  expandable: boolean;
}
