import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import {
  AlertService,
  AuditContentType,
  ContentType,
  DesignerAuditStatus,
  GalleryType,
  ResponseCode,
  RestService,
  ServerResponse,
  StorageService,
} from '@core';

interface DropdownCategory {
  _id: string;
  index: string;
  className: string;
  key: string;
  checked: boolean;
  indeterminate?: boolean;
  childs?: Array<DropdownCategory>;
}

@Component({
  selector: 'nex-design-audit',
  templateUrl: './design-audit.component.html',
  styleUrls: ['./design-audit.component.scss'],
})
export class DesignAuditComponent implements OnInit {
  dialogForm: FormGroup;
  priceLimit = 50000;
  nameMaxLength = 50;
  currentDeviceType = this.storage.user.deviceType;

  itemData: any;
  lastCategories: any[];
  isCategoryLoaded = false;
  checkedItems: Array<DropdownCategory> = [];
  categories: Array<DropdownCategory> = [];
  reviewLog: Array<{
    changeByName: string;
    changeDate: string;
    auditStatus: number;
    auditText: string;
    message: string;
  }>;

  auditRadioList = [
    { value: DesignerAuditStatus.Pass, text: 'MSG_AUDIT_SUCCESS' },
    { value: DesignerAuditStatus.Failed, text: 'MSG_AUDIT_FAIL' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DesignAuditComponent>,
    private rest: RestService,
    private fb: FormBuilder,
    private alert: AlertService,
    private storage: StorageService,
  ) {}

  get ContentType() {
    return ContentType;
  }

  get GalleryType() {
    return GalleryType;
  }

  get isFont() {
    return this.itemData.type === GalleryType.Font;
  }

  ngOnInit() {
    this.itemData = this.data.item || {};
    this.lastCategories = this.itemData.category || [];
    this.reviewLog = this.itemData.reason || [];
    this.dialogForm = this.fb.group({
      auditStatus: [DesignerAuditStatus.Pass, Validators.required],
      message: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(4), Validators.maxLength(100)]],
      name: [this.itemData.name || '', [Validators.required, Validators.maxLength(this.nameMaxLength)]],
      price: [this.itemData.price, [Validators.required, Validators.max(this.priceLimit), Validators.pattern('^[0-9]\\d*$')]],
    });

    this.dialogForm.get('auditStatus').valueChanges.subscribe((value) => {
      if (value === DesignerAuditStatus.Pass) {
        this.dialogForm.get('message').disable();
      } else {
        this.dialogForm.get('message').enable();
      }
    });

    this.getCategories(this.data.type).subscribe((res: ServerResponse) => {
      if (!res.data) {
        return;
      }

      this.isCategoryLoaded = true;
      this.categories = res.data;

      this.categories.forEach((parent) => {
        const p = this.lastCategories.find((f) => f.key === parent.key);
        if (this.isFont && p && p.key === 'LANGUAGE') {
          parent.checked = true;
          parent.indeterminate = false;
        } else {
          parent.checked = p && p.childs.length === parent.childs.length;
          parent.indeterminate = p && p.childs.length < parent.childs.length;
        }

        parent.childs.forEach((child) => {
          child.checked = p && !!p.childs.find((f) => f === child.key);
        });
      });
    });
  }

  onParentChange(event: MatCheckboxChange, parent: DropdownCategory) {
    parent.checked = event.checked;
    parent.childs.forEach((child) => {
      child.checked = parent.checked;
    });
  }

  onChildChange(event: MatCheckboxChange | MatRadioChange, child: DropdownCategory, parent: DropdownCategory) {
    if (this.isFont && parent.key === 'LANGUAGE') {
      parent.childs.forEach((item) => {
        item.checked = item.key === child.key;
      });
      parent.checked = true;
      parent.indeterminate = false;
      return;
    }
    child.checked = (event as MatCheckboxChange).checked;
    parent.checked = parent.childs.every((item) => item.checked);
    const checkedChilds = parent.childs.filter((f) => f.checked);
    parent.indeterminate = checkedChilds.length && checkedChilds.length < parent.childs.length;
  }

  done() {
    const selectedCategories = [];
    this.categories
      .filter((f) => f.checked || f.indeterminate)
      .forEach((item) => {
        selectedCategories.push({
          key: item.key,
          childs: item.childs.filter((f) => f.checked).map((m) => m.key),
        });
      });

    if (this.dialogForm.invalid) {
      if (this.dialogForm.get('message').invalid) {
        this.alert.alertWarning('DESIGNER_REVIEW_ALERT_REASON_REQUIRED');
      }
      return;
    }
    if (!selectedCategories.length) {
      this.alert.alertWarning('DESIGNER_REVIEW_ALERT_ERR_CATEGORY');
      return;
    }

    let submitType = '';
    switch (this.data.type) {
      case ContentType.PoTemplate:
      case ContentType.EsTemplate:
        submitType = AuditContentType.Template;
        break;
      case ContentType.PoGallery:
      case ContentType.EsBackgroundGallery:
      case ContentType.EsAnimatedGallery:
        submitType = AuditContentType.Gallery;
        break;
      default:
        return;
    }

    const deleteParentKey = this.lastCategories
      .filter((f) => selectedCategories.findIndex((item) => item.key === f.key) < 0)
      .map((m) => m.key);

    const submitData = {
      type: submitType,
      deviceType: this.currentDeviceType,
      name: this.dialogForm.value.name,
      price: Number.parseFloat(this.dialogForm.value.price),
      auditStatus: this.dialogForm.value.auditStatus,
      message: this.dialogForm.value.message || '',
      category: selectedCategories,
      deleteParentKey,
    };
    console.log('review data', submitData);
    this.putReview(this.itemData._id, submitData).subscribe((res: ServerResponse) => {
      if (res.code === ResponseCode.Warning) {
        this.alert.alertWarning(res.msgCode);
        return;
      }
      this.alert.alertSuccess();
      this.dialogRef.close(true);
    });
  }

  getNameErrorMessage(): string {
    const nameCtrl = this.dialogForm.get('message');
    if (nameCtrl.hasError('required') || nameCtrl.hasError('minlength') || nameCtrl.hasError('maxlength')) {
      return 'COMMON_TEXT_PARAGRAPH_LIMIT';
    }
    return '';
  }

  private putReview(id: string, data: any) {
    return this.rest.putJson('v3/platform/review/' + id, data);
  }

  private getCategories(type: string) {
    return this.rest.getJson('v3/class/dropdownlist?type=' + type);
  }
}
