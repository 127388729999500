import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ContentType,
  MediaAudit,
  PreviewType,
  DeviceType,
  StorageService,
  UtilsService,
  ResponseCode,
  ServerResponse,
  RestService,
  AlertService,
} from '@core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'nex-content-audit',
  templateUrl: './content-audit.component.html',
  styleUrls: ['./content-audit.component.scss'],
})
export class ContentAuditComponent implements OnInit {
  itemData: any;
  auditType: number;
  ContentType = ContentType;

  auditInterval: any;
  timer: number;
  auditForm: FormGroup;
  auditRadioList: any[] = [];
  PreviewType = PreviewType;

  get isEsign() {
    return this.storage.user.deviceType === DeviceType.Esign;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ContentAuditComponent>,
    private fb: FormBuilder,
    private storage: StorageService,
    private utils: UtilsService,
    private rest: RestService,
    private alert: AlertService,
  ) {}

  ngOnInit(): void {
    this.auditType = this.data.type;
    this.itemData = this.data.item;
    this.initForm();

    this.timer = Number.parseInt(`${this.itemData.duration || 0}`, 10);
    this.auditInterval = setInterval(() => {
      this.timer--;
      if (this.timer === 0) {
        clearInterval(this.auditInterval);
      }
    }, 1000);
  }

  onAuditSubmit() {
    const submitData = this.auditForm.value;
    if (!submitData.audit) {
      return;
    }
    let url = '';
    if (this.auditType === PreviewType.FirstAudit) {
      url = 'v3/user/templates/audit/';
    }
    if (this.auditType === PreviewType.FinalAudit) {
      url = 'v3/user/templates/platform/audit/';
      Object.assign(submitData, { finalRejectReason: submitData.reason });
    }
    if (!url) {
      return;
    }
    this.utils.showSpinner(true);
    this.rest.putJson(url + this.itemData._id, submitData).subscribe(
      (res: ServerResponse) => {
        this.utils.showSpinner(false);
        if (res.code === ResponseCode.Success) {
          this.alert.alertSuccess();
          this.dialogRef.close(res);
        }
      },
      (err) => {
        console.log('onAuditSubmit error:', err);
        this.utils.showSpinner(false);
        this.alert.alertFailure();
      },
    );
  }

  getErrorMessage(): string {
    const ctrl = this.auditForm.get('reason');
    if (ctrl.hasError('required') || ctrl.hasError('minlength') || ctrl.hasError('maxlength')) {
      return 'COMMON_TEXT_PARAGRAPH_LIMIT';
    }
    return '';
  }

  private initForm(): void {
    console.log('type', this.auditType);

    if (this.auditType === PreviewType.FirstAudit) {
      this.auditRadioList = [
        { value: MediaAudit.FirstPassed, text: 'TEMPLATES_RADIO_AUDIT_PASSED' },
        { value: MediaAudit.FirstFailed, text: 'TEMPLATES_RADIO_AUDIT_REJECTION' },
      ];
    }
    if (this.auditType === PreviewType.FinalAudit) {
      this.auditRadioList = [
        { value: MediaAudit.FinalPassed, text: 'TEMPLATES_RADIO_AUDIT_PASSED' },
        { value: MediaAudit.FinalFailed, text: 'TEMPLATES_RADIO_AUDIT_REJECTION' },
      ];
    }

    this.auditForm = this.fb.group({
      audit: ['', Validators.required],
      deviceType: this.storage.user.deviceType,
      reason: [
        { value: '', disabled: true },
        [Validators.required, Validators.minLength(4), Validators.maxLength(100)],
      ],
    });

    this.auditForm.get('audit').valueChanges.subscribe((value) => {
      if (value === MediaAudit.FirstPassed || value === MediaAudit.FinalPassed) {
        this.auditForm.get('reason').disable();
      } else {
        this.auditForm.get('reason').enable();
      }
    });
  }
}
