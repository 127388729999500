// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiPath: 'https://api.dev.nexnovo.mobi/',
  clientId: 'nexnovo-web',
  clientSecret: 'nexnovo-api',
  s3Domain: 'https://cdn.nexnovo.mobi/',
  bingApiKey: 'AiL2gNdZec1FS6-Pqwwank8sWStiBUwR_JxUba0lQOi7o_FmkbxFCzRHoAamMmaP',
  firebase: {
    apiKey: 'AIzaSyC54jJ4AJu3_jex1E9ewFoVcKfKFYY9yzA',
    authDomain: 'nexposter-mobi.firebaseapp.com',
    databaseURL: 'https://nexposter-mobi.firebaseio.com',
    projectId: 'nexposter-mobi',
    storageBucket: 'nexposter-mobi.appspot.com',
    messagingSenderId: '869115058587',
    appId: '1:869115058587:web:42cf130a20bf81cebde93e',
    measurementId: 'G-VTR7YP863J',
  },
  socialLogin: {
    appid: 'wx58a83a20bb54e78c', // weixin
    client_id: '545799443035608', // facebook
    bindUri: 'https://portal.dev.nexnovo.mobi/passport/bind', // login bind
    callbackUri: 'https://portal.dev.nexnovo.mobi/callback', // bind callback
  },
  enableTrac: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
