import cloneDeep from 'lodash-es/cloneDeep';

import { Injectable } from '@angular/core';

import { Node, SearchableNode, TreeTableNode } from '../models';
import { TreeService } from './tree.service';

@Injectable()
export class ConverterService {
  constructor(private treeService: TreeService) {}

  /**
   * Clone a Node<T> object and convert it to a SearchableNode<T>
   * @param tree the node to be converted
   */
  toSearchableTree<T>(tree: Node<T>): SearchableNode<T> {
    const treeClone = cloneDeep(tree) as SearchableNode<T>;
    this.treeService.traverse(treeClone, (node: SearchableNode<T>) => {
      node.id = node.id ? node.id : this.uuid();
    });
    return treeClone;
  }

  /**
   * Clone a SearchableNode<T> object and convert it to a TreeTableNode<T>
   * @param tree the node to be converted
   */
  toTreeTableTree<T>(tree: SearchableNode<T>): TreeTableNode<T> {
    const treeClone = cloneDeep(tree) as TreeTableNode<T>;
    this.treeService.traverse(treeClone, (node: TreeTableNode<T>) => {
      node.depth = this.treeService.getNodeDepth(treeClone, node);
      node.isExpanded = true;
      node.isVisible = true;
    });
    return treeClone;
  }

  uuid() {
    let d = new Date().getTime();
    let d2 = (performance && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16;
      if (d > 0) {
        // tslint:disable-next-line: no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // tslint:disable-next-line: no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // tslint:disable-next-line: no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }
}
