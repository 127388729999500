// 门头屏素材类容类型 1、背景 2、动画
export enum EsGalleryContentType {
  Background = 1,
  Animated = 2,
}

// 屏幕方向 1、横屏 2、竖屏
export enum ScreenOrientation {
  Landscape = 1,
  Portrait = 2,
}

// 节目审核状态 0、草稿 1、待审核 2、初审未通过 3、初审通过 4、终审未通过 5、终审通过
export enum MediaAuditStatus {
  Draft,
  Pending,
  FirstFailed,
  FirstPassed,
  FinalFailed,
  FinalPassed,
}

// 商店审核状态 0、初始化 1、待审核 2、审核不通过 3、审核通过
export enum DesignerAuditStatus {
  Init,
  Pending,
  Failed,
  Pass,
}

// 产品类型 1、定制视频 2、标准素材 4、标准视频 5、定制文字 6、定制图片
export enum ProductType {
  CustomizeVideo = 1,
  StandardMaterial = 2,
  StandardVideo = 4,
  CustomizeText = 5,
  // CustomizePicture = 6,
}

// 订单状态 0、待支付 1、已支付 2、初次发布 3、系统取消 4、用户取消 5、返工 6、再次发布 7、已完成 8、交易失败 9、资料不合符 10、开始制作
export enum OrderStatus {
  Pending = 0,
  Paid = 1,
  Init = 2,
  SystemCancel = 3,
  UserCancel = 4,
  Rework = 5,
  Again = 6,
  Completed = 7,
  Failed = 8,
  UNQUALIFIED = 9,
  BEGIN = 10,
}

// 设计师内容类型 templates：广告屏模板 gallery：广告屏素材 templatesEs：门头屏模板 galleryEs：门头屏动画素材 galleryEsBKG：门头屏背景素材
// 节目内容类型 posterMedia 广告屏节目 esignMedia 门头屏节目
export enum ContentType {
  PoTemplate = 'templates',
  PoGallery = 'gallery',
  EsTemplate = 'templatesEs ',
  EsAnimatedGallery = 'galleryEs',
  EsBackgroundGallery = 'galleryEsBKG',
  PoMedia = 'posterMedia',
  EsMedia = 'esignMedia',
}

// 预览类型
export enum PreviewType {
  PrivateTemplate,
  PrivateEsTemplate,
  CompanyTemplate,
  CompanyEsTemplate,
  BasicTemplate,
  FirstAudit,
  FinalAudit,
  PrivateGallery,
  CompanyGallery,
  PlayLog,
  Video = 'video',
  Diy = 'diy',
  Font = 'font',
  Animate = 'animate',
}

// 审核内容类型
export enum AuditContentType {
  Template = 'template',
  Gallery = 'gallery',
}

// 设备类型 1、广告屏 2、门头屏 3、广告屏和门头屏共存
export enum DeviceType {
  Poster = 1,
  Esign = 2,
  All = 3,
}

// 0.草稿 1.待审核 2.初审未通过 3.初审通过 4.终审未通过 5.终审通过
export enum MediaAudit {
  Draft,
  Pending,
  FirstFailed,
  FirstPassed,
  FinalFailed,
  FinalPassed,
}

export enum ResponseCode {
  Success = 1,
  Warning = 3,
}

// 门头屏单元屏幕规格
export enum ScreenSpec {
  Width = 76,
  Height = 48,
}

// 设备授权模式 0、标准模式 1、比例模式
export enum GrantType {
  Standard = 0,
  Ratio = 1,
}

// 节目类型 Video：标准视频 Customize：定制视频 Diy:diy
export enum TemplateType {
  Video = 'video',
  Customize = 'customize',
  Diy = 'diy',
}

// 素材类型 image：图片动画 text：文字动画 font:字体
export enum GalleryType {
  Image = 'image',
  Text = 'text',
  Font = 'font',
}

// 协议 terms：服务条款 policy:隐私政策
export enum ProtocolType {
  Term = 'terms',
  Policy = 'policy',
}

// 社交账号类型
export enum SocialAccountType {
  Weixin = 1,
  Facebook = 2,
}

// 申请企业账号状态 0.草稿 1.申请 2.失败 3.成功
export enum UpgradeStatus {
  Draft = 0,
  Apply = 1,
  Failure = 2,
  Success = 3,
}

// 编辑器模式
export enum DiyMode {
  EsDiy = 1,
  EsScroll = 2,
  PsDiy = 1,
  PsVideo = 2,
  PsSlide = 3,
}
