import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentType, RestService, ServerResponse } from '@core';

interface DropdownCategory {
  _id: string;
  index: string;
  className: string;
  key: string;
  checked: boolean;
  indeterminate?: boolean;
  childs?: Array<DropdownCategory>;
}

@Component({
  selector: 'nex-design-detail',
  templateUrl: './design-detail.component.html',
  styleUrls: ['../../designer/designer.common.scss'],
})
export class DesignDetailComponent implements OnInit {
  itemData: any;
  isCategoryLoaded = false;
  categories: Array<DropdownCategory> = [];
  reviewLog: Array<{
    changeByName: string;
    changeDate: string;
    auditStatus: number;
    auditText: string;
    message: string;
  }>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private rest: RestService) {}

  get ContentType() {
    return ContentType;
  }

  ngOnInit() {
    console.log('content type', this.data.type);
    this.itemData = this.data.item || {};
    this.reviewLog = this.itemData.reason || [];
    this.getCategories(this.data.type).subscribe((res: ServerResponse) => {
      if (!res.data) {
        return;
      }

      this.isCategoryLoaded = true;
      this.categories = res.data;

      const itemCategory = this.itemData.category || [];

      this.categories.forEach((parent) => {
        const p = itemCategory.find((f) => f.key === parent.key);
        parent.checked = p && p.childs.length === parent.childs.length;
        parent.indeterminate = p && p.childs.length < parent.childs.length;

        parent.childs.forEach((child) => {
          child.checked = p && !!p.childs.find((f) => f === child.key);
        });
      });
    });
  }

  private getCategories(type: string) {
    const apiName = this.data.isAudit ? 'dropdownlist' : 'dropdown';
    return this.rest.getJson('v3/class/' + apiName + '?type=' + type);
  }
}
