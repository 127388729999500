import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import {
  DeviceType,
  ScreenOrientation,
  EsGalleryContentType,
  GrantType,
  SocialAccountType,
} from '@core/enums/propertymap.enum';

@Pipe({
  name: 'templateTypeText',
})
export class TemplateTypeTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value): Observable<string> {
    value = value || {};
    let key = '';
    if (value.type === 'html5/canvas') {
      key = 'TEMPLATES_TYPE_DIY';
    }
    if (value.type === 'video/mp4') {
      key = value.customization ? 'TEMPLATES_TYPE_CUSTOM_VIDEO' : 'TEMPLATES_TYPE_STANDARD_VIDEO';
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(res);
      });
    });
  }
}

@Pipe({
  name: 'galleryTypeText',
})
export class GalleryTypeTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): Observable<string> {
    let key: string;
    switch (value) {
      case 'image':
        key = 'GALLERY_TYPE_ANIMATED_IMAGE';
        break;
      case 'text':
        key = 'GALLERY_TYPE_ANIMATED_TEXT';
        break;
      case 'font':
        key = 'GALLERY_TYPE_FONT';
        break;
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(res);
      });
    });
  }
}

@Pipe({
  name: 'esGalleryContentTypeText',
})
export class EsGalleryContentTypeTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): Observable<string> {
    let key: string;
    switch (value) {
      case EsGalleryContentType.Background:
        key = 'ESIGN_CONTENT_TYPE_BACKGROUND';
        break;
      case EsGalleryContentType.Animated:
        key = 'ESIGN_CONTENT_TYPE_ANIMATTION';
        break;
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(res);
      });
    });
  }
}

@Pipe({
  name: 'screenOrientationText',
})
export class ScreenOrientationTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): Observable<string> {
    let key: string;
    switch (value) {
      case ScreenOrientation.Landscape:
        key = 'ESIGN_SCREEN_ORIENTATION_LAND';
        break;
      case ScreenOrientation.Portrait:
        key = 'ESIGN_SCREEN_ORIENTATION_PORTRAIT';
        break;
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(res);
      });
    });
  }
}

@Pipe({
  name: 'screenTypeText',
})
export class ScreenTypeTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(data: any): Observable<string> {
    let key: string;

    if (data && data.hasOwnProperty('screenOrientation') && data.hasOwnProperty('screenCount')) {
      switch (data.screenOrientation) {
        case ScreenOrientation.Landscape:
          key = 'ESIGN_SCREEN_ORIENTATION_LAND';
          break;
        case ScreenOrientation.Portrait:
          key = 'ESIGN_SCREEN_ORIENTATION_PORTRAIT';
          break;
      }
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(`${res} (×${data.screenCount || 0})`);
      });
    });
  }
}

@Pipe({
  name: 'directionText',
})
export class DirectionTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(screenOrientation: DeviceType): Observable<string> {
    let key: string;

    if (screenOrientation === DeviceType.Poster) {
      key = 'ORIENTATION_LANDSCAPE';
    }
    if (screenOrientation === DeviceType.Esign) {
      key = 'ORIENTATION_VERTICAL';
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(`${res}`);
      });
    });
  }
}

@Pipe({
  name: 'grantTypeText',
})
export class GrantTypeTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): Observable<string> {
    let key: string;
    switch (value) {
      case GrantType.Standard:
        key = 'DEVICES_TEXT_STANDARD_MODE';
        break;
      case GrantType.Ratio:
        key = 'DEVICES_TEXT_RATIO_MODE';
        break;
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(res);
      });
    });
  }
}

@Pipe({
  name: 'deviceTypeText',
})
export class DeviceTypeTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): Observable<string> {
    let key: string;
    switch (value) {
      case DeviceType.Poster:
        key = 'DEVICE_TYPE_POSTER';
        break;
      case DeviceType.Esign:
        key = 'DEVICE_TYPE_ESIGN';
        break;
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(res);
      });
    });
  }
}

@Pipe({
  name: 'socialAccountTypeText',
})
export class SocialAccountTypeTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): Observable<string> {
    let key: string;
    switch (value) {
      case SocialAccountType.Weixin:
        key = 'SOCIAL_TYPE_WEIXIN';
        break;
      case SocialAccountType.Facebook:
        key = 'SOCIAL_TYPE_FACEBOOK';
        break;
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(res);
      });
    });
  }
}
@Pipe({
  name: 'supportTypeText',
})
export class SupportTypeTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): Observable<string> {
    let key: string;
    switch (value) {
      case 1:
        key = 'SUPPORT_TYPE_ACCOUNT';
        break;
      case 2:
        key = 'SUPPORT_TYPE_TECHNICAL';
        break;
      case 3:
        key = 'SUPPORT_TYPE_OTHER';
        break;
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(res);
      });
    });
  }
}
@Pipe({
  name: 'supportStatusText',
})
export class SupportStatusTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): Observable<string> {
    let key: string;
    switch (value) {
      case 1:
        key = 'SUPPORT_STATUS_PENDING';
        break;
      case 2:
        key = 'SUPPORT_STATUS_PLATFORM_REPLY';
        break;
      case 3:
        key = 'SUPPORT_STATUS_USER_REPLY';
        break;
      case 4:
        key = 'SUPPORT_STATUS_SOLVED';
        break;
    }

    if (!key) {
      return of('');
    }

    return new Observable((observer) => {
      this.translate.stream(key).subscribe((res: string) => {
        observer.next(res);
      });
    });
  }
}
