import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { PageRoutingModule } from './page-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CallbackComponent } from './callback/callback.component';

import { LoginComponent } from './authorization/login/login.component';
import { SignupComponent } from './authorization/signup/signup.component';
import { PolicyDialogComponent } from './authorization/signup/policy-dialog.component';
import { BindComponent } from './authorization/bind/bind.component';
import { CountryDialogComponent } from './authorization/signup/country-dialog.component';
import { ForgetPasswordComponent } from './authorization/forget-password/forget-password.component';

import { CoverPreviewComponent } from './shared/cover-preview/cover-preview.component';
import { ContentAuditComponent } from './shared/content-audit/content-audit.component';
import { DesignDetailComponent } from './shared/design-detail/design-detail.component';
import { DesignAuditComponent } from './shared/design-audit/design-audit.component';
import { ScreenSelectorComponent } from './shared/screen-selector/screen-selector.component';
import { VerifyComponent } from './shared/verify/verify.component';
import { UserMaterialComponent } from './shared/user-material/user-material.component';
import { CalendarHeaderComponent } from './shared/calendar-header/calendar-header.component';
import { FilePreviewComponent } from './shared/file-preview/file-preview.component';

import { PasswordComponent } from './startup/password/password.component';
import { TwoFactorComponent } from './startup/two-factor/two-factor.component';
import { DeviceModeComponent } from './startup/device-mode/device-mode.component';

@NgModule({
  imports: [SharedModule, PageRoutingModule],
  declarations: [
    DashboardComponent,
    CallbackComponent,
    LoginComponent,
    SignupComponent,
    BindComponent,

    PolicyDialogComponent,
    CountryDialogComponent,

    DesignDetailComponent,
    CoverPreviewComponent,

    DeviceModeComponent,
    PasswordComponent,
    ContentAuditComponent,
    DesignAuditComponent,
    ScreenSelectorComponent,
    TwoFactorComponent,
    VerifyComponent,
    UserMaterialComponent,
    ForgetPasswordComponent,
    CalendarHeaderComponent,
    FilePreviewComponent,
  ],
})
export class PagesModule {}
