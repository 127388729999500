<div class="spinner" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>
<div class="progress-bar" *ngIf="isFetching">
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</div>
<div class="layout-container" [class.is-mobile]="isSmallScreen">
  <layout-header class="layout-header" [isSmallScreen]="isSmallScreen" (sidenavClose)="onSidenavClose()"> </layout-header>
  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="isSmallScreen ? 56 : 0">
    <mat-sidenav
      #sidenav
      [mode]="isSmallScreen ? 'over' : 'side'"
      [fixedInViewport]="isSmallScreen"
      fixedTopGap="56"
      role="navigation"
      [opened]="!isSmallScreen && authUser"
      (openedChange)="sidenavService.isOpen = sidenav.opened"
    >
      <nex-sidenav class="pd-nav" (sidenavClose)="onSidenavClose()"></nex-sidenav>
    </mat-sidenav>
    <mat-sidenav-content role="main">
      <main class="main-container">
        <nex-breadcrumb [hidden]="isHideBreadcrumb"></nex-breadcrumb>
        <router-outlet></router-outlet>
        <!-- <p>{{ message | async | json }}</p> -->
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
