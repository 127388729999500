<div class="password">
  <h3>{{ 'APP_CHANGE_PASSWORD' | translate }}</h3>

  <p class="prompt">
    <mat-icon class="mat-24">warning</mat-icon>
    <span *ngIf="user?.defaultPwd">{{ 'MANDATORY_PASSWORD_MODIFICATION_PROMPT' | translate }}</span>
    <span *ngIf="user?.passwordExpire">{{ 'MANDATORY_PASSWORD_EXPIRE_PROMPT' | translate }}</span>
  </p>

  <form class="form" [formGroup]="passwordForm" (ngSubmit)="onSubmitPassword()">
    <mat-form-field class="full-width">
      <input
        matInput
        formControlName="oldPwd"
        type="password"
        autocomplete="new-password"
        [placeholder]="'LOGIN_TEXT_OLD_PASSWORD' | translate"
        required
      />
      <mat-error *ngIf="getControl('oldPwd').invalid">{{ 'COMMON_TEXT_REQUIRED' | translate }}</mat-error>
    </mat-form-field>

    <!-- <mat-form-field class="full-width" hintLabel="{{ 'USER_HINT_PASSWORD' | translate: { limit: pwdMin + '~' + pwdMax } }}"> -->
    <mat-form-field class="full-width" [hintLabel]="passwordRuleKey | translate">
      <input
        #psd
        matInput
        formControlName="password"
        type="password"
        autocomplete="new-password"
        [placeholder]="'LOGIN_TEXT_NEW_PASSWORD' | translate"
        required
      />
      <mat-hint align="end">{{ psd.value?.length || 0 }}/{{ pwdMin }}~{{ pwdMax }}</mat-hint>
      <mat-error *ngIf="getControl('password').invalid">
        {{ getPasswordErrorMessage() | translate: { limit: pwdMin + '~' + pwdMax } }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input
        matInput
        formControlName="confirmPassword"
        type="password"
        autocomplete="new-password"
        [placeholder]="'LOGIN_TEXT_CONFIRM_NEW_PASSWORD' | translate"
        required
        [errorStateMatcher]="matcher"
      />
      <mat-error *ngIf="getControl('confirmPassword')?.hasError('required') || passwordForm.hasError('notSame')">
        {{ 'SIGN_UP_PASSWORD_NOT_MATCH' | translate }}
      </mat-error>
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button color="primary" type="submit" [disabled]="passwordForm.invalid">
        {{ 'BUTTON_OK' | translate }}
      </button>
      <span style="margin-left: 16px;">{{ 'COMMON_TEXT_OR' | translate }} </span>
      <button mat-button color="primary" (click)="finishLogout()">{{ 'GOBACK_TO_LOGIN_PAGE' | translate }}</button>
    </div>
  </form>
</div>
