import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarComponent } from '@shared/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(public snackBar: MatSnackBar) {}

  public alertSuccess(message?: string, duration?: number) {
    message = message || 'SNACK_BAR_DEFAULT_SUCCESS';
    return this.openSnackBar(message, 'success', duration);
  }

  public alertFailure(message?: string, duration?: number) {
    message = message || 'SNACK_BAR_DEFAULT_ERROR';
    return this.openSnackBar(message, 'error', duration);
  }

  public alertWarning(message: string, duration?: number) {
    return this.openSnackBar(message, 'warn', duration);
  }

  public alertInfo(message: string, duration?: number) {
    return this.openSnackBar(message, 'info', duration);
  }

  private openSnackBar(message: string, snackType: string, duration?: number) {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: duration || 2000,
      verticalPosition: 'top',
      panelClass: 'snack-bar-' + snackType,
      data: { message, snackType },
    });
  }
}
