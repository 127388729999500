<mat-tab-group animationDuration="0ms" [disableRipple]="true" mat-align-tabs="center"
  (selectedIndexChange)="$event === 1 ? wechatLogin() : null">
  <mat-tab label="{{ 'LOGIN_METHOD_ACCOUNT' | translate }}">
    <div class="logintab" fxLayout="column" fxLayoutAlign="center center">
      <form class="form" [formGroup]="loginForm">
        <mat-form-field>
          <input matInput formControlName="username" autocomplete="off" required
            [placeholder]="'LOGIN_TEXT_USERNAME' | translate" />
          <mat-error *ngIf="getControl('username').invalid">{{ getErrorMessage('username') }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="password" autocomplete="new-password" required
            [type]="hide ? 'password' : 'text'" [placeholder]="'LOGIN_TEXT_PASSWORD' | translate" />
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          <mat-error *ngIf="getControl('password').invalid">{{ getErrorMessage('password') }}</mat-error>
        </mat-form-field>
        <div class="forget-password">
          <a [routerLink]="['/passport/forget-password']">{{ 'LOGIN_TEXT_FORGET_PASSWORD' | translate }} </a>
        </div>
        <div *ngIf="isCheckCaptcha" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="5px">
          <mat-form-field fxFlex="55" fxFlex.xs="45">
            <input #captchaCtrl matInput formControlName="captcha" [placeholder]="'LOGIN_TEXT_CAPTCHA' | translate"
              autocomplete="off" required />
            <mat-error *ngIf="getControl('captcha').invalid">{{ getErrorMessage('captcha') }}</mat-error>
          </mat-form-field>
          <div fxFlex style="padding-left: 2%;">
            <div *ngIf="getControl('captcha').status === 'PENDING'">
              <img src="./assets/img/spinner_37_1s.gif" />
            </div>
            <mat-icon *ngIf="getControl('captcha').status === 'VALID'" class="captcha-success">done</mat-icon>
            <mat-icon *ngIf="getControl('captcha').errors?.captcha" class="captcha-failure">error</mat-icon>
          </div>
          <div fxFlex class="valid-code">
            <img #captchaImg [src]="captchaSource" (click)="captchaCtrl.value = ''; captchaImg.src = getCaptcha()" />
          </div>
        </div>
        <button mat-raised-button color="primary" (click)="onLogin()">
          {{ 'LOGIN_BUTTON_LOGIN' | translate }}
        </button>
      </form>
      <div class="tp-login">
        <div fxLayout fxLayoutAlign="space-between center">
          <div *ngIf="!isChinaNetwork" fxFlex fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="tp-box" (click)="facebookLogin()">
              <mat-icon svgIcon="facebook"></mat-icon>
              {{ 'LOGIN_METHOD_FACEBOOK' | translate }}
            </div>
          </div>
          <a class="register" routerLink="/passport/signup">{{ 'USER_REGISTER_ACCTOUNT' | translate }}</a>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="{{ 'LOGIN_METHOD_WECHAT' | translate }}">
    <div class="logintab" fxLayout="column" fxLayoutAlign="center center">
      <div class="qrcode">
        <label class="scan">{{ 'LOGIN_SCAN_QRCODE_CREATE' | translate }}</label>
        <div id="wx_container"></div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
