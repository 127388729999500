export enum SlideType {
  DEFAULT = '',
  TRANSITION = 'transition',
  VIDEO = 'video',
}

// export interface Template {
//   name: string;
//   duration: number;
//   slides: Array<Slides>;
//   type: string; // html5/canvas | video
//   previewImage?: string;
//   enable?: boolean;
//   published?: boolean;
//   customization?: boolean;
//   // draft?: boolean;    //true草稿
//   personal?: boolean; // true个人,false公共
//   screenOrientation?: number;
//   screenCount?: number;
//   width?: number;
//   height?: number;
// }

export interface Slides {
  background?: string;
  duration?: number;
  previewImage: string;
  // objects: (SlideImageObject | SlideTextObject | SlideVideoObject)[];
  objects: any[];
  type?: SlideType;
}

export interface SlideTextObject {
  type: string;
  id?: string;
  url?: string;
  hash?: string;
  text: string;
  color: string;
  fontsize: number;
  fontfamily: string;
  fontstyles?: any; // Array<number>
  stylesheet?: string;
  rect: CanvasRect;
  effect?: {
    typename?: string;
    scatter?: boolean;
    outline?: number;
    rotate?: number;
    alpha?: number;
    moveType?: string; // 循环走字
    moveSpeed?: number; // 走字速度
    textAlign?: string;
    shadow?: {
      color: string;
      x: number;
      y: number;
      blur: number;
    };
    borderBgColor?: string; // text bg color
    borderWidth?: number;
    borderColor?: string;
    borderDash?: any; // [7,3]
    borderType?: number;
    colors?: string | string[] /*Array<{
      color: string,
      offset: number, // 0-1
    }>*/;
  };
  tweens?: CanvasTween;
  startAt?: number;
  endAt?: number;
  locked?: boolean;
  visible?: boolean;
  target?: any;
}

export interface SlideImageObject {
  type: string;
  id: string;
  url: string;
  hash: string;
  rect: CanvasRect;
  sprite: CanvasSprite;
  effect?: {
    rotate?: number;
    alpha?: number;
    moveType?: string;
    playType: number; // 1一次 0循环
  };
  tweens?: CanvasTween;
  startAt?: number;
  endAt?: number;
  locked?: boolean;
  bground?: boolean;
  visible?: boolean;
  duration?: number;
}

export interface SlideVideoObject {
  type: string;
  id: string;
  url: string;
  rect?: CanvasRect;
  hash: string;
  startAt?: number;
  endAt?: number;
  locked?: boolean;
  visible?: boolean;
}

export interface CanvasRect {
  left: number;
  top: number;
  width?: number;
  height?: number;
}

export interface CanvasTween {
  loop: boolean | number; // 循环次数true重复,0执行一次   false
  data:
    | string
    | Array<{
        waitb?: number; // 在动画之前停留
        to: object; // { scaleX: number, scaleY: number, alpha: number },
        time: number; // 执行时长毫秒
        waita?: number; // 在动画之后停留
        ease: string;
      }>;
}

export interface CanvasSprite {
  width: number;
  height: number;
  frames?: number;
  index?: number;
}

export interface ImageElement {
  id: string;
  element: HTMLImageElement;
}

export interface LoadRequest {
  id: string;
  state: number; // 0加载中,1成功,2失败
  progress: number;
  loader?: Promise<any>;
  onProgress?: any;
}
