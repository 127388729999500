// USAGE:
//
// 当您将infiniteScroll指令附加到元素时，它将发出infiniteScrollAction
// 每次用户滚动到元素底部时，都会发生@Output（）事件。 您的负载更多文章
// 函数可以进行HTTP调用，然后将结果附加到商品列表中。 这样，
// 有效地增加了元素的高度，从而开始了infiniteScroll指令的过程
// 反复，直到元素高度停止增加。
//
// <div class="container" infiniteScroll (infiniteScrollAction)="loadMoreArticles()">
//     <div class="article" *ngFor="let article of articles">
//         ...
//     </div>
// </div>
//
// <div class="container" infiniteScroll [infiniteScrollContext]="'self'" (infiniteScrollAction)="loadMoreArticles()">
//     <div class="article" *ngFor="let article of articles">
//         ...
//     </div>
// </div>

import { Directive, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface Viewport {
  width: number;
  height: number;
}

// InfiniteScrollContext表示指令将在其中运行的上下文。
// 默认值为'document'，当结束时，这将触发您的操作
// 您的元素相对于文档滚动条已到达。
// 如果您使用“自我”，则在元素结束时将触发您的操作
// 相对于元素自己的滚动条已达到。
export type InfiniteScrollContext = 'self' | 'document';

@Directive({
  selector: '[nexInfiniteScroll]',
})
export class InfiniteScrollDirective implements OnInit {
  private el: any;
  private viewport: Viewport;
  private scrollEvent$: Observable<any>;

  @Input() infiniteScrollContext: InfiniteScrollContext = 'self';
  @Output() infiniteScrollAction: EventEmitter<any> = new EventEmitter();

  constructor(element: ElementRef) {
    this.el = element.nativeElement;
    this.viewport = this.getViewport(window);
  }

  ngOnInit() {
    if (this.infiniteScrollContext === 'self') {
      this.scrollEvent$ = fromEvent(this.el, 'scroll').pipe(debounceTime(250));

      this.scrollEvent$.subscribe((e: any) => {
        // console.log(Math.ceil(e.target.scrollTop + e.target.offsetHeight));
        // console.log(e.target.scrollHeight);
        if (Math.ceil(e.target.scrollTop + e.target.offsetHeight) >= e.target.scrollHeight) {
          this.infiniteScrollAction.emit(null);
        }
      });
    } else if (this.infiniteScrollContext === 'document') {
      this.scrollEvent$ = fromEvent(window.document, 'scroll').pipe(debounceTime(250));

      this.scrollEvent$.subscribe(() => {
        const rect = this.el.getBoundingClientRect();
        const elementTopRelativeToViewport = rect.top;
        const elementTopRelativeToDocument = elementTopRelativeToViewport + window.pageYOffset;
        const scrollableDistance = this.el.offsetHeight + elementTopRelativeToDocument;
        const currentPos = window.pageYOffset + this.viewport.height;

        if (currentPos > scrollableDistance) {
          this.infiniteScrollAction.emit(null);
        }
      });
    } else {
      throw new Error(
        `'infiniteScrollContext' contains invalid value ${this.infiniteScrollContext}. Only 'self' and 'document' are allowed.`,
      );
    }
  }

  private getViewport(win: Window): Viewport {
    // This works for all browsers except IE8 and before
    if (win.innerWidth != null) {
      return {
        width: win.innerWidth,
        height: win.innerHeight,
      };
    }

    // For IE (or any browser) in Standards mode
    const d = win.document;

    if (document.compatMode === 'CSS1Compat') {
      return {
        width: d.documentElement.clientWidth,
        height: d.documentElement.clientHeight,
      };
    }

    // For browsers in Quirks mode
    return {
      width: d.body.clientWidth,
      height: d.body.clientHeight,
    };
  }
}

// // https://blog.strongbrew.io/infinite-scroll-with-rxjs-and-angular2/
// 该版本需要计算每项的高度

// import { BehaviorSubject, fromEvent, merge } from 'rxjs';
// import { debounceTime, distinct, filter, flatMap, map, tap } from 'rxjs/operators';

// import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
// import { ServerResponsePagination } from '@core';

// interface ScrollPosition {
//   sT: number;
//   cH: number;
// }

// @Directive({
//   selector: '[nexInfiniteScroll]',
// })
// export class InfiniteScrollDirective implements AfterViewInit {
//   @Input() pageSize = 10;

//   @Input() itemHeight = 40;

//   @Input() scrollCallback: any;

//   @Output() scrollDataChange = new EventEmitter<any>();

//   @Output() pageChange = new EventEmitter<number>();

//   @Output() loading = new EventEmitter<boolean>();

//   @Output() ending = new EventEmitter<boolean>();

//   private cache = [];

//   private pageByManual$ = new BehaviorSubject(1);

//   private pageByScroll$ = fromEvent(this.el.nativeElement, 'scroll').pipe(
//     map((e: any): ScrollPosition => ({ sT: e.target.scrollTop, cH: e.target.clientHeight })),
//     debounceTime(200),
//     distinct(),
//     map((y) => Math.ceil((y.sT + y.cH) / (this.itemHeight * this.pageSize))),
//   );

//   private pageByResize$ = fromEvent(window, 'resize').pipe(
//     debounceTime(200),
//     map(() =>
//       Math.ceil((this.el.nativeElement.offsetHeight + this.el.nativeElement.scrollTop) / (this.itemHeight * this.pageSize)),
//     ),
//   );

//   private pageToLoad$ = merge(this.pageByManual$, this.pageByScroll$, this.pageByResize$).pipe(
//     distinct(),
//     filter((page) => this.cache[page - 1] === undefined),
//   );

//   private itemResults$ = this.pageToLoad$.pipe(
//     tap(() => this.loading.emit(true)),
//     debounceTime(400),
//     flatMap((page: number) => {
//       this.pageChange.emit(page);
//       return this.scrollCallback().pipe(
//         map((res: ServerResponsePagination) => res.data.rows),
//         tap((data: any) => {
//           this.loading.emit(false);
//           this.cache[page - 1] = data;
//           if (this.itemHeight * this.pageSize * page < window.innerHeight) {
//             this.pageByManual$.next(page + 1);
//           }
//         }),
//       );
//     }),
//     map(() => this.cache.reduce((a, b) => a.concat(b), [])),
//   );

//   constructor(private el: ElementRef) {}

//   ngAfterViewInit() {
//     this.itemResults$.subscribe((cacheData) => {
//       this.scrollDataChange.emit(cacheData);
//     });
//   }
// }
