<h1 matDialogTitle>{{ 'STUDIO_FIT_SCREEN_TITLE' | translate }}</h1>
<mat-dialog-content>
  <p class="fit-desc">{{ 'STUDIO_FIT_SCREEN_DESC' | translate }}</p>
  <mat-tab-group
    *ngIf="orientationOptions.length > 1"
    animationDuration="0ms"
    [disableRipple]="true"
    mat-align-tabs="center"
    mat-stretch-tabs
    (selectedIndexChange)="onSelectedIndexChange($event)"
  >
    <mat-tab *ngFor="let tab of orientationOptions" [label]="tab.text | translate">
      <ng-template [ngTemplateOutlet]="countList" [ngTemplateOutletContext]="{ $implicit: countOptions }"></ng-template>
    </mat-tab>
  </mat-tab-group>

  <ng-template
    *ngIf="orientationOptions.length <= 1"
    [ngTemplateOutlet]="countList"
    [ngTemplateOutletContext]="{ $implicit: countOptions }"
  ></ng-template>

  <ng-template #countList let-cl>
    <ul class="count-list">
      <ng-container *ngFor="let item of cl">
        <li (click)="selectedCount = item.value">
          <div>
            <span
              [ngStyle]="{ 'background-image': 'url(' + item.icon + ')' }"
              [style.padding-left]="selectedType === Orientation.Landscape ? '10px' : '0'"
              [style.padding-top]="selectedType === Orientation.Portrait ? '4px' : '0'"
            >
              {{ item.value }}
            </span>
            {{ item.text | translate }}
          </div>
          <mat-icon color="primary" class="mat-24" *ngIf="selectedCount === item.value">done</mat-icon>
        </li>
        <mat-divider></mat-divider>
      </ng-container>
    </ul>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button matDialogClose>{{ 'BUTTON_CANCEL' | translate }}</button>
  <button mat-button color="primary" (click)="done()" [disabled]="!isDone">
    {{ 'BUTTON_OK' | translate }}
  </button>
</mat-dialog-actions>
