import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  @Output() sidenavToggle: EventEmitter<boolean> = new EventEmitter();

  @Output() sidenavCollapse: EventEmitter<boolean> = new EventEmitter();

  isOpen = false;

  isExpanded = true;

  toggle() {
    this.isOpen = !this.isOpen;
    this.sidenavToggle.emit(this.isOpen);
  }

  collapse() {
    this.isExpanded = !this.isExpanded;
    this.sidenavCollapse.emit(this.isExpanded);
  }
}
