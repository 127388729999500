import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { UtilsService } from '@core';

@Component({
  selector: 'nex-sprite-player',
  template: `
    <div class="canvas-wrapper" [ngStyle]="{ width: fullWidth ? '100%' : width }">
      <nex-loading *ngIf="!isLoaded" loadingText="" textColor="#d5d5d5" spacing="{{ spacing + 'px' }}"></nex-loading>
      <canvas
        #spriteCanvas
        [hidden]="!isLoaded"
        [width]="width"
        [height]="height"
        [style.max-width]="canvasMaxWidth + 'px'"
      >
        Your browser does not support the HTML5 canvas tag.
      </canvas>
    </div>
  `,
  styles: [
    `
      .canvas-wrapper {
        background: #000;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class SpritePlayerComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('spriteCanvas', { static: true }) canvasElRef: ElementRef;
  @Input() width = 256;
  @Input() canvasMaxWidth = 760;
  @Input() fullWidth = false;
  @Input() isCompress = true;
  @Input() source: string | Array<File>;
  @Input() sprite: { width: number; height: number; count: number };

  mode = '';
  frameWidth = 0;
  frameHeight = 0;
  frameIndex = 0; // 当前帧
  totalFrames = 0; // 总帧数

  fps = 25;
  columnFrames = 16;
  lastDrawTimestamp = 0;
  drawImageMinInterval = 0;
  animationFrame = null;
  paused = false;
  isLoaded = false;
  images = [];
  canvas: HTMLCanvasElement;
  spacing = 16;

  height = 256;

  constructor(private utils: UtilsService) {
    this.drawImageMinInterval = 1000 / this.fps;
  }

  @Input('height')
  set inputHeight(height: number) {
    this.height = height;
    const _spacing = (height - 96) / 2;
    if (_spacing > this.spacing) {
      this.spacing = _spacing;
    }
  }

  ngAfterViewInit() {
    this.canvas = this.canvasElRef.nativeElement;
  }

  ngOnChanges() {
    if (this.isPlay()) {
      this.stop();
    }
    this.play();
  }

  ngOnDestroy() {
    if (this.isPlay()) {
      this.stop();
    }
  }

  play() {
    this.paused = false;
    this.isLoaded = false;
    this.images = [];
    if (typeof this.source === 'string') {
      this.mode = 'sprite';
      this.utils.checkRemoteImage(this.source, {
        success: (image: HTMLImageElement) => {
          this.isLoaded = true;
          this.images = [image];
          this.frameWidth = this.sprite.width || 0;
          this.frameHeight = this.sprite.height || 0;
          this.totalFrames = this.sprite.count || 0;
          this.loop();
        },
      });
    } else if (this.source instanceof Array) {
      this.mode = 'array';
      this.utils.loadImages(this.source).subscribe((res) => {
        this.isLoaded = true;
        this.images = res.images;
        if (this.isCompress) {
          const resize = this.utils.resizeImage(res.width, res.height, 256, 256);
          this.frameWidth = resize.width;
          this.frameHeight = resize.height;
        } else {
          this.frameWidth = res.width;
          this.frameHeight = res.height;
        }
        this.totalFrames = this.images.length;
        this.loop();
      });
    } else {
      this.paused = true;
      throw new Error('error: There is no matching pattern');
    }
  }

  stop() {
    this.paused = true;
    this.frameIndex = 0;
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
  }

  isPlay() {
    return this.paused === false;
  }

  getFps() {
    return this.frameIndex + 1;
  }

  draw() {
    const ctx = this.canvas.getContext('2d');

    ctx.clearRect(0, 0, this.width, this.height);

    if (this.mode === 'sprite') {
      const width = this.frameWidth;
      const height = this.frameHeight;
      const sx = width * (this.frameIndex % this.columnFrames);
      const sy = height * Math.floor(this.frameIndex / this.columnFrames);
      const x = (this.width - width) / 2;
      const y = (this.height - height) / 2;

      ctx.drawImage(this.images[0], sx, sy, width, height, x, y, width, height);
      this.frameIndex = (this.frameIndex + 1) % this.totalFrames;
    } else if (this.mode === 'array') {
      const currentImage = this.images[this.frameIndex];
      const sx = (this.width - this.frameWidth) / 2;
      const sy = (this.height - this.frameHeight) / 2;

      ctx.drawImage(currentImage, sx, sy, this.frameWidth, this.frameHeight);
      this.frameIndex = (this.frameIndex + 1) % this.images.length;
    } else {
      throw new Error('error: There is no matching pattern');
    }
  }

  loop() {
    const that = this;
    const elapsed = Date.now() - this.lastDrawTimestamp;
    if (elapsed >= this.drawImageMinInterval) {
      this.lastDrawTimestamp = Date.now();
      this.draw();
    }
    if (!this.paused) {
      this.animationFrame = requestAnimationFrame(() => that.loop());
    } else {
      console.log('cancelAnimationFrame');
      cancelAnimationFrame(this.animationFrame);
    }
  }
}
