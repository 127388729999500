import { Subscription, of } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService, RestService, SocialAccountType, UtilsService } from '@core';
import { environment } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { CaptchaValidator } from '@shared/validators/captcha.validator';
import { debounceTime, switchMap } from 'rxjs/operators';

declare let WxLogin: any;

@Component({
  selector: 'nex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  hide = true;
  wechatobj: any;
  i18n: any = {};
  isChinaNetwork = true;

  isCheckCaptcha = false;
  captchaSource: string;
  loginForm: FormGroup;
  private subscr: Subscription;

  constructor(
    @Inject(DOCUMENT) private doc: any,
    private translate: TranslateService,
    private fb: FormBuilder,
    private auth: AuthService,
    private alert: AlertService,
    private rest: RestService,
    private utils: UtilsService,
  ) {
    utils.networkChanged.subscribe((status) => {
      console.log('isChinaNetwork', status);
      this.isChinaNetwork = status;
    });
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      captcha: [''],
    });

    // this.auth.authChange.subscribe((user) => {
    //   if (user && !user.tokenAccountVerify) {
    //     this.alert.alertSuccess('LOGIN_TEXT_ALERT_SUCCESS');
    //   }
    // });

    this.getControl('username')
      .valueChanges.pipe(
        debounceTime(300),
        switchMap((value) => (value ? this.rest.getJson('v3/user/verify/' + value) : of({ data: false }))),
      )
      .subscribe((res) => {
        this.isCheckCaptcha = res.data;
        if (this.isCheckCaptcha) {
          this.initCaptchaValidator();
        } else {
          this.clearCaptchaValidator();
        }
      });

    this.translate.onLangChange.subscribe(() => {
      this.translate
        .get(['COMMON_TEXT_REQUIRED', 'LOGIN_VALIDATION_USERNAME', 'COMMON_TEXT_LENGTH_INCORRECT', 'LOGIN_VALIDATION_CAPTCHA'])
        .subscribe((data) => {
          this.i18n.COMMON_TEXT_REQUIRED = data.COMMON_TEXT_REQUIRED;
          this.i18n.LOGIN_VALIDATION_USERNAME = data.LOGIN_VALIDATION_USERNAME;
          this.i18n.COMMON_TEXT_LENGTH_INCORRECT = data.COMMON_TEXT_LENGTH_INCORRECT;
          this.i18n.LOGIN_VALIDATION_CAPTCHA = data.LOGIN_VALIDATION_CAPTCHA;
        });
    });
  }

  onLogin() {
    if (this.isCheckCaptcha && this.getControl('captcha').status !== 'VALID') {
      return;
    }
    if (this.loginForm.invalid) {
      return;
    }
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;
    const captcha = this.loginForm.value['captcha'] || '';

    this.utils.showSpinner(true);
    this.auth.login(username, encodeURI(password), captcha).subscribe((res) => {
      this.utils.showSpinner(false);
      if (res && res.access_token) {
        return;
      }
      this.isCheckCaptcha = (res.msg && res.msg >= 3) || (res.msgCode && res.msgCode === 'MSG_VERIFICATION_EXPIRED');
      if (this.isCheckCaptcha) {
        this.initCaptchaValidator();
      }
    });
  }

  wechatLogin() {
    if (!this.wechatobj) {
      setTimeout(() => {
        this.wechatobj = new WxLogin({
          self_redirect: false,
          id: 'wx_container',
          appid: environment.socialLogin.appid,
          scope: 'snsapi_login',
          redirect_uri: encodeURIComponent(environment.socialLogin.bindUri + '?type=' + SocialAccountType.Weixin),
          state: Date.now(),
          style: 'black',
          // tslint:disable-next-line: max-line-length
          href: `data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9`,
        });
      });
    }
  }

  facebookLogin() {
    const host = 'https://www.facebook.com/v7.0/dialog/oauth';
    const clientId = environment.socialLogin.client_id;
    const bindUri = encodeURIComponent(environment.socialLogin.bindUri + '?type=' + SocialAccountType.Facebook);
    const state = Date.now();

    this.doc.location.href = `${host}?client_id=${clientId}&redirect_uri=${bindUri}&state=${state}`;
  }

  initCaptchaValidator() {
    this.captchaSource = this.getCaptcha();

    const captchaCtrl = this.getControl('captcha');
    captchaCtrl.setValue('');

    captchaCtrl.setValidators([Validators.required, Validators.minLength(6)]);
    this.subscr = captchaCtrl.valueChanges.subscribe((value) => {
      if (value.length >= 4) {
        captchaCtrl.setAsyncValidators(CaptchaValidator.createValidator(this.auth));
      }
    });
  }

  clearCaptchaValidator() {
    const captchaCtrl = this.getControl('captcha');
    captchaCtrl.clearValidators();
    captchaCtrl.clearAsyncValidators();
    captchaCtrl.setValue('');
    this.captchaSource = '';
    if (this.subscr) {
      this.subscr.unsubscribe();
    }
  }

  getCaptcha() {
    return this.auth.getCaptchaSource() + '?r=' + Math.random();
  }

  getControl(controlName: string) {
    return this.loginForm.get(controlName) as FormControl;
  }

  getErrorMessage(controlName: string) {
    const control = this.getControl(controlName);
    if (control.hasError('required')) {
      return this.i18n.COMMON_TEXT_REQUIRED;
    }
    if (control.hasError('username')) {
      return this.i18n.LOGIN_VALIDATION_USERNAME;
    }
    if (control.hasError('minlength')) {
      return this.i18n.COMMON_TEXT_LENGTH_INCORRECT;
    }
    if (control.hasError('captcha')) {
      return this.i18n.LOGIN_VALIDATION_CAPTCHA;
    }
    return '';
  }
}
