<h1 mat-dialog-title>
  <mat-icon class="mat-24" color="accent" style="vertical-align: middle;">warning</mat-icon>
  {{ data.title | translate }}
</h1>
<div style="line-height: 24px;">
  {{ data.body | translate }}
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="dialogRef.close()" *ngIf="data.btnCancel">
    {{ data.btnCancel | translate }}
  </button>
  <button mat-button (click)="dialogRef.close(true)" color="primary">
    {{ data.btnOk | translate }}
  </button>
</div>
