<h1 matDialogTitle>{{ 'USER_SIGN_UP_COUNTRY_DIALOG_TITLE' | translate }}</h1>

<mat-dialog-content>
  <div class="country" *ngIf="countries.length && !isLoading">
    <ng-container *ngFor="let parent of countries; first as isFirst">
      <mat-expansion-panel [expanded]="selectedContinent ? selectedContinent === parent.continents : isFirst">
        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
          <mat-panel-title>
            <a class="parent item" [ngClass]="{ active: selectedContinent === parent.continents }">
              {{ lang == 'zh' ? parent.chineseName : parent.continents }}
            </a>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <ul class="items">
          <li
            [ngClass]="{ 'last-item': isLast }"
            (click)="$event.preventDefault(); selectedCountry = sub; selectedContinent = parent.continents"
            *ngFor="let sub of parent.countryList; last as isLast"
          >
            <a class="item" [ngClass]="{ active: this.selectedCountry?.acronym === sub.acronym }">
              {{ lang == 'zh' ? sub.nativeName : sub.country }}
              <mat-icon *ngIf="this.selectedCountry?.acronym === sub.acronym" color="primary" style="float: right;"
                >flag</mat-icon
              >
            </a>
          </li>
        </ul>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
    </ng-container>
  </div>
  <nex-loading *ngIf="isLoading && !countries.length"></nex-loading>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button matDialogClose>{{ 'BUTTON_CANCEL' | translate }}</button>
  <button mat-button color="primary" (click)="selectCountry($event)" [disabled]="!selectedCountry">
    {{ 'BUTTON_OK' | translate }}
  </button>
</mat-dialog-actions>
