<div
  [ngStyle]="{
    width: fullWidth ? '100%' : width + 'px',
    height: height + 'px',
    cursor: cursor,
    background: background
  }"
  class="image-viewer-wrapper"
>
  <label
    (click)="clickImage($event)"
    class="image-viewer-preview"
    [ngStyle]="{
      width: width + 'px',
      height: height + 'px',
      cursor: cursor,
      border: hasBorder ? '1px dashed ' + borderColor : 'none'
    }"
  >
    <img [src]="src" (mouseover)="onMouseOver($event)" (mouseout)="onMouseOut($event)" [title]="imgTitle" />
    <input *ngIf="!readonly" type="file" hidden [accept]="mimeType" (change)="changeFile($event)" />
  </label>
</div>
