import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService, RestService, AlertService, Verify, ServerResponse, ResponseCode } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariable } from '@global';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ValidateMobile } from '@shared/validators/common.validator';
import { switchMap, finalize } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'nex-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit, OnDestroy {
  dialogI18nTitle: string;
  isPhone = false;
  isEmail = false;
  isVerify = false; // 是否为验证类型
  isNeedVerify = false; // 是否需要验证
  isCn = true;
  isLoading = false;
  counter: number;
  resendTimer: any;
  verifyForm: FormGroup;
  dialogI18nTitleObj = {
    bind: 'VERIFY_DIALOG_TITLE_BIND',
    change: 'VERIFY_DIALOG_TITLE_CHANGE',
    verify: 'VERIFY_DIALOG_TITLE_VERIFY',
  };
  verifyTypeI18nObj = { phone: 'USER_TEXT_PHONE', email: 'USER_TEXT_EMAIL' };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Verify,
    public dialogRef: MatDialogRef<VerifyComponent>,
    private utils: UtilsService,
    private rest: RestService,
    private alert: AlertService,
    private fb: FormBuilder,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    console.log('data', this.data);
    this.dialogI18nTitle = this.getDialogTitle();
    this.isCn = (this.data.countryCode || '').toUpperCase() === 'CN';
    this.isVerify = this.data.showType === 'verify';
    this.isPhone = this.data.verifyType === GlobalVariable.VERIFY_TYPE_PHONE;
    this.isEmail = this.data.verifyType === GlobalVariable.VERIFY_TYPE_EMAIL;
    this.isNeedVerify = this.isVerify || (!this.isVerify && ((this.isCn && this.isPhone) || (!this.isCn && this.isEmail)));
    this.verifyForm = this.createVerifyForm();
  }

  ngOnDestroy(): void {
    if (this.resendTimer) {
      window.clearInterval(this.resendTimer);
    }
  }

  createVerifyForm(): FormGroup {
    const formGroup = this.fb.group({});
    if (!this.isVerify && this.isPhone) {
      const phoneValidators = [Validators.required];
      if (this.isCn) {
        phoneValidators.push(ValidateMobile);
      }
      formGroup.addControl('phone', new FormControl('', phoneValidators));
    }
    if (!this.isVerify && this.isEmail) {
      formGroup.addControl('email', new FormControl('', [Validators.required, Validators.email]));
    }
    if (this.isNeedVerify) {
      formGroup.addControl('code', new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,6}$')]));
    }
    return formGroup;
  }

  getDialogTitle(): string {
    const showTypeI18n = this.dialogI18nTitleObj[this.data.showType];
    const verifyTypeI18n = this.verifyTypeI18nObj[this.data.verifyType];
    return this.translate.instant(showTypeI18n, { type: this.translate.instant(verifyTypeI18n) });
  }

  getCtrl(ctrlName: string): FormControl {
    return this.verifyForm.get(ctrlName) as FormControl;
  }

  getValidCode(event) {
    event.preventDefault();
    const _url = 'v3/auth/getverifycode';
    const _params = {
      eventType: 1,
      type: this.data.verifyType,
    };
    if (!this.isVerify) {
      Object.assign(_params, { number: this.getCtrl(this.data.verifyType).value });
    }
    this.isLoading = true;
    timer(800)
      .pipe(switchMap(() => this.rest.postJson(_url, _params).pipe(finalize(() => (this.isLoading = false)))))
      .subscribe((res: ServerResponse) => {
        if (res.code === ResponseCode.Success) {
          this.counter = GlobalVariable.VERIFY_COUNTER;
          this.resendTimer = window.setInterval(() => {
            if (this.counter > 0) {
              this.counter--;
            } else {
              window.clearInterval(this.resendTimer);
            }
          }, 1000);
        }
      });
  }

  onSave() {
    if (this.verifyForm.invalid) {
      return;
    }
    let saveData = this.verifyForm.value;
    if (this.isNeedVerify) {
      saveData = Object.assign({}, saveData, { type: this.data.verifyType });
    }
    let url = 'v3/user/profile';
    let method = 'putJson';
    if (this.isVerify) {
      url = 'v3/auth/checkverifycode';
      method = 'postJson';
      saveData = Object.assign({}, saveData, { eventType: 1 });
    }
    this.utils.showSpinner(true);
    this.rest[method](url, saveData).subscribe(
      (res: ServerResponse) => {
        this.utils.showSpinner(false);
        if (res.code === ResponseCode.Success) {
          this.alert.alertSuccess();
          this.dialogRef.close(true);
        }
      },
      () => {
        this.utils.showSpinner(false);
        this.alert.alertFailure();
      },
    );
  }
}
