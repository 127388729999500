import { Component } from '@angular/core';

@Component({
  selector: 'layout-passport',
  template: `
    <div class="container">
      <header-i18n fxFlexAlign="end"></header-i18n>
      <div class="wrap">
        <div class="top">
          <img class="logo" src="./assets/img/logo_primary.png" />
        </div>
        <div class="center" fxLayout fxLayoutAlign="center">
          <div class="box" fxFlex="500px" fxFlex.lt-sm="100" fxLayout fxLayoutAlign="center">
            <div fxFlex="400px" fxFlex.lt-sm="100">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        {{ 'COPYRIGHT_INFOMATION' | translate }}
        {{ 'FOOTER_TEXT_VISION' | translate }}
      </div>
    </div>
  `,
  styleUrls: ['./passport.component.scss'],
})
export class LayoutPassportComponent {}
