import { map } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { AuthService } from '@core';

export class AsyncPasswordValidator {
  static createValidator(auth: AuthService, userId?: string) {
    return (control: AbstractControl) => {
      return auth.verifyAsyncPassword(control.value, userId).pipe(map((res) => (res.data ? null : { asyncPassword: true })));
    };
  }
}
