import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nex-color-selector',
  template: `
    <div class="color-menu" [style.max-width]="panelWdith + 'px'" (click)="$event.stopPropagation()">
      <ng-container *ngFor="let item of colorOptions">
        <div class="color-items" [class.color-checked]="color === item" (click)="onColorSelect(item)">
          <span class="color-self" [ngStyle]="{ background: item }"></span>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .color-menu {
        padding: 8px 16px;
      }
      .color-items {
        width: 36px;
        height: 36px;
        display: inline-block;
        cursor: pointer;
        padding: 1px;
        border-radius: 4px;
        border: 2px solid transparent;
        transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      }
      .color-items:hover {
        border-color: #a7acbc;
        box-shadow: 0px 0px 5px #555c70;
      }
      .color-items .color-self {
        width: 100%;
        height: 100%;
        background: #000;
        display: inline-block;
        border-radius: 4px;
      }
      .color-checked {
        border-color: #a7acbc;
        box-shadow: 0px 0px 5px #555c70;
      }
    `,
  ],
})
export class ColorSelectorComponent {
  @Input() color: string;
  @Input() panelWdith = 285;
  @Input() colorOptions: string[] = [];
  @Output() colorSelect = new EventEmitter<string>();

  constructor() {}

  onColorSelect(color: string) {
    this.colorSelect.emit(color);
  }
}
