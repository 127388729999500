import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nex-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @Output() clickDay = new EventEmitter();
  @Output() changeDate = new EventEmitter();
  @Output() moveStart = new EventEmitter();
  @Output() changeMonth = new EventEmitter();
  @Input() color = '';
  @Input() tagid = '';
  @Input() readonly = 'false';
  @Input() showMonthButton = 'true';
  @Input() disabledPrev = false;
  @Input() disabledNext = false;

  showWeek = true;
  weekStart = 1; // 0星期天, 1星期1
  // defaultWeeks = ['日', '一', '二', '三', '四', '五', '六'];
  defaultWeeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']; // 默认
  weeks: any[]; // 显示用
  // months = ['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月'];
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  dayItems: Array<{
    day: string;
    date: string;
    selected: boolean;
    color: string;
    disabled?: boolean;
    tagid?: string;
  }> = [];
  schedules: Array<{ date: string; tagid: string; color: string }> = [];
  inputDate: Date; // 当前时间
  curYear: number; // 当前年份
  curMonth: number; // 当前月
  today: number; // 当天
  moveRanges: { sday: number; eday: number } = { sday: 0, eday: 0 }; // 记录触摸移动范围
  monthTitle: string;
  // disabledPrev: boolean = false;
  // disabledNext: boolean = false;

  // isMove : boolean = false;
  // moveTime : number = 0;
  constructor(private translate: TranslateService) {
    // console.log(this.isLeap(2019))
    if (!this.inputDate) {
      this.inputDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
    this.translate
      .get([
        'LANG_WEEKS_START',
        'LANG_WEEKS_SU',
        'LANG_WEEKS_MO',
        'LANG_WEEKS_TU',
        'LANG_WEEKS_WE',
        'LANG_WEEKS_TH',
        'LANG_WEEKS_FR',
        'LANG_WEEKS_ST',
        'LANG_MONTHS_0',
        'LANG_MONTHS_1',
        'LANG_MONTHS_2',
        'LANG_MONTHS_3',
        'LANG_MONTHS_4',
        'LANG_MONTHS_5',
        'LANG_MONTHS_6',
        'LANG_MONTHS_7',
        'LANG_MONTHS_8',
        'LANG_MONTHS_9',
        'LANG_MONTHS_10',
        'LANG_MONTHS_11',
      ])
      .subscribe((data) => {
        this.defaultWeeks = [
          data.LANG_WEEKS_SU,
          data.LANG_WEEKS_MO,
          data.LANG_WEEKS_TU,
          data.LANG_WEEKS_WE,
          data.LANG_WEEKS_TH,
          data.LANG_WEEKS_FR,
          data.LANG_WEEKS_ST,
        ];
        this.weeks = this.defaultWeeks;
        this.weekStart = Number.parseInt(data.LANG_WEEKS_START, 10);
        if (this.weekStart === 1) {
          this.weeks.push(this.weeks.shift());
        }
        this.months = [
          data.LANG_MONTHS_0,
          data.LANG_MONTHS_1,
          data.LANG_MONTHS_2,
          data.LANG_MONTHS_3,
          data.LANG_MONTHS_4,
          data.LANG_MONTHS_5,
          data.LANG_MONTHS_6,
          data.LANG_MONTHS_7,
          data.LANG_MONTHS_8,
          data.LANG_MONTHS_9,
          data.LANG_MONTHS_10,
          data.LANG_MONTHS_11,
        ];
        console.log(this.weeks, this.months);
      });
  }

  @Input('schedules')
  set setSchedules(items) {
    this.schedules = items;
    // console.log('setSchedules', items);
    if (this.dayItems.length > 0) {
      this.dayItems.forEach((o) => {
        // 要显示过期的颜色可以去掉&& !o.disabled
        if (o.day !== '' && !o.disabled) {
          o.color = items[o.date] ? items[o.date].color : ''; // o.color;
          o.selected = items[o.date] ? true : false; // o.selected;
          o.tagid = items[o.date] ? items[o.date].tagid : ''; // o.tagid;
        }
      });
    }
  }

  @Input('show-week')
  set setShowWeek(show: string) {
    this.showWeek = show === 'true' ? true : false;
  }

  @Input('week-start')
  set setWeekStart(num: number) {
    num = num === 0 ? 0 : 1;
    if (this.weekStart !== num) {
      this.weekStart = num;
      this.weeks = this.defaultWeeks;
      if (this.weekStart === 1) {
        this.weeks.push(this.weeks.shift());
      }
      this.initCalendar();
    }
  }

  @Input('date')
  set setDate(date: string) {
    if (!date) {
      return;
    }
    const curDate = new Date(date);
    // console.log('date',date,_date.getFullYear(),_date.getMonth());
    this.inputDate.setFullYear(curDate.getFullYear());
    this.inputDate.setMonth(curDate.getMonth());
    console.log('setDate', this.inputDate.getMonth() + 1, this.inputDate.getDate());
    this.initCalendar();
  }

  @Input('add-month')
  set setMonth(num: any) {
    num = parseInt(num, 10);
    this.inputDate.setMonth(this.inputDate.getMonth() + num); // +1 -1
    this.initCalendar();
  }

  ngOnInit(): void {
    this.initCalendar();
  }

  initCalendar(): void {
    this.dayItems = [];
    this.curYear = this.inputDate.getFullYear();
    this.curMonth = this.inputDate.getMonth();
    this.monthTitle = this.months[this.curMonth] + ' ' + this.curYear;
    // console.log(this.curYear, this.curMonth);
    const date = new Date(this.curYear, this.curMonth + 1, 0); // 把时间设为下个月的1号,天数减去1,得到当月最后一天;
    const lastDay = date.getDate(); // 当前月最后一天
    let firstDayWeek = new Date(this.curYear, this.curMonth, 1).getDay(); // 获取当前月第一天 是 周几
    firstDayWeek = this.weekStart === 1 && firstDayWeek === 0 ? 6 + 1 : firstDayWeek; // 注意0为日
    // console.log('lastDay:' + lastDay, firstDayWeek);
    // 渲染月前面的空白
    for (let i = this.weekStart; i < firstDayWeek; i++) {
      this.dayItems.push({
        day: '',
        selected: false,
        color: '',
        date: '',
        tagid: '',
        disabled: true,
      });
    }
    const todayDate = new Date();
    // 利用当月最后一天获取天
    for (let i = 1; i <= lastDay; i++) {
      const fullmonth = this.curMonth < 9 ? '0' + (this.curMonth + 1) : this.curMonth + 1;
      const fullday = i < 10 ? '0' + i : i;
      const curDate = this.curYear + '-' + fullmonth + '-' + fullday;
      const disabled = this.getTime(curDate) < todayDate.getTime() - 86400000 ? true : false; // @todo 当天
      this.dayItems.push({
        day: '' + i,
        date: curDate,
        selected: false,
        color: '',
        tagid: '',
        disabled,
      });
    }
    this.dayItems.forEach((o) => {
      if (o.day !== '' && !o.disabled) {
        o.color = this.schedules[o.date] ? this.schedules[o.date].color : ''; // o.color;
        o.selected = this.schedules[o.date] ? true : false; // o.selected;
        o.tagid = this.schedules[o.date] ? this.schedules[o.date].tagid : ''; // o.tagid;
      }
    });
  }
  // num = +1 or -1
  setYear(num: number): void {
    this.inputDate.setFullYear(this.inputDate.getFullYear() + num); // +1 -1
    this.initCalendar();
  }
  // num = +1 or -1
  onMonthToggle(num: number): void {
    if (num < 0 && this.disabledPrev) {
      return;
    }
    if (num > 0 && this.disabledNext) {
      return;
    }
    const date = new Date(this.inputDate.getFullYear(), this.inputDate.getMonth(), 1);
    date.setMonth(date.getMonth() + num); // +1 -1
    this.changeMonth.emit(date);
  }

  onClickDay(item, ev): void {
    // console.log(item);
    if (item.day === '' || item.disabled || this.readonly === 'true') {
      return;
    }
    if (this.color === '') {
      item.selected = false;
      item.tagid = '';
      item.color = '';
    } else {
      console.log(item.color);
      console.log(this.color);
      item.selected = item.color !== this.color ? true : !item.selected;
      console.log('xx2', item.selected);
      item.tagid = item.selected || item.color !== this.color ? this.tagid : ''; // this.tagid
      item.color = item.selected || item.color !== this.color ? this.color : ''; // this.color
      console.log('ddd', item.color);
    }
    this.clickDay.emit(item);
  }

  touchMove(ev): void {
    if (this.color === '' || !ev.targetTouches || this.readonly === 'true') {
      return;
    }
    const touch = ev.targetTouches[0];
    const ele = document.elementFromPoint(touch.pageX, touch.pageY);
    if (ele && ele.id) {
      // 记录开始和最后触摸点
      this.moveRanges.eday = parseInt(ele.id, 10);
      if (!this.moveRanges.sday) {
        this.moveRanges.sday = parseInt(ele.id, 10);
        // console.log(this.moveRanges.sday);
      }
      if (this.moveRanges.sday < this.moveRanges.eday) {
        for (let i = this.moveRanges.sday; i <= this.moveRanges.eday; i++) {
          this.dayItems.forEach((o) => {
            if (!o.disabled && i === parseInt(o.day, 10)) {
              o.selected = true;
              o.color = this.color;
              o.tagid = this.tagid;
            }
          });
        }
      }
    }
  }

  touchStart(ev): void {
    if (this.color === '' || this.readonly === 'true') {
      return;
    }
    // this.moveTime = (new Date()).getTime();
    // console.log("touchStart",this.moveTime);
    this.moveStart.emit(ev);
  }

  touchEnd(ev, day): void {
    if (this.color === '' || this.readonly === 'true') {
      return;
    }
    console.log('touchEnd', this.moveRanges);
    if (day !== '') {
      const days = [];
      this.dayItems.forEach((o) => {
        if (o.tagid !== '' && o.day !== '' && o.selected && !o.disabled) {
          days.push(o);
        }
      });
      this.changeDate.emit(days);
    }
    // 手势抬起重置
    this.moveRanges.sday = 0;
    this.moveRanges.eday = 0;
  }

  getTime(dateTime: string): number {
    return new Date(Date.parse(dateTime)).getTime();
  }
}
