import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '@core';
import { environment } from '@env';

@Component({
  selector: 'nex-callback',
  template: ``,
})
export class CallbackComponent {
  constructor(private rest: RestService, private router: Router) {
    const bindData = {
      code: this.rest.getQueryString('code') || '',
      state: this.rest.getQueryString('state') || '',
      identityType: Number.parseInt(this.rest.getQueryString('type') || '', 10),
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    };
    this.rest.putJson('v3/account/bind', bindData).subscribe((res) => {
      this.router.navigate(['/account/settings/binding']);
    });
  }
}
