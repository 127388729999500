import defaults from 'lodash-es/defaults';
import flatMap from 'lodash-es/flatMap';
import { Subject } from 'rxjs';

import { Component, ElementRef, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Required, checkRequired } from './decorators/required.decorator';
import { defaultOptions } from './default.options';
import { Node, Options, SearchableNode, TreeTableNode } from './models';
import { ConverterService } from './services/converter.service';
import { TreeService } from './services/tree.service';
import { ValidatorService } from './services/validator.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ng-treetable, treetable', // 'ng-treetable' is currently being deprecated
  templateUrl: './treetable.component.html',
  styleUrls: ['./treetable.component.scss'],
  providers: [ConverterService, TreeService, ValidatorService],
})
export class TreetableComponent<T> implements OnInit, OnChanges {
  @Input() @Required tree: Node<T> | Node<T>[];
  @Input() options: Options<T> = {};
  @Output() nodeClicked: Subject<TreeTableNode<T>> = new Subject();
  @Output() rowClicked: Subject<TreeTableNode<T>> = new Subject();

  private searchableTree: SearchableNode<T>[];
  private treeTable: TreeTableNode<T>[];
  displayedColumns: string[];
  dataSource: MatTableDataSource<TreeTableNode<T>>;

  constructor(
    private treeService: TreeService,
    private validatorService: ValidatorService,
    private converterService: ConverterService,
    elem: ElementRef,
  ) {
    const tagName = elem.nativeElement.tagName.toLowerCase();
    if (tagName === 'ng-treetable') {
      console.warn(
        `DEPRECATION WARNING: \n The 'ng-treetable' selector is being deprecated. Please use the new 'treetable' selector`,
      );
    }
  }

  ngOnInit() {
    checkRequired(this);
  }

  ngOnChanges(): void {
    this.tree = Array.isArray(this.tree) ? this.tree : [this.tree];
    this.options = this.parseOptions(defaultOptions);
    const customOrderValidator = this.validatorService.validateCustomOrder(this.tree[0] as any, this.options.customColumnOrder);
    if (this.options.customColumnOrder && !customOrderValidator.valid) {
      throw new Error(`
        Properties ${customOrderValidator.redundancy.map((x) => `'${x}'`).join(', ')} incorrect or missing in customColumnOrder`);
    }
    this.displayedColumns = this.options.customColumnOrder ? this.options.customColumnOrder : this.extractNodeProps(this.tree[0]);
    this.searchableTree = this.tree.map((t) => this.converterService.toSearchableTree(t));
    const treeTableTree = this.searchableTree.map((st) => this.converterService.toTreeTableTree(st));
    this.treeTable = flatMap(treeTableTree, this.treeService.flatten);
    this.dataSource = this.generateDataSource();
  }

  extractNodeProps(tree: Node<T> & { value: { [k: string]: any } }): string[] {
    return Object.keys(tree.value).filter((x) => typeof tree.value[x] !== 'object');
  }

  generateDataSource(): MatTableDataSource<TreeTableNode<T>> {
    return new MatTableDataSource(this.treeTable.filter((x) => x.isVisible));
  }

  formatIndentation(node: TreeTableNode<T>, step: number = 5): string {
    return '&nbsp;'.repeat(node.depth * step);
  }

  formatElevation(): string {
    return `mat-elevation-z${this.options.elevation}`;
  }

  onNodeClick(clickedNode: TreeTableNode<T>): void {
    clickedNode.isExpanded = !clickedNode.isExpanded;
    this.treeTable.forEach((el) => {
      el.isVisible = this.searchableTree.every((st) => {
        return this.treeService
          .searchById(st, el.id)
          .fold([], (n) => n.pathToRoot)
          .every((p) => this.treeTable.find((x) => x.id === p.id).isExpanded);
      });
    });
    this.dataSource = this.generateDataSource();
    this.nodeClicked.next(clickedNode);
  }

  onRowClick(clickedRow: TreeTableNode<T>): void {
    this.treeTable.forEach((el) => {
      el.isSelected = el.id === clickedRow.id;
    });
    this.rowClicked.next(clickedRow);
  }

  // Overrides default options with those specified by the user
  parseOptions(defaultOpts: Options<T>): Options<T> {
    return defaults(this.options, defaultOpts);
  }
}
