<h1 matDialogTitle>{{ 'DESIGNER_DETAIL_TITLE' | translate }}</h1>

<mat-dialog-content>
  <div class="esign-previewer" *ngIf="[ContentType.PoGallery, ContentType.PoTemplate].indexOf(data.type) < 0">
    <nex-previewer [contentType]="data.type" [contentData]="data.item" [deviceType]="data.deviceType">
    </nex-previewer>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="16px">
    <div fxFlex>
      <mat-form-field class="full-width">
        <input
          matInput
          type="text"
          [value]="itemData.name"
          readonly
          placeholder="{{ 'DESIGNER_TEMPLATES_NAME' | translate }}"
        />
      </mat-form-field>
      <mat-form-field
        class="full-width"
        *ngIf="[ContentType.PoTemplate, ContentType.EsTemplate].indexOf(data.type) >= 0"
      >
        <input
          matInput
          type="text"
          [value]="itemData | templateTypeText | async"
          readonly
          placeholder="{{ 'DESIGNER_TEMPLATES_TYPE' | translate }}"
        />
      </mat-form-field>
      <ng-container *ngIf="data.type === ContentType.PoGallery">
        <mat-form-field class="full-width">
          <input
            matInput
            type="text"
            [value]="itemData.type | galleryTypeText | async"
            readonly
            placeholder="{{ 'DESIGNER_GALLERIES_TYPE' | translate }}"
          />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            matInput
            type="text"
            placeholder="{{ 'DESIGNER_TEMPLATES_IS_CUSTOMIZE' | translate }}"
            value="{{ itemData.customization | convertBool: 'COMMON_TEXT_YES':'COMMON_TEXT_NO' | translate }}"
            readonly
          />
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="itemData.customization">
          <input
            matInput
            type="text"
            placeholder="{{ 'DESIGNER_GALLERIES_INPUT_CUSTOM_TEXT' | translate }}"
            value="{{ (itemData.text || []).join(',') }}"
            readonly
          />
        </mat-form-field>
      </ng-container>

      <ng-container
        *ngIf="
          [ContentType.EsTemplate, ContentType.EsAnimatedGallery, ContentType.EsBackgroundGallery].indexOf(data.type) >=
          0
        "
      >
        <mat-form-field class="full-width" *ngIf="data.type !== ContentType.EsTemplate">
          <input
            matInput
            type="text"
            [value]="itemData.contentType | esGalleryContentTypeText | async"
            readonly
            placeholder="{{ 'ESIGN_TEXT_CONTENT_TYPE' | translate }}"
          />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            matInput
            type="text"
            placeholder="{{ 'ESIGN_SCREEN_ORIEN' | translate }}"
            value="{{ itemData.screenOrientation | screenOrientationText | async }}"
            readonly
          />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            matInput
            type="text"
            placeholder="{{ 'ESIGN_SCREEN_COUNT' | translate }}"
            value="{{ itemData.screenCount }}"
            readonly
          />
        </mat-form-field>
      </ng-container>

      <mat-form-field class="full-width">
        <input
          matInput
          type="text"
          [value]="itemData.price"
          readonly
          placeholder="{{ 'DESIGNER_TEMPLATES_PRICE' | translate }}"
        />
      </mat-form-field>
      <mat-form-field
        class="full-width"
        *ngIf="[ContentType.PoTemplate, ContentType.EsTemplate].indexOf(data.type) >= 0"
      >
        <input
          matInput
          type="textarea"
          [value]="itemData.description"
          readonly
          placeholder="{{ 'DESIGNER_TEMPLATES_DESCRIPTION' | translate }}"
        />
      </mat-form-field>
      <p class="label-category">{{ 'DESIGNER_CATEGORY' | translate }}</p>
      <ul class="checkbox-list" *ngIf="isCategoryLoaded; else loadingTpl">
        <li *ngFor="let parent of categories">
          <mat-checkbox
            color="primary"
            (click)="(false)"
            [checked]="parent.checked"
            [indeterminate]="parent.indeterminate"
          >
            {{ parent.className }}
          </mat-checkbox>
          <div class="child">
            <div class="child-item" *ngFor="let child of parent.childs">
              <mat-checkbox color="primary" (click)="(false)" [checked]="child.checked">
                {{ child.className }}
              </mat-checkbox>
            </div>
          </div>
        </li>
      </ul>
      <ng-template #loadingTpl>
        <nex-loading></nex-loading>
      </ng-template>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div fxFlex="35" style="max-width: 416px;">
      <div *ngIf="[ContentType.PoGallery, ContentType.PoTemplate].indexOf(data.type) >= 0" style="margin-bottom: 16px;">
        <nex-previewer
          [contentType]="data.type"
          [contentData]="data.item"
          [scale]="0.65"
          [deviceType]="data.deviceType"
        >
        </nex-previewer>
      </div>
      <div class="log-item" *ngFor="let log of reviewLog; last as isLast">
        <p>
          {{ 'LOG_REVIEW_AUDIT_STATUS' | translate }}<label>{{ log.auditText | translate }}</label>
        </p>
        <p>{{ 'LOG_REVIEW_CHANGE_BY' | translate }} {{ log.changeByName }}</p>
        <p>{{ 'LOG_REVIEW_CHANGE_AT' | translate }} {{ log.changeDate | date: 'short' }}</p>
        <p>{{ 'LOG_REVIEW_MESSAGE' | translate }} {{ log.message }}</p>
        <mat-divider *ngIf="!isLast"></mat-divider>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button matDialogClose>{{ 'BUTTON_CLOSE' | translate }}</button>
</mat-dialog-actions>
