import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MatPaginatorI18n extends MatPaginatorIntl {
  constructor(translate: TranslateService) {
    super();

    const initLang = () => {
      translate
        .get([
          'PAGINATOR_ITEMS_PER_PAGE',
          'PAGINATOR_NEXT_PAGE',
          'PAGINATOR_PREV_PAGE',
          'PAGINATOR_LAST_PAGE',
          'PAGINATOR_FIRST_PAGE',
        ])
        .subscribe((i18n) => {
          this.itemsPerPageLabel = i18n.PAGINATOR_ITEMS_PER_PAGE;
          this.nextPageLabel = i18n.PAGINATOR_NEXT_PAGE;
          this.previousPageLabel = i18n.PAGINATOR_PREV_PAGE;
          this.lastPageLabel = i18n.PAGINATOR_LAST_PAGE;
          this.firstPageLabel = i18n.PAGINATOR_FIRST_PAGE;
        });
    };

    translate.onLangChange.subscribe(() => {
      initLang();
    });

    initLang();
  }
}
