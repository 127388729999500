<ng-container *ngIf="authUser">
  <mat-accordion multi="true">
    <ng-container *ngFor="let menu of menus">
      <mat-expansion-panel [expanded]="isExpanded" routerLinkActive="panel">
        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
          <mat-panel-title>
            <a class="parent router-link">
              <mat-icon class="mat-18">{{ menu.icon }}</mat-icon> &nbsp; {{ menu.key | translate }}
            </a>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul fxLayout="column" fxLayoutGap="16px" class="navigation-items">
          <li *ngFor="let sub of menu.children" (click)="closeSide()">
            <a
              class="router-link"
              routerLinkActive="active"
              routerLink="{{ sub.endpoint }}"
              [routerLinkActiveOptions]="{ exact: pathIgnore.indexOf(sub.endpoint) < 0 }"
            >
              {{ sub.key | translate }}
            </a>
          </li>
        </ul>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</ng-container>
