<ng-container *ngIf="!isAuthorized; else watingTemplate">
  <div class="uinfo" fxLayout="column" fxLayoutAlign="center center">
    <img [src]="certifyData?.avatar || './assets/img/avatar.png'" />
    <span>{{ certifyData?.nickname || 'nickname' }}</span>
  </div>
  <mat-tab-group mat-align-tabs="center" animationDuration="0ms" [disableRipple]="true">
    <mat-tab label="{{ 'LOGIN_BIND_EXISTING_ACCOUNT' | translate }}">
      <form class="form" [formGroup]="signinForm" fxLayout="column" fxLayoutAlign="center center">
        <mat-form-field>
          <input
            #uname
            matInput
            formControlName="username"
            [placeholder]="'LOGIN_TEXT_USERNAME' | translate"
            autocomplete="off"
            readonly
            (focus)="uname.removeAttribute('readonly')"
            required
          />
          <mat-error *ngIf="signinForm.get('username')?.hasError('required')">
            {{ 'COMMON_TEXT_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            #upwd
            matInput
            formControlName="password"
            [type]="hide ? 'password' : 'text'"
            [placeholder]="'LOGIN_TEXT_PASSWORD' | translate"
            autocomplete="off"
            readonly
            (focus)="upwd.removeAttribute('readonly')"
            required
          />
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          <mat-error *ngIf="signinForm.get('password')?.hasError('required')">
            {{ 'COMMON_TEXT_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <button class="full-width" mat-raised-button color="primary" (click)="signinBinding()">
          {{ 'LOGIN_AND_BIND_WITH' | translate: { type: identityTypeText } }}
        </button>
      </form>
    </mat-tab>
    <mat-tab label="{{ 'LOGIN_BIND_NEW_ACCOUNT' | translate }}">
      <form class="form" [formGroup]="signupForm" fxLayout="column" fxLayoutAlign="center center">
        <mat-form-field hintLabel="{{ 'USER_HINT_USERNAME' | translate: { limit: unameMin + '~' + unameMax } }}">
          <input
            #userName
            matInput
            formControlName="username"
            autocomplete="off"
            required
            [placeholder]="'LOGIN_TEXT_USERNAME' | translate"
          />
          <mat-hint align="end">{{ userName.value?.length || 0 }}/{{ unameMin }}~{{ unameMax }}</mat-hint>
          <mat-error *ngIf="getControl('username').invalid">
            {{ getUsernameErrorMessage() | translate: { limit: unameMin + '~' + unameMax } }}
          </mat-error>
        </mat-form-field>

        <mat-form-field hintLabel="{{ 'USER_HINT_PASSWORD' | translate: { limit: pwdMin + '~' + pwdMax } }}">
          <input
            #psd
            matInput
            formControlName="password"
            type="password"
            autocomplete="new-password"
            [placeholder]="'LOGIN_TEXT_PASSWORD' | translate"
            required
          />
          <mat-hint align="end">{{ psd.value?.length || 0 }}/{{ pwdMin }}~{{ pwdMax }}</mat-hint>
          <mat-error *ngIf="getControl('password').invalid">
            {{ getPasswordErrorMessage() | translate: { limit: pwdMin + '~' + pwdMax } }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            formControlName="confirmPassword"
            type="password"
            autocomplete="new-password"
            [placeholder]="'LOGIN_TEXT_CONFIRM_PASSWORD' | translate"
            required
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="signupForm.get('confirmPassword')?.hasError('required') || signupForm.hasError('notSame')">
            {{ 'SIGN_UP_PASSWORD_NOT_MATCH' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            formControlName="country"
            (click)="openCountryDialog($event)"
            readonly
            required
            placeholder="{{ 'COMPANY_TEXT_COUNTRY' | translate }}"
          />
          <mat-error *ngIf="getControl('countryCode').invalid">{{ 'COMMON_TEXT_FIELD_REQUIRED' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="selectedCountry && selectedCountry.acronym === 'CN'">
          <input matInput formControlName="phone" autocomplete="off" required placeholder="{{ 'USER_TEXT_PHONE' | translate }}" />
          <mat-error *ngIf="getControl('phone')?.hasError('required')">{{ 'COMMON_TEXT_FIELD_REQUIRED' | translate }}</mat-error>
          <mat-error *ngIf="getControl('phone')?.hasError('customMobile')">{{ 'INVALID_TEXT_MOBILE' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="selectedCountry && selectedCountry.acronym !== 'CN'">
          <input matInput formControlName="email" autocomplete="off" required placeholder="{{ 'USER_TEXT_EMAIL' | translate }}" />
          <mat-error *ngIf="getControl('email')?.hasError('required')">{{ 'COMMON_TEXT_FIELD_REQUIRED' | translate }}</mat-error>
          <mat-error *ngIf="getControl('email')?.hasError('email')">{{ 'INVALID_TEXT_EMAIL' | translate }}</mat-error>
        </mat-form-field>

        <div *ngIf="selectedCountry" class="full-width" fxLayout fxLayoutAlign="space-between baseline">
          <mat-form-field fxFlex="60%">
            <input matInput formControlName="code" required placeholder="{{ 'COMMON_TEXT_VERIFICATION_CODE' | translate }}" />
            <mat-error *ngIf="getControl('code')?.hasError('required')">{{ 'COMMON_TEXT_FIELD_REQUIRED' | translate }}</mat-error>
            <mat-error *ngIf="getControl('code')?.hasError('pattern')">
              {{ 'TWO_FACTORY_VERIFICATION_CODE' | translate }}
            </mat-error>
          </mat-form-field>
          <div fxFlex="37px">
            <img *ngIf="isLoading" style="vertical-align: middle;" src="./assets/img/spinner_37_1s.gif" />
          </div>
          <button
            mat-stroked-button
            (click)="getVerifyCode($event)"
            [disabled]="getControl('phone')?.invalid || getControl('email')?.invalid || isLoading || counter > 0"
          >
            {{ 'REGISTER_GET_VALIDATE_CODE' | translate }} <span *ngIf="counter > 0">({{ counter }})</span>
          </button>
        </div>

        <div class="policy">
          <mat-checkbox formControlName="policy" [disableRipple]="true"></mat-checkbox>&nbsp;
          {{ provision[0] }}
          <a (click)="showPolicy($event, protocolType.Term)">{{ provision[1] }}</a>
          {{ provision[2] }}
          <a (click)="showPolicy($event, protocolType.Policy)">{{ provision[3] }}</a>
        </div>

        <button
          class="full-width"
          mat-raised-button
          [disabled]="!signupForm.value.policy"
          color="primary"
          (click)="signupBinding()"
        >
          {{ 'REGISTER_AND_BIND_WITH' | translate: { type: identityTypeText } }}
        </button>
      </form>
    </mat-tab>
  </mat-tab-group>
  <div class="quick-login" fxLayout="column" fxLayoutAlign="center center">
    <a fxFlex="50" (click)="signupBinding('auto')">
      {{ 'LOGIN_SIGNUP_BINDING_AUTO' | translate }}
    </a>
  </div>
</ng-container>

<ng-template #watingTemplate>
  <div class="welcome">
    <h1>{{ 'WELCOME_TEXT' | translate }}</h1>
  </div>
</ng-template>
