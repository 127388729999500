import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';

import {
  AuditContentType,
  DesignerAuditStatus,
  EsGalleryContentType,
  ScreenOrientation,
  DeviceType,
  ScreenSpec,
  MediaAudit,
  TemplateType,
  GalleryType,
} from '@core/enums/propertymap.enum';

import { Option } from '../models/option';

@Injectable({
  providedIn: 'root',
})
export class PropertymapService {
  constructor(private storage: StorageService, private utils: UtilsService, private translate: TranslateService) {}

  public getSupportTypeOptions(): Array<Option> {
    return [
      { value: 1, text: this.utils.lang('SUPPORT_TYPE_ACCOUNT') },
      { value: 2, text: this.utils.lang('SUPPORT_TYPE_TECHNICAL') },
      { value: 3, text: this.utils.lang('SUPPORT_TYPE_OTHER') },
    ];
  }

  public getSupportStatusOptions(): Array<Option> {
    return [
      { value: 1,key:'admin,user', text: this.utils.lang('SUPPORT_STATUS_PENDING') },
      { value: 2,key:'admin,user', text: this.utils.lang('SUPPORT_STATUS_PLATFORM_REPLY') },
      { value: 3,key:'admin', text: this.utils.lang('SUPPORT_STATUS_USER_REPLY') },
      { value: 4,key:'admin,user', text: this.utils.lang('SUPPORT_STATUS_SOLVED') },
    ];
  }

  public getTemplateTypeOptions(): Array<Option> {
    return [
      { value: TemplateType.Video, text: 'TEMPLATES_TYPE_STANDARD_VIDEO' },
      { value: TemplateType.Customize, text: 'TEMPLATES_TYPE_CUSTOM_VIDEO' },
      { value: TemplateType.Diy, text: 'TEMPLATES_TYPE_DIY' },
    ];
  }

  public getUserAuditStatusOptions(): Array<Option> {
    return [
      { value: MediaAudit.Pending, text: 'TEMPLATES_SEARCH_AUDIT_PENDING' },
      { value: MediaAudit.FirstFailed, text: 'TEMPLATES_SEARCH_AUDIT_FAILURE' },
      { value: MediaAudit.FirstPassed, text: 'TEMPLATES_SEARCH_AUDIT_SUCCESSFUL' },
      { value: MediaAudit.FinalFailed, text: 'TEMPLATES_SEARCH_FINAL_AUDIT_FAILURE' },
      { value: MediaAudit.FinalPassed, text: 'TEMPLATES_SEARCH_FINAL_AUDIT_SUCCESSFUL' },
    ];
  }

  public getGalleryTypeOptions(): Array<Option> {
    return [
      { value: GalleryType.Image, text: 'DESIGNER_GALLERIES_TYPE_ANIMATED_IMAGE' },
      { value: GalleryType.Text, text: 'DESIGNER_GALLERIES_TYPE_ANIMATED_TEXT' },
      { value: GalleryType.Font, text: 'DESIGNER_GALLERIES_TYPE_FONT' },
    ];
  }

  public getAuditContentTypeOptions(): Array<Option> {
    return [
      { value: AuditContentType.Template, text: 'DESIGNER_REVIEW_TYPE_TEMPLATES' },
      { value: AuditContentType.Gallery, text: 'DESIGNER_REVIEW_TYPE_MATERIAL' },
    ];
  }

  public getDeviceTypeOptions(): Array<Option> {
    return [
      {
        value: DeviceType.Poster,
        text: 'DEVICE_TYPE_OPTION_POSTER',
        icon: '/assets/img/device_poster_sm.png',
        width: 24,
      },
      { value: DeviceType.Esign, text: 'DEVICE_TYPE_OPTION_ESIGN', icon: '/assets/img/device_esign_sm.png', width: 24 },
    ];
  }

  public getDesignerAuditStatusOptions(): Array<Option> {
    return [
      { value: DesignerAuditStatus.Init, text: 'MSG_AUDIT_INIT' },
      { value: DesignerAuditStatus.Pending, text: 'MSG_AUDIT_PENDING_DESIGNER' },
      { value: DesignerAuditStatus.Pass, text: 'MSG_AUDIT_SUCCESS' },
      { value: DesignerAuditStatus.Failed, text: 'MSG_AUDIT_FAIL' },
    ];
  }

  public getScreenOrientationOptions(): Array<Option> {
    return [
      { value: ScreenOrientation.Landscape, text: 'ESIGN_SCREEN_ORIENTATION_LAND' },
      { value: ScreenOrientation.Portrait, text: 'ESIGN_SCREEN_ORIENTATION_PORTRAIT' },
    ];
  }

  public getYesOrNoOptions(): Array<Option> {
    return [
      { value: true, text: 'COMMON_TEXT_YES' },
      { value: false, text: 'COMMON_TEXT_NO' },
    ];
  }

  public getEsContentTypeOptions(): Array<Option> {
    return [
      { value: EsGalleryContentType.Background, text: 'ESIGN_CONTENT_TYPE_BACKGROUND' },
      { value: EsGalleryContentType.Animated, text: 'ESIGN_CONTENT_TYPE_ANIMATTION' },
    ];
  }

  public getUserScreenOrientationOptions(): Array<Option> {
    const loginUser = this.storage.user;
    const options = [];
    if (loginUser.landscape.length) {
      options.push({ value: ScreenOrientation.Landscape, text: 'ESIGN_SCREEN_ORIENTATION_LAND' });
    }
    if (loginUser.portrait.length) {
      options.push({ value: ScreenOrientation.Portrait, text: 'ESIGN_SCREEN_ORIENTATION_PORTRAIT' });
    }
    return options;
  }

  public getUserScreenCountOptions(value: ScreenOrientation): Array<Option> {
    const loginUser = this.storage.user;
    const options = [];
    if (value === ScreenOrientation.Landscape) {
      loginUser.landscape.forEach((item) => {
        options.push({
          icon: '/assets/img/es_landscape.png',
          value: item,
          text: `${item} (${this.translate.instant('COMMON_TEXT_PIXEL')}:${ScreenSpec.Width * item} × ${
            ScreenSpec.Height
          })`,
        });
      });
    }
    if (value === ScreenOrientation.Portrait) {
      loginUser.portrait.forEach((item) => {
        options.push({
          icon: '/assets/img/es_portrait.png',
          value: item,
          text: `${item} (${this.translate.instant('COMMON_TEXT_PIXEL')}:${ScreenSpec.Height} × ${
            ScreenSpec.Width * item
          })`,
        });
      });
    }
    return options;
  }

  public getUserEsScreenSize(value: ScreenOrientation): Array<{ width: number; height: number; count: number }> {
    const loginUser = this.storage.user;
    const sizes = [];
    if (value === ScreenOrientation.Landscape) {
      loginUser.landscape.forEach((item) => {
        sizes.push({
          width: ScreenSpec.Width * item,
          height: ScreenSpec.Height,
          count: item,
        });
      });
    }
    if (value === ScreenOrientation.Portrait) {
      loginUser.portrait.forEach((item) => {
        sizes.push({
          width: ScreenSpec.Height,
          height: ScreenSpec.Width * item,
          count: item,
        });
      });
    }
    return sizes;
  }

  public autoAdaptEsignScreen(width: number, height: number) {
    const LANDSCAPE = ScreenOrientation.Landscape;
    const PORTRAIT = ScreenOrientation.Portrait;
    const loginUser = this.storage.user;

    const userHasLandscape = loginUser.landscape.length > 0;
    const userHasPortrait = loginUser.portrait.length > 0;

    // 用户的屏幕类型，默认给横屏
    let orientation = LANDSCAPE;
    // 屏幕数量默认给第一个，优先级为横屏 -> 竖屏 —> 1（假设还没添加设备）
    let count = userHasLandscape ? loginUser.landscape[0] : 1;

    if (userHasPortrait) {
      orientation = PORTRAIT;
      count = loginUser.portrait[0];
    }

    if (!width || !height) {
      return { orientation, count };
    }

    // 内容适配的屏幕类型，用户可能没有这种屏幕
    // 视频文件宽小于高给竖屏，大于等于高给横屏
    let applyOrientation = width < height ? PORTRAIT : LANDSCAPE;
    const isLandscape = applyOrientation === LANDSCAPE;
    let notApplicable = false;

    // 根据视频适配的屏幕类型得到用户的屏幕尺寸
    const userEsScreenSizes = this.getUserEsScreenSize(applyOrientation);
    console.log('userEsScreenSizes', userEsScreenSizes);
    // 如果当前屏幕类型有2块以上的屏幕数量
    // 若是横屏，将视频宽度和用户的屏幕宽度做排序，找到最接近的屏幕数量
    // 竖屏反之
    if (userEsScreenSizes.length > 1) {
      const getKey = (obj?: any) => {
        if (obj) {
          return isLandscape ? obj.width : obj.height;
        }
        return isLandscape ? width : height;
      };
      const sizes = userEsScreenSizes.map((m) => getKey(m));
      const neighboringItem = this.utils.lookup(sizes, getKey());
      count = userEsScreenSizes.find((f) => getKey(f) === neighboringItem).count;
    } else if (userEsScreenSizes.length === 1) {
      count = userEsScreenSizes[0].count;
    } else {
      // 如果内容适配到了屏幕类型，但没有屏幕数量，说明用户没有这种类型的屏幕
      // 因此要返回用户实际拥有的屏幕，并将该条适配标记为不适用用来提醒用户
      applyOrientation = orientation;
      notApplicable = true;
    }
    return { orientation: applyOrientation, count, notApplicable };
  }

  public getUserEsDefault(): { orientation: ScreenOrientation; count: number } {
    const LANDSCAPE = ScreenOrientation.Landscape;
    const PORTRAIT = ScreenOrientation.Portrait;

    const landscape = this.storage.user.landscape;
    const portrait = this.storage.user.portrait;
    const landscapeCount = landscape.length;
    const portraitCount = portrait.length;

    // // 如果两种类型的设备都没有，默认横屏 X 1
    // if (!landscapeCount && !portraitCount) {
    //   return { orientation: LANDSCAPE, count: 1 };
    // }

    // 如果只有横屏，或者两种类型都有，默认横屏第一个
    if ((landscapeCount && !portraitCount) || (landscapeCount && portraitCount)) {
      return { orientation: LANDSCAPE, count: landscape[0] };
    }

    // 如果只有竖屏，默认竖屏第一个
    if (!landscapeCount && portraitCount) {
      return { orientation: PORTRAIT, count: portrait[0] };
    }

    return { orientation: LANDSCAPE, count: 1 };
  }
}
