import { filter } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

export interface Breadcrumb {
  icon?: string;
  text?: string;
  url?: any;
  visible?: boolean;
  disabled?: boolean;
  items?: Breadcrumb[];
}

@Component({
  selector: 'nex-breadcrumb',
  template: `
    <div class="breadcrumb">
      <ul fxLayout="row" fxLayoutAlign="start center">
        <li *ngIf="home">
          <a
            class="item-link"
            [routerLink]="home.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="onItemClick($event, home)"
          >
            <mat-icon class="mat-18">{{ home.icon || 'home' }}</mat-icon>
            <span class="item-text">{{ home.text | translate }}</span>
          </a>
        </li>
        <li *ngIf="home && items?.length" class="chevron-right">
          <mat-icon>chevron_right</mat-icon>
        </li>
        <ng-container *ngFor="let item of items; last as isLast">
          <li>
            <a
              class="item-link"
              [routerLink]="item.url"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="onItemClick($event, item)"
            >
              <mat-icon *ngIf="item.icon" class="mat-18">{{ item.icon }}</mat-icon>
              <span class="item-text">{{ item.text | translate }}</span>
            </a>
          </li>
          <li *ngIf="!isLast" class="chevron-right">
            <mat-icon>chevron_right</mat-icon>
          </li>
        </ng-container>
      </ul>
    </div>
  `,
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BreadcrumbComponent {
  static readonly ROUTE_DATA_I18NTEXT = 'i18nText';

  @Input() home: Breadcrumb = { text: 'APP_HOME', icon: 'home', url: '/' };
  @Input() items: Breadcrumb[];
  @Output() itemClick: EventEmitter<any> = new EventEmitter();

  constructor(router: Router, activatedRoute: ActivatedRoute) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => (this.items = this.createBreadcrumbs(activatedRoute.root)));
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const text = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_I18NTEXT];
      if (!(text === undefined || text === null)) {
        breadcrumbs.push({ text, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  onItemClick(event, item: Breadcrumb) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    this.itemClick.emit({ item, source: event });
  }
}
