import { Directive, HostListener, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[nexCheckImage]',
})
export class CheckImageDirective implements OnDestroy {
  @Input() loader = '/assets/img/spinner_200_1s.gif';
  @Input() onErrorSrc = '/assets/img/err_image.svg';

  private src: string;
  private timer: number;
  private tick = 1;

  constructor(private el: ElementRef<HTMLImageElement>) {}

  @Input()
  set checkSrc(value: string) {
    this.clearTimer();
    this.src = value;
    this.el.nativeElement.src = value;
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  @HostListener('error')
  onError() {
    this.el.nativeElement.src = this.loader;
    this.check({
      success: () => (this.el.nativeElement.src = this.src),
      error: () => (this.el.nativeElement.src = this.onErrorSrc),
    });
  }

  check(option) {
    this.clearTimer();

    const self = this;

    if (typeof option.ticks === 'undefined' || typeof option.ticks !== 'number') {
      option.ticks = 10;
    }

    if (self.tick > option.ticks) {
      return typeof option.error === 'function' && option.error();
    }

    if (typeof option.delay === 'undefined') {
      option.delay = 3000;
    }

    const image = new Image();
    image.onload = () => typeof option.success === 'function' && option.success(image);
    image.onerror = () => {
      self.tick++;
      this.timer = window.setTimeout(() => self.check(option), option.delay);
    };
    image.src = self.src;
  }

  clearTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      delete this.timer;
    }
  }
}
