import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, KEEP_LOGIN, StorageService, UtilsService } from '@core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nex-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, OnDestroy {
  private sub$: Subscription;

  constructor(
    private router: Router,
    private utils: UtilsService,
    private auth: AuthService,
    private storage: StorageService,
    private translate: TranslateService,
  ) {
    utils.initLanguages();
    // utils.checkIsChinaNetwork();
    utils.regSvgIcons([
      { svgName: 'brand_facebook', alias: 'facebook' },
      { svgName: 'brand_weixin', alias: 'weixin' },
      { svgName: 'filter_alt' },
      { svgName: 'filter_primary' },
      { svgName: 'double_arrow_left' },
      { svgName: 'double_arrow_right' },
      { svgName: 'loop_outline' },
      { svgName: 'magic_outline' },
      { svgName: 'once2_outline' },
      { svgName: 'once_outline' },
      { svgName: 'replace_outline' },
      { svgName: 'scrolling_text' },
      { svgName: 'gold_outline' },
    ]);
    auth.initAuthListener();
  }

  ngOnInit() {
    this.sub$ = this.auth.authChange.subscribe((user) => {
      if (!user) {
        return;
      }
      const isKeepLogin = !this.storage.get(KEEP_LOGIN);
      const condition1 = user.passwordExpireDate && this.utils.getDateDiff(user.passwordExpireDate) < 7;
      const condition2 = !user.defaultPwd && !user.passwordExpire && !user.tokenAccountVerify && user.deviceType;
      if (isKeepLogin && condition1 && condition2) {
        const _date = new Date(user.passwordExpireDate).toLocaleString(this.utils.getSelectedLang().code, {
          hour12: false,
        });
        const _msg = this.translate.instant('CONFIRM_PASSWORD_NEED_CHANGE', { date: _date });
        this.utils
          .showConfirm(_msg, 'COMMON_TEXT_TITLE_INFO', 'PASSWORD_CHANGE_LATER', 'PASSWORD_CHANGE_NOW')
          .subscribe((ok) => {
            if (ok) {
              this.router.navigate(['/startup/change-password']);
            }
          });
      }
    });
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }
}
