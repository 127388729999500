import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { LayoutDefaultComponent } from './default/default.component';
import { LayoutHeaderComponent } from './default/header/header.component';
import { LayoutPanelComponent } from './default/panel/panel.component';
import { LayoutPassportComponent } from './passport/passport.component';
import { LayoutStartupComponent } from './startup/startup.component';
import { LayoutBlankComponent } from './blank/blank.component';

import { HeaderI18nComponent } from './default/header/components/i18n.component';
import { HeaderModeComponent } from './default/header/components/mode.component';
import { SidenavComponent } from './default/sidenav/sidenav.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    LayoutDefaultComponent,
    LayoutHeaderComponent,
    LayoutPanelComponent,
    LayoutPassportComponent,
    LayoutStartupComponent,
    LayoutBlankComponent,
    HeaderI18nComponent,
    HeaderModeComponent,
    SidenavComponent,
  ],
  exports: [
    LayoutDefaultComponent,
    LayoutHeaderComponent,
    LayoutPanelComponent,
    LayoutPassportComponent,
    LayoutStartupComponent,
    LayoutBlankComponent,
  ],
})
export class LayoutModule {}
