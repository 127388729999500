import { Component, OnInit, Inject } from '@angular/core';
import { RestService, ServerResponse, ResponseCode, UtilsService } from '@core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Country {
  acronym: string;
  country: string;
  code: string;
  method: string;
  nativeName: string;
  continents?: string;
  chineseName?: string;
}

export interface Continent {
  chineseName: string;
  continents: string;
  countryList: Array<Country>;
  tag: string;
  _id: string;
}

@Component({
  selector: 'nex-country-dialog',
  templateUrl: './country-dialog.component.html',
  styleUrls: ['./country-dialog.component.scss'],
})
export class CountryDialogComponent implements OnInit {
  isLoading = true;
  lang: string;
  selectedContinent: string;
  selectedCountry: Country;
  countries: Array<Continent> = [];

  constructor(
    private rest: RestService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CountryDialogComponent>,
    private utils: UtilsService,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.selectedCountry = this.data;
      this.selectedContinent = this.data.continents || '';
    }
    this.lang = this.utils.getSelectedLang().code;
    this.rest.getJson('v2/getsupportcountry', null, false).subscribe((res: ServerResponse) => {
      if (res.code === ResponseCode.Success) {
        setTimeout(() => {
          this.countries = res.data;
          this.isLoading = false;
        }, 200);
      }
    });
  }

  selectCountry(ev: MouseEvent): void {
    ev.preventDefault();
    this.dialogRef.close(Object.assign(this.selectedCountry, { continents: this.selectedContinent }));
  }
}
