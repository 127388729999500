<h1 mat-dialog-title>{{ 'TEMPLATES_DIALOG_AUDIT_TITLE' | translate }}</h1>
<form [formGroup]="auditForm" (ngSubmit)="onAuditSubmit()">
  <mat-dialog-content>
    <div class="tip">{{ 'TEMPLATES_TEXT_ATTENTION' | translate }}</div>
    <mat-divider></mat-divider>
    <div
      class="wrap"
      [fxLayout]="isEsign ? 'column' : 'row wrap'"
      [fxLayoutAlign]="isEsign ? 'start stretch' : 'start start'"
      fxLayoutGap="16px"
    >
      <nex-previewer
        [fxFlex]="isEsign ? '' : '240px'"
        [contentType]="ContentType[isEsign ? 'EsMedia' : 'PoMedia']"
        [contentData]="itemData"
        [scale]="0.6"
      ></nex-previewer>
      <div fxFlex class="details">
        <ng-container *ngIf="itemData; else detailTpl">
          <p>
            <label>{{ 'TEMPLATES_TEXT_TYPE' | translate }}</label>
            <span>{{ itemData | templateTypeText | async }}</span>
          </p>
          <p>
            <label>{{ 'TEMPLATES_TEXT_NAME' | translate }}</label>
            <span title="{{ itemData.name }}">{{ itemData.name | truncate: [20, '...'] }}</span>
          </p>
          <p>
            <label>{{ 'TEMPLATES_TEXT_DURATION' | translate }}</label>
            <span>{{ itemData.duration }} s</span>
          </p>
          <p>
            <label>{{ 'TEMPLATES_TEXT_SIZE' | translate }}</label>
            <span>{{ itemData.filesize | filesize }}</span>
          </p>
          <p *ngIf="auditType === PreviewType.FinalAudit">
            <label>{{ 'TEMPLATES_TEXT_COMPANY' | translate }}</label>
            <span>{{ itemData.companyData?.shortName || '' }}</span>
          </p>
          <p>
            <label>{{ 'COMMON_TABLE_TITLE_CREATED_BY' | translate }}</label>
            <span>{{ itemData.createdBy?.displayName || '' }}</span>
          </p>
          <p>
            <label>{{ 'COMMON_TABLE_TITLE_CREATED_AT' | translate }}</label>
            <span>{{ itemData.createdAt | date: 'short' }}</span>
          </p>
          <p>
            <label>{{ 'COMMON_TABLE_TITLE_UPDATED_AT' | translate }}</label>
            <span>{{ itemData.updatedAt | date: 'short' }}</span>
          </p>
          <p><mat-divider></mat-divider></p>
          <p>
            <label id="audit-group-label">{{ 'TEMPLATES_TEXT_CHOOSE_AUDIT' | translate }}</label>
          </p>
          <mat-radio-group aria-labelledby="audit-group-label" class="audit-radio-group" formControlName="audit">
            <mat-radio-button
              color="primary"
              *ngFor="let item of auditRadioList"
              class="audit-radio-button"
              [value]="item.value"
            >
              {{ item.text | translate }}
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field class="full-width" hintLabel="{{ 'COMMON_TEXT_PARAGRAPH_LIMIT' | translate }}">
            <textarea
              #textarea
              class="audit-reason"
              matInput
              formControlName="reason"
              placeholder="{{ 'TEMPLATES_TEXT_AUDIT_REJECTION_REASON' | translate }}"
            >
            </textarea>
            <mat-hint align="end">{{ textarea.value?.length || 0 }}/4~100</mat-hint>
            <mat-error *ngIf="auditForm.get('reason')?.invalid">{{ getErrorMessage() | translate }} </mat-error>
          </mat-form-field>
        </ng-container>

        <ng-template #detailTpl>
          <nex-empty-data></nex-empty-data>
        </ng-template>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>{{ 'BUTTON_CANCEL' | translate }}</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="timer > 0 || auditForm.invalid">
      {{ 'BUTTON_OK' | translate }}
      {{ timer > 0 ? '(' + timer + ')' : '' }}
    </button>
  </mat-dialog-actions>
</form>
