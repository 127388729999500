import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FilesizePipe implements PipeTransform {

  public transform(bytes: number | string): string {
    if (typeof bytes === 'string') {
      bytes = Number.parseInt(bytes, 10);
    }
    if (Number.isNaN(Number.parseInt(`${bytes}`, 10)) || !Number.isFinite(bytes)) { return '-'; }
    if (bytes <= 0) {
      return '0';
    }
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const num = Math.floor(Math.log(bytes) / Math.log(1024));
    const unit = units[num];
    const result = bytes / Math.pow(1024, Math.floor(num));
    if (unit === 'KB') {
      return Number.parseInt(`${result}`, 10) + ' ' + unit;
    }
    return result.toFixed(2) + ' ' + unit;
  }

}
