import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(state.url);
  }

  checkLogin(url: string): Observable<boolean> {
    // console.log('state.url', url);
    return new Observable((observer) => {
      const ignoreUrls = ['/startup/verify', '/startup/mode', '/startup/change-password'];
      if (ignoreUrls.includes(url)) {
        url = '/';
      }
      this.auth.setUrl(url);
      if (this.auth.isAuthorized()) {
        observer.next(true);
        observer.complete();
        return;
      }
      if (!this.auth.authWait) {
        this.router.navigate(['/passport/login']);
      }
      observer.next(false);
      observer.complete();
    });
  }
}
