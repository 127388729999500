import { timer } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AlertService,
  AuthService,
  DeviceType,
  DiyMode,
  Option,
  RestService,
  ServerResponsePagination,
  TemplateType,
  UtilsService,
} from '@core';

@Component({
  selector: 'nex-user-material',
  templateUrl: './user-material.component.html',
  styleUrls: ['./user-material.component.scss'],
})
export class UserMaterialComponent implements OnInit {
  pageNum = 1;
  pageSize = 25;
  totalSize = 0;
  picNumMax = 20;
  isLoading: boolean;
  i18nTitle: string;
  i18nSubtitle: string;

  isAppUser: boolean;
  isShowToggleGroup = false;
  toggleType: string;
  toggleGroupOptions: Option[] = [];

  selectedItems: any[] = [];
  userTemplates: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DiyMode,
    auth: AuthService,
    public rest: RestService,
    private alert: AlertService,
    private utils: UtilsService,
    public dialogRef: MatDialogRef<UserMaterialComponent>,
  ) {
    const authUser = auth.getUser();
    this.isAppUser = authUser.appuser;
    if (data === DiyMode.PsSlide) {
      this.i18nTitle = 'DIALOG_SLIDE_DIY_TITLE';
      this.i18nSubtitle = 'DIALOG_SLIDE_DIY_SUBTITLE';
      this.toggleGroupOptions = [
        { value: 'me', text: this.utils.lang('STUDIO_PRIVATE') },
        { value: 'company', text: this.utils.lang('STUDIO_COMPANY'), disabled: this.isAppUser },
      ];
      this.toggleType = this.toggleGroupOptions[0].value;
      this.isShowToggleGroup = this.toggleGroupOptions.filter((f) => !f.disabled).length > 1;
    } else if (data === DiyMode.PsVideo) {
      this.i18nTitle = 'DIALOG_VIDEO_DIY_TITLE';
      this.i18nSubtitle = 'DIALOG_VIDEO_DIY_SUBTITLE';
      this.isShowToggleGroup = false;
    } else {
      this.i18nTitle = 'DIALOG_MATERIAL_DIY_TITLE';
      this.isShowToggleGroup = false;
    }
  }

  ngOnInit() {
    this.fetchMaterials();
  }

  loadMore() {
    const pages = Math.ceil(this.totalSize / this.pageSize);
    if (this.pageNum < pages) {
      this.pageNum++;
      this.fetchMaterials();
    }
  }

  clearData() {
    this.pageNum = 1;
    this.totalSize = 0;
    this.selectedItems = [];
    this.userTemplates = [];
  }

  onToggle() {
    this.clearData();
    this.fetchMaterials();
  }

  getPreviewSource(item: any) {
    const previewMap = new Map([
      [DiyMode.PsVideo, 'videoPreviewJPG'],
      [DiyMode.PsSlide, 'userImagesPreview'],
    ]);
    const mapPreview = previewMap.get(this.data);
    if (mapPreview) {
      return this.rest[mapPreview](item.filename);
    }
    return './assets/img/err_image.png';
  }

  onItemClick(item: any) {
    if (this.data === DiyMode.PsVideo) {
      this.userTemplates.forEach((m) => {
        m.selected = m._id === item._id;
      });
      this.selectedItems = [item];
    } else if (this.data === DiyMode.PsSlide) {
      if (!item.selected && this.selectedItems.length >= this.picNumMax) {
        this.alert.alertInfo(this.utils.lang('DIY_SLIDE_LIMIT_PICTURE', { num: this.picNumMax }));
        return;
      }
      item.selected = !item.selected;
      item.selected ? this.selectedItems.push(item) : this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    }
    console.log('selectedItems', this.selectedItems);
  }

  fetchMaterials() {
    const queryParams = {
      pageSize: this.pageSize,
      pageNum: this.pageNum,
    };
    const dataMap = new Map([
      [
        DiyMode.PsVideo,
        { url: 'v3/user/templates/audited', params: { deviceType: DeviceType.Poster, type: TemplateType.Video } },
      ],
      [DiyMode.PsSlide, { url: 'v3/user/gallery/' + this.toggleType, params: { type: 'picture' } }],
    ]);
    const mapData = dataMap.get(this.data);
    if (!mapData.url) {
      return;
    }
    Object.assign(queryParams, mapData.params);
    this.getUserMaterials(mapData.url, queryParams).subscribe(
      (res: ServerResponsePagination) => {
        if (!this.totalSize) {
          this.totalSize = res.data.totalSize;
        }
        this.userTemplates = this.userTemplates.concat(res.data.rows);
      },
      () => (this.isLoading = false),
    );
  }

  onDone() {
    this.dialogRef.close(this.selectedItems);
  }

  onClose(event: MouseEvent) {
    event.stopPropagation();
    this.dialogRef.close();
  }

  private getUserMaterials(url, params) {
    this.isLoading = true;
    return timer(300).pipe(
      switchMap(() => this.rest.getJson(url, params).pipe(finalize(() => (this.isLoading = false)))),
    );
  }
}
