import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { PosterConfig } from '@global';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '@shared/components/confirm/confirm.component';

import {
  ContentType,
  DeviceType,
  EsGalleryContentType,
  ScreenOrientation,
  ScreenSpec,
} from '../enums/propertymap.enum';
import { Gallery } from '../models/gallery';
import { Language } from '../models/language';
import { Template } from '../models/template';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@nexnovo/nex-editor';
import { RestService } from './rest.service';

const moment = _rollupMoment || _moment;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  spinnerChanged = new Subject<boolean>();
  timeoutChanged = new Subject<number>();
  networkChanged = new ReplaySubject<boolean>();

  private _isInChina = true;

  private languages: Language[] = [
    { title: '简体中文', code: 'zh', acronym: 'CN' },
    { title: 'English', code: 'en', acronym: 'US' },
    { title: 'Français', code: 'fr', acronym: 'FR' },
    // { title: 'Español', code: 'es', acronym: 'ES' },
    // { title: '日本語', code: 'ja', acronym: 'JP' },
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private activateRoute: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private loaderServ: LoaderService,
    private rest: RestService,
  ) {}

  get isChinaNetwork() {
    return this._isInChina;
  }

  checkIsChinaNetwork() {
    // source: https://juejin.im/post/58edfdb544d904005776688d
    const url = '//graph.facebook.com/feed?callback=h';
    const xhr = new XMLHttpRequest();
    let called = false;
    xhr.open('GET', url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        called = true;
        this._isInChina = false;
        this.networkChanged.next(this._isInChina);
      }
    };
    xhr.send();
    setTimeout(() => {
      if (!called) {
        xhr.abort();
        this._isInChina = true;
        this.networkChanged.next(this._isInChina);
      }
    }, 1000);
  }

  initLanguages() {
    const userLang = (navigator.languages ? navigator.languages[0] : navigator.language) || 'en-US';
    let defaultLang = userLang.substr(0, 2);
    if (!this.languages.find((f) => f.code === defaultLang)) {
      defaultLang = 'en';
    }

    this.translate.setDefaultLang(defaultLang);
    const code = localStorage.getItem('xlanguage');
    if (code) {
      this.translate.use(code);
      this.dateAdapter.setLocale(code);
    }
  }

  getLanguages() {
    return this.languages.slice();
  }

  getSelectedLang() {
    const code = localStorage.getItem('xlanguage') || this.translate.getDefaultLang();
    return this.languages.find((f) => f.code === code);
  }

  setLanguage(code: string) {
    this.translate.use(code);
    this.dateAdapter.setLocale(code);
    localStorage.setItem('xlanguage', code);
  }

  lang(key: string | Array<string>, params?: any): string | any {
    if (typeof key === 'string') {
      const _lang = this.translate.instant([key], params);
      return _lang[key];
    }
    return this.translate.instant(key, params);
  }

  showSnackBar(message?: string, btn?: string, duration?: number) {
    message = message || 'SNACK_BAR_MSG_SUCCESS'; // Success
    btn = btn || 'SNACK_BAR_BUTTON_CLOSE'; // Ok
    duration = duration || 2000;
    this.translate.get([message, btn]).subscribe((i18n) => {
      const snack = this.snackBar.open(i18n[message], i18n[btn], { duration });
    });
  }

  showConfirm(
    body: string,
    title = 'COMMON_TEXT_TITLE_INFO',
    btnCancel = 'BUTTON_CANCEL',
    btnOk = 'BUTTON_OK',
  ): Observable<any> {
    return this.dialog
      .open(ConfirmComponent, {
        width: '400px',
        disableClose: true,
        data: { title, body, btnCancel, btnOk },
      })
      .afterClosed();
  }

  showSpinner(status: boolean, timeout?: number) {
    this.spinnerChanged.next(status);
    if (timeout) {
      window.setTimeout(() => {
        this.spinnerChanged.next(!status);
      }, timeout);
    }
  }

  getTimeZoneList() {
    const timeArry = [];
    for (let j = 11; j >= 1; j--) {
      timeArry.push(`GMT-${j < 10 ? '0' + j : j}:00`);
    }
    for (let i = 0; i <= 12; i++) {
      timeArry.push(`GMT+${i < 10 ? '0' + i : i}:00`);
    }
    return timeArry;
  }

  isJsonString(str: string): boolean {
    try {
      return typeof JSON.parse(str) === 'object';
    } catch (e) {
      return false;
    }
  }

  parseNumberOrBoolean(str: string) {
    if (/^[0-9]+$/.test(str)) {
      return Number.parseInt(str, 10);
    }
    if (/^[0-9]+\.?[0-9]+?$/.test(str)) {
      return Number.parseFloat(str);
    }
    if (['true', 'false'].includes(str)) {
      return str === 'true';
    }
    return str;
  }

  randColor(notContainColors?: any) {
    const colors = [
      '#333333',
      '#683b11',
      '#df4a39',
      '#f69f58',
      '#f9cd5b',
      '#fcf679',
      '#b4eb70',
      '#68ca6f',
      '#7bdfce',
      '#5abef7',
      '#5e81f5',
      '#873aca',
      '#c846ba',
      '#f677b9',
      '#f7bac9',
    ];
    if (typeof notContainColors === 'object') {
      for (const i of notContainColors) {
        for (let j = 0; j < colors.length; j++) {
          if (colors[j] === notContainColors[i]) {
            colors.splice(j, 1);
            j = j - 1;
          }
        }
        if (colors.length === 0) {
          return null;
        }
      }
    }
    const randIndex = Math.floor(Math.random() * colors.length);
    const color = colors[randIndex % colors.length];
    return color;
  }

  getColors() {
    const colors = [
      '#333333',
      '#683b11',
      '#df4a39',
      '#f69f58',
      '#f9cd5b',
      '#fcf679',
      '#b4eb70',
      '#68ca6f',
      '#7bdfce',
      '#5abef7',
      '#5e81f5',
      '#873aca',
      '#c846ba',
      '#f677b9',
      '#f7bac9',
    ];
    return colors;
  }

  generateTimes() {
    const times = [];
    for (let i = 0; i <= 24; i++) {
      times.push((i < 10 ? '0' + i : i) + ':00');
    }
    return times;
  }

  checkRemoteImage(source: string, option: any) {
    const self = this;
    let timeoutID;
    if (typeof option.count === 'undefined') {
      option.count = 1;
    }
    if (typeof option.interval === 'undefined') {
      option.interval = 1000;
    }
    if (option.count > 25) {
      return typeof option.error === 'function' && option.error();
    }
    const image = new Image();
    image.onload = () => typeof option.success === 'function' && option.success(image);
    image.onerror = () => {
      option.count++;
      timeoutID = setTimeout(() => self.checkRemoteImage(source, option), option.interval);
    };
    image.src = source;
  }

  // Reference to https://stackoverflow.com/users/508537/briguy37
  generateUUID() {
    let d = new Date().getTime();
    let d2 = (performance && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16;
      if (d > 0) {
        // tslint:disable-next-line: no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // tslint:disable-next-line: no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // tslint:disable-next-line: no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  // 根据设定宽高动态缩放字体大小
  dynamicFitTextOnCanvas(
    text: string,
    fontsize: number,
    fontface: string,
    desiredWidth: number,
    desiredHeight: number,
  ) {
    console.log('dynamic text:', text);
    let tmpDiv = document.querySelector('.tmp-div') as HTMLElement;
    if (!tmpDiv) {
      tmpDiv = document.createElement('div') as HTMLElement;
      tmpDiv.classList.add('tmp-div');
      tmpDiv.style.position = 'absolute';
      tmpDiv.style.left = '-100000px';
      tmpDiv.style.top = '-100000px';
      tmpDiv.style.visibility = 'hidden';
      // tmpDiv.style['left'] = '30%';
      // tmpDiv.style['top'] = '50%';
      // tmpDiv.style['background-color'] = 'antiquewhite';
      document.body.appendChild(tmpDiv);
    }
    tmpDiv.innerText = text;
    return measureDivBinary(tmpDiv, 0, fontsize, fontface, desiredWidth, desiredHeight);

    function measureDivBinary(
      element: HTMLElement,
      min: number,
      max: number,
      fontfamily: string,
      width: number,
      height: number,
    ) {
      if (max - min < 1) {
        return Math.floor(min);
      }
      const cur = min + (max - min) / 2;
      element.style.font = Math.floor(cur) + 'px ' + fontfamily;
      element.style.lineHeight = 'initial';
      const measureRect = element.getBoundingClientRect();
      if (measureRect.width > width || measureRect.height > desiredHeight) {
        return measureDivBinary(element, min, cur, fontfamily, width, height);
      } else {
        return measureDivBinary(element, cur, max, fontfamily, width, height);
      }
    }
  }

  // 缩放图片
  resizeImage(imgWidth: number, imgHeight: number, maxWidth: number, maxHeight?: number) {
    let width = maxWidth;
    let height = Math.ceil(imgHeight * (maxWidth / imgWidth));
    if (maxHeight > 0 && imgWidth < imgHeight) {
      width = Math.ceil(imgWidth * (maxHeight / imgHeight));
      height = maxHeight;
    }
    return { width, height };
  }

  // 压缩图片
  compressImage(file: File, isCompress: boolean, width?: number, height?: number): Observable<any> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Observable((observer) => {
      reader.onload = (ev) => {
        const img = new Image();
        img.src = (ev.target as any).result;
        (img.onload = () => {
          if (isCompress) {
            width = width || 256;
            height = height || 256;
            const canvas = document.createElement('canvas');
            const resize = this.resizeImage(img.width, img.height, width, height);
            canvas.width = resize.width;
            canvas.height = resize.height;
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            ctx.clearRect(0, 0, resize.width, resize.height);
            ctx.drawImage(img, 0, 0, resize.width, resize.height);
            ctx.canvas.toBlob((blob: Blob) => {
              observer.next({
                src: canvas.toDataURL(),
                file: new File([blob], file.name, {
                  type: blob.type,
                  lastModified: Date.now(),
                }),
                size: resize,
              });
            });
          } else {
            observer.next({ size: { width: img.width, height: img.height } });
          }
        }),
          (reader.onerror = (error) => observer.error(error));
      };
    });
  }

  loadImages(files: File[]): Observable<any> {
    let tobeload = 0;
    const images = [];
    return new Observable((observer) => {
      files.forEach((file) => {
        // console.log('tobeload', tobeload);
        tobeload++;
        const image = new Image();
        image.src = URL.createObjectURL(file);
        images.push(image);
        image.onload = () => {
          tobeload--;
          if (tobeload === 0) {
            observer.next({ images, width: image.width, height: image.height });
          }
        };
        image.onerror = (error) => observer.error(error);
      });
    });
  }

  generateFontImage(text: string, fontface: string): Observable<any> {
    console.log('text', text);
    console.log('fontface', fontface);
    let height = 80;
    let width = 560;
    const dynamicFontSize = this.dynamicFitTextOnCanvas(text, height, fontface, width, height);
    const fontStyle = dynamicFontSize + 'px ' + fontface;
    console.log('dynamicFontSize:' + dynamicFontSize);

    return new Observable((observer) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, width, height);
      ctx.font = fontStyle;
      width = Math.round(ctx.measureText(text).width) + 30;
      height += 10;
      canvas.width = width;
      canvas.height = height;
      ctx.clearRect(0, 0, width, height);
      ctx.font = fontStyle;
      ctx.fillStyle = '#000';
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.fillText(text, width / 2, height / 2);

      canvas.toBlob((blob: Blob) => {
        observer.next({
          src: canvas.toDataURL(),
          file: new File([blob], fontface + '.png', {
            type: blob.type,
            lastModified: Date.now(),
          }),
        });
      });
    });
  }

  convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // orientation 屏幕方向 count 屏幕数量 单元最小屏幕76 x 48 默认1块横屏
  convertScreenToPixel(orientation = 1, count = 1) {
    let width = ScreenSpec.Width;
    let height = ScreenSpec.Height;

    if (orientation === ScreenOrientation.Landscape) {
      width = ScreenSpec.Width * count;
      height = ScreenSpec.Height;
    }
    if (orientation === ScreenOrientation.Portrait) {
      width = ScreenSpec.Height;
      height = ScreenSpec.Width * count;
    }
    return { width, height };
  }

  // hex颜色转rgb颜色
  hex2Rgb(str: string) {
    if (!/^\#?[0-9A-Fa-f]{6}$/.test(str)) {
      console.log('utils.hex2Rgb Wrong hex value: ' + str);
      return null;
    }
    str = str.replace('#', '');
    const hxs: any[] = str.match(/../g);
    for (let i = 0; i < 3; i++) {
      hxs[i] = parseInt(hxs[i], 16);
    }
    // console.log('utils.hex2Rgb:' + parseInt(80, 16))
    // console.log('utils.hex2Rgb:' + hxs);
    return hxs;
  }

  // GRB颜色转Hex颜色
  rgb2Hex(a, b, c) {
    const r = /^\d{1,3}$/;
    if (!r.test(a) || !r.test(b) || !r.test(c)) {
      console.log('utils.hex2Rgb Wrong RGB color value:' + a + b + c);
      return null;
    }
    const hexs = [a.toString(16), b.toString(16), c.toString(16)];
    for (let i = 0; i < 3; i++) {
      if (hexs[i].length === 1) {
        hexs[i] = '0' + hexs[i];
      }
    }
    return '#' + hexs.join('');
  }

  // 得到hex颜色值为color的加深颜色值，level为加深的程度，限0-1之间
  darkColor(color, level) {
    const rgbc = this.hex2Rgb(color);
    if (rgbc) {
      for (let i = 0; i < 3; i++) {
        rgbc[i] = Math.floor(rgbc[i] * (1 - level)); // floor 向下取整
      }
      return this.rgb2Hex(rgbc[0], rgbc[1], rgbc[2]);
    }
    return color;
  }

  // 得到hex颜色值为color的减淡颜色值，level为加深的程度，限0-1之间
  lightColor(color, level) {
    const rgbc = this.hex2Rgb(color);
    if (rgbc) {
      for (let i = 0; i < 3; i++) {
        rgbc[i] = Math.floor((255 - rgbc[i]) * level + rgbc[i]);
      }
      return this.rgb2Hex(rgbc[0], rgbc[1], rgbc[2]);
    }
    return color;
  }

  lookup(array: number[], value: number) {
    if (!array.length || value < 1) {
      return;
    }
    // 将原数组复制
    const arr = array.concat([]);
    arr.push(value);
    // 数据排序
    arr.sort((a, b) => a - b);
    const index = arr.indexOf(value);
    // 当前项在数组第一项 / 最后一项，返回当前项的后一项 / 前一项
    if (index === 0) {
      return arr[index + 1];
    }

    if (index === array.length - 1) {
      return arr[index - 1];
    }
    // 当前项和前一项或者后一项比较，返回差值小的项
    const resultIndex = arr[index] - arr[index - 1] > arr[index + 1] - arr[index] ? index + 1 : index - 1;
    return arr[resultIndex];
  }

  hasDuplicates(items: any[]) {
    // 找出重复项
    let duplicates = items.filter((item, index, array) => array.indexOf(item) !== index);
    // 去重
    duplicates = duplicates.filter((item, index, array) => array.indexOf(item, 0) === index);
    return duplicates;
  }

  getDateDiff(dateStart, dateEnd?) {
    dateStart = new Date(dateStart);
    dateEnd = dateEnd ? new Date(dateEnd) : new Date();
    const diffValue = (dateEnd - dateStart) / (1000 * 60 * 60 * 24);
    return Math.abs(diffValue);
  }

  regSvgIcons(arr: { svgName: string; alias?: string; path?: string }[]) {
    if (arr && arr instanceof Array) {
      const defaultPath = '/assets/img/';
      arr.forEach((item) => {
        const _url = (item.path || defaultPath) + item.svgName + '.svg';
        this.iconRegistry.addSvgIcon(item.alias || item.svgName, this.sanitizer.bypassSecurityTrustResourceUrl(_url));
      });
    }
  }

  getPreviewScale(screenCount: number, isGallery = false): number {
    if (!isGallery) {
      return screenCount <= 5 ? 3 : 2;
    }
    return screenCount <= 5 ? 2 : 1;
  }

  getTemplatePreviewDialogConifg(
    item: Template,
    isEsign: boolean,
    isPublic = false,
    deviceType?: DeviceType,
  ): MatDialogConfig {
    let previewDialog: MatDialogConfig;
    if (isEsign && item.type === 'html5/canvas') {
      const innerWidth = window.innerWidth;
      const pixel = this.convertScreenToPixel(item.screenOrientation, item.screenCount);
      const _width = this.getPreviewScale(item.screenCount) * pixel.width + 48;
      previewDialog = {
        width: (_width > innerWidth ? innerWidth - 40 : _width) + 'px',
        maxHeight: window.innerHeight - 40 + 'px',
        data: { type: ContentType[isPublic ? 'EsTemplate' : 'EsMedia'], item, deviceType },
      };
    } else {
      previewDialog = {
        width: '50vw',
        minWidth: PosterConfig.previewMinWidth + 48 + 'px',
        maxWidth: PosterConfig.previewMaxWidth + 48 + 'px',
        data: { type: ContentType[isPublic ? 'PoTemplate' : 'PoMedia'], item, deviceType },
      };
    }
    console.log('getTemplatePreviewDialogConifg previewDialog', previewDialog);
    return previewDialog;
  }

  getGalleryPreviewDialogConfig(item: Gallery, isEsign: boolean): MatDialogConfig {
    const typeMap = new Map([
      [EsGalleryContentType.Background, ContentType.EsBackgroundGallery],
      [EsGalleryContentType.Animated, ContentType.EsAnimatedGallery],
    ]);
    const type = isEsign ? typeMap.get(item.contentType) : ContentType.PoGallery;
    let previewDialog: MatDialogConfig;
    // 背景按屏幕像素息显示，其余广告屏门头屏动画都统一显示
    if (item.contentType && item.contentType === EsGalleryContentType.Background) {
      const pixel = this.convertScreenToPixel(item.screenOrientation, item.screenCount);
      // const _width = pixel.width + 48;
      console.log('pixel', pixel);
      const _width = this.getPreviewScale(item.screenCount, true) * pixel.width + 48;
      previewDialog = {
        width: (_width > innerWidth ? innerWidth - 40 : _width) + 'px',
        maxHeight: window.innerHeight - 40 + 'px',
        data: { type, item },
      };
    } else {
      // 动画按1比1的比例显示
      previewDialog = {
        width: '50vw',
        minWidth: 96 + 48 + 'px', // 最小是按动画素材96px+窗口padding值48显示
        maxWidth: 256 + 48 + 'px',
        maxHeight: window.innerHeight - 40 + 'px',
        data: { type, item },
      };
    }
    console.log('getGalleryPreviewDialogConfig previewDialog', item, previewDialog);
    return previewDialog;
  }

  /**
   * 获取URL参数
   */
  parseUrl(key: string, type?: 'int' | 'bool') {
    let value = this.activateRoute.snapshot.queryParams[key];
    if (type === 'int') {
      value = parseInt(value, 10);
    } else if (type === 'bool') {
      value = value === true || value === '1' || value === 1 || value === 'true' ? true : false;
    }
    return value;
  }

  /**
   * Time Formatting
   * @param format yyyy-MM-dd hh:mm:ss
   * @param date Date
   */
  dateFormat(format: string, date: Date): string {
    const obj = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (const k in obj) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? obj[k] : ('00' + obj[k]).substr(('' + obj[k]).length),
        );
      }
    }
    return format;
  }

  momentFormat(format: string, date: any) {
    return moment(date).format(format);
  }

  getCssUrl(filename: string, stylesheet?: string) {
    return this.rest.fontsFiles(filename) + '/' + (stylesheet ? stylesheet : 'stylesheet.css') + '?v=2';
  }

  getVideoBase64(url) {
    return new Promise((resolve, reject) => {
      let dataURL = '';
      const video = document.createElement('video') as HTMLVideoElement;
      const canvas = document.createElement('canvas');
      const URL = window.URL || window.webkitURL

      video.autoplay = true;
      video.muted = true;
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        URL.revokeObjectURL(video.src);
        const width = video.videoWidth;
        const height = video.videoHeight;
        canvas.width = width;
        canvas.height = height;
        setTimeout(() => {
          canvas.getContext('2d').drawImage(video, 0, 0, width, height);
          dataURL = canvas.toDataURL('image/jpeg');
          resolve(dataURL);
        }, 168);
      };
      video.onerror = (error) => reject(error);
      video.src = url;
    });
  }

  loadRegularFonts(
    fonts: Array<{ filename: string; fontfamily: string; stylesheet: string }>,
    cb?: (progress: any) => void,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let loadNum = 0;
      const cacheFonts = [];
      fonts.forEach((item) => {
        if (!cacheFonts.find((obj) => obj.filename === item.filename)) {
          cacheFonts.push(item);
        }
      });
      if (!cacheFonts.length) {
        resolve(null);
        return;
      }
      // tslint:disable-next-line: no-unused-expression
      cb && cb(0);
      cacheFonts.forEach((font) => {
        const cssLink = this.getCssUrl(font.filename, font.stylesheet);
        // tslint:disable-next-line: no-unused-expression
        cb && cb(((100 / cacheFonts.length) * Math.random()).toFixed(1));
        this.loaderServ
          .loadFont(font.fontfamily, cssLink, () => {
            loadNum++;
            // tslint:disable-next-line: no-unused-expression
            cb && cb(((100 / cacheFonts.length) * loadNum).toFixed(1));
            if (loadNum >= cacheFonts.length) {
              resolve(null);
              return;
            }
          })
          .then(() => {})
          .catch((errr) => {
            console.log('Load regular fonts error:', errr);
          });
      });
    });
  }
}
