<ng-container *ngIf="!isDone; else doneTemplate">
  <h3>{{ 'USER_RETRIEVE_PASSWORD' | translate }}</h3>
  <div class="guide-text">{{ 'USER_RETRIEVE_PASSWORD_DESC' | translate }}</div>
  <div class="stage-text" *ngIf="stage == 0 || (stage > 0 && hasNumber)">
    {{ stages[stage] | translate: { type: verifyTypeText } }}
  </div>
  <div class="forms">
    <ng-container *ngIf="stage == 0">
      <mat-form-field>
        <input
          matInput
          [formControl]="usernameCtrl"
          [placeholder]="'USER_TEXT_USERNAME' | translate"
          autocomplete="off"
          required
        />
        <mat-error *ngIf="usernameCtrl?.hasError('required')">
          {{ 'COMMON_TEXT_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="usernameCtrl?.hasError('invalidUser')">
          {{ 'FORGET_PWD_INVALID_USER' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="stage == 1 && hasNumber">
      <strong class="user-number">{{ trustedUser.number }}</strong>
      <mat-form-field>
        <input matInput [formControl]="numberCtrl" [placeholder]="verifyTypeText" autocomplete="off" required />
        <mat-error *ngIf="numberCtrl?.hasError('required')">
          {{ 'COMMON_TEXT_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="numberCtrl?.hasError('invalidNumber')">
          {{ 'FORGET_PWD_INVALID_NUMBER' | translate: { type: verifyTypeText } }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="stage == 2 && hasNumber">
      <mat-form-field>
        <input matInput [formControl]="codeCtrl" [placeholder]="'COMMON_TEXT_VERIFICATION_CODE' | translate" required />
        <mat-error *ngIf="codeCtrl?.hasError('required')">{{ 'COMMON_TEXT_FIELD_REQUIRED' | translate }} </mat-error>
        <mat-error *ngIf="codeCtrl?.hasError('pattern')">{{ 'TWO_FACTORY_VERIFICATION_CODE' | translate }} </mat-error>
        <mat-error *ngIf="codeCtrl?.hasError('invalidCode')">{{ 'FORGET_PWD_INVALID_CODE' | translate }} </mat-error>
      </mat-form-field>
      <div class="send-again">
        {{ 'FORGET_PWD_NOT_RECEIVED_VERIFY_CODE' | translate }}
        <a (click)="resendVerifyCode()">{{ 'FORGET_PWD_SEND_AGAIN' | translate }}</a>
      </div>
    </ng-container>

    <ng-container *ngIf="stage == 3 && hasNumber">
      <form class="form" [formGroup]="passwordForm">
        <mat-form-field [hintLabel]="passwordRuleKey | translate">
          <input
            #psd
            matInput
            type="password"
            formControlName="password"
            [placeholder]="'LOGIN_TEXT_PASSWORD' | translate"
            autocomplete="new-password"
            required
          />
          <mat-hint align="end">{{ psd.value?.length || 0 }}/{{ pwdMin }}~{{ pwdMax }}</mat-hint>
          <mat-error *ngIf="passwordForm.get('password')?.invalid">
            {{ getPasswordErrorMessage() | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            type="password"
            formControlName="confirmPassword"
            [placeholder]="'LOGIN_TEXT_CONFIRM_PASSWORD' | translate"
            [errorStateMatcher]="matcher"
            autocomplete="new-password"
            required
          />
          <mat-error *ngIf="passwordForm.get('confirmPassword')?.hasError('required') || passwordForm.hasError('notSame')">
            {{ 'SIGN_UP_PASSWORD_NOT_MATCH' | translate }}
          </mat-error>
        </mat-form-field>
      </form>
    </ng-container>

    <ng-container *ngIf="stage > 0 && !hasNumber">
      <p class="err-tip">
        <mat-icon class="mat-18">error</mat-icon>
        {{ 'FORGET_PWD_NUMBER_NOT_BIND' | translate: { type: verifyTypeText } }}
      </p>
    </ng-container>
  </div>
  <div class="bottom-box">
    <div class="actions" *ngIf="stage == 0 || (stage > 0 && hasNumber)">
      <button *ngIf="stage > 0" mat-raised-button (click)="reset()">
        {{ 'BUTTON_CANCEL' | translate }}
      </button>
      <button *ngIf="stage < stages.length - 1" mat-raised-button color="primary" (click)="nextStep()">
        {{ 'BUTTON_NEXT' | translate }}
      </button>
      <button *ngIf="stage === stages.length - 1" mat-raised-button color="primary" (click)="done()">
        {{ 'BUTTON_OK' | translate }}
      </button>
      <img *ngIf="isLoadingNext" class="loading-img" src="./assets/img/spinner_37_1s.gif" />
    </div>
    <a (click)="gotoLogin()">{{ 'FORGET_PWD_BACK_TO_LOGIN' | translate }}</a>
  </div>
</ng-container>
<ng-template #doneTemplate>
  <div class="reset-done">
    <div><mat-icon class="mat-48">check_circle_outline</mat-icon></div>
    <p>{{ 'FORGET_PWD_RESET_SUCCESS' | translate }}</p>
    <div *ngIf="counter >= 0">({{ counter }}s)</div>
  </div>
</ng-template>
