import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import xorWith from 'lodash-es/xorWith';

import { Injectable } from '@angular/core';

import { Node } from '../models';

@Injectable()
export class ValidatorService {

  // 原作者要求的是自定义属性列必须和Node<T>中属性保持一致，不合理
  // validateCustomOrder<T, K extends Node<T>>(node: K, customColumnOrder: Array<keyof T> & string[]): { valid: boolean, xor: string[] } {
  //   const xor = _.xor(Object.keys(node.value), customColumnOrder);
  //   return {
  //     valid: _.isEmpty(xor),
  //     xor
  //   };
  // }

  // 只要自定义属性是包含在keys属性内就可以通过
  validateCustomOrder<T, K extends Node<T>>(node: K, customColumnOrder: Array<keyof T> & string[])
    : { valid: boolean, redundancy: string[] } {
    const keys = Object.keys(node.value);
    const xor = xorWith(keys, customColumnOrder, isEqual);
    const redundancy = xor.filter(f => keys.indexOf(f) === -1);
    return {
      valid: isEmpty(redundancy),
      redundancy
    };
  }

}
