import { Component, Input } from '@angular/core';

@Component({
  selector: 'nex-loading-diy',
  styles: [
    `
      .app-loading-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .app-loading {
        width: 100%;
        opacity: 0.7;
        height: 68px;
        text-align: center;
        position: relative;
      }
      .app-loading span {
        position: absolute;
        display: block;
        font-size: 13px;
        text-align: center;
        left: 0;
        right: 0;
        top: 25px;
        color: #ccc;
      }
    `,
  ],
  template: `
    <div class="app-loading-mask">
      <div class="app-loading">
        <img src="/assets/img/loading_circle.svg" />
        <span>{{ loadingText }}</span>
      </div>
    </div>
  `,
})
export class DiyLoadingComponent {
  @Input() loadingText = '';
}
