import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { UtilsService } from '@core';

@Component({
  selector: 'nex-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit, OnChanges {
  @Input() imgTitle = '';
  @Input() background = '#000';
  @Input() cursor = 'default';
  @Input() width = 192;
  @Input() height = 256;
  @Input() fullWidth = false;
  @Input() readonly = false;
  @Input() hasBorder = false;
  @Input() borderColor = '#ccc';
  @Input() source: string | ArrayBuffer | File;
  @Input() mimeType?: MimeType;
  @Output() fileChange = new EventEmitter<{ file: File; src: any }>();
  @Output() imageClick = new EventEmitter<any>();
  @Output() imageMouseOver = new EventEmitter<any>();
  @Output() imageMouseOut = new EventEmitter<any>();

  src: any = './assets/img/loading100px.svg';

  constructor(private utils: UtilsService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.source) {
      if (typeof this.source === 'string') {
        this.utils.checkRemoteImage(this.source, {
          success: () => {
            this.src = this.source as string;
          },
          error: () => (this.src = './assets/img/err_image.svg'),
        });
      } else if (typeof (this.source as File).name === 'string') {
        this.readFile(this.source as File);
      }
    }
  }

  changeFile(event: any) {
    if (event.target.files && event.target.files.length) {
      this.readFile(event.target.files[0]);
    }
  }

  clickImage(event: Event) {
    event.stopPropagation();
    this.imageClick.emit(event);
  }

  onMouseOver(event: MouseEvent) {
    this.imageMouseOver.emit(event);
  }

  onMouseOut(event: MouseEvent) {
    this.imageMouseOut.emit(event);
  }

  private readFile(file: File) {
    const URL = window.URL || window.webkitURL;
    if (file.type.includes('video')) {
      this.utils.getVideoBase64(URL.createObjectURL(file)).then((imageUrl) => {
        this.src = imageUrl;
        this.fileChange.emit({ file, src: imageUrl });
      });
    } else if (file.type.includes('image')) {
      const reader: FileReader = new FileReader();
      reader.onload = (e: Event) => {
        this.src = reader.result;
        this.fileChange.emit({ file, src: reader.result });
      };
      reader.onerror = (error) => {
        console.error('readFile error', error);
      };
      reader.readAsDataURL(file);
    }
  }
}
