import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AlertService,
  ContentType,
  DeviceType,
  Gallery,
  PreviewType,
  PropertymapService,
  RestService,
  StorageService,
  Template,
  UtilsService,
} from '@core';
import { EsignService, NexEditorService, NexPlayerComponent, Slides, TemplateService } from '@nexnovo/nex-editor';

@Component({
  selector: 'nex-previewer',
  templateUrl: './previewer.component.html',
  styleUrls: ['./previewer.component.scss'],
})
export class PreviewerComponent implements OnInit {
  @ViewChild('diyPlayer') diyPlayer: NexPlayerComponent;
  @Input() deviceType: DeviceType;
  // 初始值默认广告屏参数
  @Input() screenWidth = 384;
  @Input() screenHeight = 512;
  @Input() scale = 1;
  itemType: ContentType;
  itemData: Template | Gallery;
  filename: string;

  showType: PreviewType;
  PreviewType = PreviewType;
  isTemplate: boolean;

  // template video
  videoPoster: string;
  videoSources = [];

  // template canvas
  ready = false;
  playing = false;
  mouseEnter = false;
  played = false;
  loadingProgress = 'loading';
  playProgress = 0;
  slides: Slides[] = [];
  screenOrientation: number;
  screenCount: number;
  slideIndex = -1;

  // gallery
  fontPreviewImage: string;
  spriteSrc: string;
  spriteData = { width: 0, height: 0, count: 0, index: 0 };

  templateTypes = [
    { type: ContentType.PoTemplate, url: 'v3/publictemplates/', credentials: true },
    { type: ContentType.EsTemplate, url: 'v3/publictemplates_es/', credentials: true },
    { type: ContentType.PoMedia, url: 'v2/user/templates/', credentials: false },
    { type: ContentType.EsMedia, url: 'v2/user/templates_es/', credentials: false },
  ];

  @Input() set contentType(value: ContentType) {
    if (value) {
      this.itemType = value;
      this.isTemplate = this.templateTypes.map((m) => m.type).includes(value);
    }
  }

  @Input() set contentData(value: Template | Gallery) {
    if (value) {
      this.itemData = value;
      this.filename = this.itemData.filename;
    }
  }

  get isEsign() {
    console.log('new previewer deviceType', this.deviceType);
    return (this.deviceType || this.storage.user.deviceType) === DeviceType.Esign;
  }

  constructor(
    private rest: RestService,
    private storage: StorageService,
    private alert: AlertService,
    private property: PropertymapService,
    private esignServ: EsignService,
    private templateServ: TemplateService,
    private nexeditor: NexEditorService,
    private utils: UtilsService,
  ) { }

  ngOnInit() {
    console.log('isEsign', this.isEsign, 'isTemplate', this.isTemplate);
    this.isTemplate ? this.loadTemplate() : this.loadGallery();
  }

  initEsignData(item: Template) {
    const defaultScreen = this.property.getUserEsDefault();
    this.screenOrientation = item.screenOrientation || defaultScreen.orientation;
    this.screenCount = item.screenCount || defaultScreen.count;

    const sizes = this.esignServ.esignPixel(this.screenOrientation, this.screenCount);
    this.screenWidth = sizes.width;
    this.screenHeight = sizes.height;
    console.log('scale 1', this.scale);
    this.scale = this.utils.getPreviewScale(this.screenCount);
    console.log('scale 2', this.scale);
    this.templateServ.esignConfig(this.screenOrientation, this.screenCount);
  }

  onProgress(ev: any) {
    if (ev.type === 'load') {
      this.loadingProgress = ev.value;
    } else {
      this.playProgress = ev.value ? parseFloat(ev.value) : 0;
    }
  }

  onEvents(ev: any) {
    console.log('onEvents', ev);
    if (ev.type === 'ready') {
      this.playing = ev.status;
      this.ready = true;
    } else if (ev.type === 'play') {
      this.playing = ev.status;
      this.slideIndex = ev.index;
    } else if (ev.type === 'next') {
      this.ready = false;
      this.slideIndex = ev.index;
    } else if (ev.type === 'played') {
      this.playing = false;
      this.played = true;
      this.slideIndex = ev.index;
    } else if (ev.type === 'error') {
      this.ready = false;
      this.alert.alertWarning('DIY_RESOURCE_LOADING_FAILED');
      setTimeout(() => (this.loadingProgress = 'Failed'), 500);
    }
  }

  onPlayStateClick() {
    if (!this.diyPlayer) {
      return;
    }
    if (this.played) {
      this.diyPlayer.drawSliders();
      this.played = false;
    } else {
      this.playing = this.diyPlayer.playPause(!this.playing);
    }
  }

  async loadTemplate() {
    this.itemData = this.itemData as Template;
    if (this.itemData.type === 'video/mp4') {
      this.showType = PreviewType.Video;
      this.videoPoster = this.itemData?.previewImage ? this.itemData?.previewImage : this.rest.videoPreviewJPG(this.filename);
      this.videoSources = [{
        src: this.itemData?.sourceSrc ? this.itemData?.sourceSrc : this.rest.videoPreviewMP4(this.filename),
        type: 'video/mp4'
      }];
    }
    if (this.itemData.type === 'html5/canvas') {
      this.showType = PreviewType.Diy;
      if (this.isEsign) {
        this.initEsignData(this.itemData);
      }
      if (this.itemData.slides && typeof (this.itemData.slides) == 'string') {
        this.itemData.slides = JSON.parse(this.itemData.slides);
      }
      let _slides = [];
      if (this.itemData.slides && this.itemData.slides.length) {
        _slides = cloneDeep(this.itemData.slides);
      } else {
        const typeConfig = this.templateTypes.find((f) => f.type === this.itemType);
        if (typeConfig) {
          const _url = typeConfig.url + this.itemData._id;
          const templateRes = await this.rest.getJson(_url, null, typeConfig.credentials).toPromise();
          _slides = templateRes.data.slides || [];
        }
      }
      if (_slides.length) {
        this.slides = this.nexeditor.getScaleSlides(_slides, this.scale, this.screenWidth, this.screenHeight);
      }
      console.log('loaded diy slides', this.slides);
    }
  }

  loadGallery() {
    this.itemData = this.itemData as Gallery;
    if (this.itemData.type === 'font') {
      this.showType = PreviewType.Font;
      this.fontPreviewImage = this.rest.fontsPreview(this.itemData.fontpreviewfile || this.filename);
    }
    if (['image', 'text'].indexOf(this.itemData.type) >= 0) {
      if (this.itemData.filedata && typeof (this.itemData.filedata) == 'string') {
        this.itemData.filedata = JSON.parse(this.itemData.filedata);
      }
      this.showType = PreviewType.Animate;
      this.spriteSrc = this.itemData.sourceSrc ? this.itemData.sourceSrc : this.rest.imagesPath(this.filename);
      const itemSprite = get(this.itemData, 'filedata.sprite', []);
      console.log('xxxxxxxxx', itemSprite)
      if (itemSprite.length) {
        this.spriteData = {
          width: itemSprite[0],
          height: itemSprite[1],
          count: itemSprite[2],
          index: itemSprite[3],
        };
        console.log('spriteData', this.spriteData);
      }
    }
  }
}
