import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, Identify, SidenavService } from '@core';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class LayoutHeaderComponent implements OnInit, OnDestroy {
  @Input() isSmallScreen = false;
  @Output() sidenavClose = new EventEmitter<void>();

  authUser: Identify;
  authSub: Subscription;

  constructor(
    private auth: AuthService,
    public dialog: MatDialog,
    private router: Router,
    public sidenavService: SidenavService,
  ) {}

  ngOnInit() {
    this.authSub = this.auth.authChange.subscribe((authUser) => {
      this.authUser = authUser;
    });
  }

  ngOnDestroy() {
    this.authSub.unsubscribe();
  }

  gotoHome() {
    this.closeSide();
    this.router.navigate(['/']);
  }

  onAccountUpgrade() {
    this.closeSide();
    this.router.navigate(['/account/upgrade']);
  }

  onlineSupport() {
    this.closeSide();
    this.router.navigate(['/account/support']);
  }

  onSettingsClick() {
    this.closeSide();
    this.router.navigate(['/account/settings']);
  }

  onLogout() {
    this.auth.logout();
  }

  private closeSide() {
    this.sidenavClose.emit();
  }
}
