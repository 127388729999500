// export interface OwnerData {
//   _id: string;
//   username: string;
//   displayName: string;
// }

export interface Schedule {
  color: string;
  endTime: string;
  hourId: string;
  name: string;
  playListId: string;
  startTime: string;
}

export interface DayModel {
  _id: string;
  color: string;
  name: string;
  isEmpty: boolean;
}
