<ng-template #tree let-list>
  <ng-container *ngFor="let item of list">
    <a
      class="router-link"
      [attr.title]="item.key | translate"
      (click)="closeSide()"
      routerLink="{{ item.endpoint }}"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: pathIgnore.indexOf(item.endpoint) < 0 }"
      *ngIf="item.children.length === 0"
    >
      <mat-icon class="mat-18" *ngIf="item.icon">{{ item.icon }}</mat-icon>
      <span class="link-text">{{ item.key | translate }}</span>
    </a>
    <mat-expansion-panel [expanded]="isExpanded" routerLinkActive="panel" *ngIf="item.children.length > 0">
      <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
        <a class="parent router-link" (click)="closeSide()" [attr.title]="item.key | translate">
          <mat-icon class="mat-18" *ngIf="item.icon">{{ item.icon }}</mat-icon>
          <span class="link-text">{{ item.key | translate }}</span>
        </a>
      </mat-expansion-panel-header>
      <div class="sidenav">
        <ng-template [ngTemplateOutlet]="tree" [ngTemplateOutletContext]="{ $implicit: item.children }"></ng-template>
      </div>
    </mat-expansion-panel>
  </ng-container>
</ng-template>

<div class="uinfo" *ngIf="authUser">
  <strong [attr.title]="authUser.displayName">{{ authUser.displayName }}</strong>
  <span [attr.title]="authUser.username"> {{ authUser.username }}</span>
</div>
<div class="sidenav">
  <ng-container *ngFor="let group of menus">
    <div class="sidenav-group" *ngIf="group.group">
      <span [innerHTML]="group.key | translate"></span>
    </div>
    <ng-template [ngTemplateOutlet]="tree" [ngTemplateOutletContext]="{ $implicit: group.children }"></ng-template>
  </ng-container>
</div>
