import { Injectable } from '@angular/core';
import { Identify } from '@core/models/identify';

export const USER = 'user';
export const XTOKEN = 'xtoken';
export const KEEP_LOGIN = 'keep_login';
// 计划通过本地存储方式实现刷新时页面数据不丢失，所以每个页面独立保存
// 广告屏的暂时共用一个
export const ESIGN_EDITOR = 'esign_editor';
export const ESIGN_SCROLL = 'esign_scroll';
export const POSTER_EDITOR = 'poster_editor';

@Injectable({ providedIn: 'root' })
export class StorageService {
  private _user: Identify | null = null;

  get(key: string, defalut?: any) {
    return JSON.parse(localStorage.getItem(key) || 'null') || defalut || null;
  }

  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  get user(): Identify {
    if (!this._user) {
      this._user = { ...this.get(USER) };
      this.set(USER, this._user);
    }
    return this._user as Identify;
  }

  setUser(value: Identify) {
    this._user = value;
    this.set(USER, value);
    return true;
  }

  clear(keys: string[]) {
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }
}
