import { Component } from '@angular/core';
import { AuthService, DeviceType, Identify, RestService } from '@core';

@Component({
  selector: 'nex-mode-select',
  template: `
    <h1 class="select-for">{{ 'DESIGNER_GUIDE_TEXT' | translate }}</h1>
    <ul
      class="device-type"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10%"
      fxLayoutGap.lt-sm="24px"
      fxLayout.lt-sm="column"
      fxLayoutAlign.lt-sm="center center"
    >
      <li fxFlex class="device-item" (click)="goto(DeviceType.Poster)">
        <img src="./assets/img/device_poster.png" />
        <h3>{{ 'DEVICE_TYPE_POSTER' | translate }}</h3>
      </li>
      <li fxFlex class="device-item" (click)="goto(DeviceType.Esign)">
        <img src="./assets/img/device_esign.png" />
        <h3>{{ 'DEVICE_TYPE_ESIGN' | translate }}</h3>
      </li>
    </ul>
  `,
  styleUrls: ['./device-mode.component.scss'],
})
export class DeviceModeComponent {
  user: Identify;

  constructor(private rest: RestService, private auth: AuthService) {
    this.user = auth.getUser();
  }

  get DeviceType() {
    return DeviceType;
  }

  goto(type: number) {
    this.rest.putJson('v3/user/devicetype', { deviceType: type, screenOrientation: 1 }).subscribe((res) => {
      if (this.user.initPwd || this.user.initUsername) {
        this.auth.setUrl('/account/settings');
      } else {
        this.auth.setUrl('/');
      }
      this.auth.identify();
    });
  }
}
