import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBool'
})
export class ConvertBoolPipe implements PipeTransform {

  transform(value: boolean, args1?: string, args2?: string): string {
    return value ? (args1 || 'COMMON_TEXT_FIELD_ENABLE') : (args2 || 'COMMON_TEXT_FIELD_DISABLE');
  }

}
