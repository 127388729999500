<h1 matDialogTitle>{{ i18nTitle | translate }}</h1>

<p mat-card-subtitle *ngIf="i18nSubtitle">{{ i18nSubtitle | translate }}</p>

<div class="toggle-group" *ngIf="isShowToggleGroup">
  <mat-button-toggle-group [(ngModel)]="toggleType" (ngModelChange)="onToggle()" appearance="legacy">
    <ng-container *ngFor="let option of toggleGroupOptions">
      <mat-button-toggle *ngIf="!option.disabled" [value]="option.value">
        {{ option.text | translate }}
      </mat-button-toggle>
    </ng-container>
  </mat-button-toggle-group>
</div>

<div class="user-material" nexInfiniteScroll (infiniteScrollAction)="loadMore()">
  <div fxLayout="row wrap" fxLayoutAlign="start start">
    <div class="list-item" *ngFor="let item of userTemplates" (click)="onItemClick(item)">
      <div class="list-item-box" [class.selected]="item.selected">
        <span *ngIf="item.selected">
          <mat-icon class="mat-24">done</mat-icon>
        </span>
        <img
          nexCheckImage
          [checkSrc]="getPreviewSource(item)"
          [title]="item.name || ('COMMON_TEXT_UNNAMED' | translate)"
        />
      </div>
    </div>
  </div>
  <div class="loading-box" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="tabs-empty" *ngIf="!isLoading && !userTemplates.length">
    <mat-icon class="mat-48">error_outline</mat-icon>
    <p>{{ 'DIY_NO_MATERIAL_PROMPT' | translate }}</p>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-button (click)="onClose($event)">{{ 'BUTTON_CANCEL' | translate }}</button>
  <button mat-button color="primary" [disabled]="!selectedItems.length" (click)="onDone()">
    {{ 'BUTTON_OK' | translate }}
  </button>
</mat-dialog-actions>
