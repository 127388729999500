import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { AuthService, Identify, SidenavService, UtilsService } from '@core';

const IGNORE_PATH_OF_BREADCRUMBS = ['/exception'];

@Component({
  selector: 'layout-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class LayoutDefaultComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: true }) sidenavRef: MatSidenav;

  showSpinner = false;
  isFetching = false;
  isSmallScreen: boolean;
  isHideBreadcrumb = false;

  message: any;
  authUser: Identify;
  authSub: Subscription;

  private unsubscribe$ = new Subject<void>();

  constructor(
    router: Router,
    utils: UtilsService,
    public sidenavService: SidenavService,
    private auth: AuthService,
    public breakpointObserver: BreakpointObserver, // messaging: MessagingServices,
  ) {
    utils.spinnerChanged.subscribe((status) => {
      setTimeout(() => (this.showSpinner = status));
    });

    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((evt) => {
      // console.log('current_url', evt);
      if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
        this.isFetching = true;
      }
      if (evt instanceof NavigationError || evt instanceof NavigationCancel) {
        this.isFetching = false;
        if (evt instanceof NavigationError) {
          console.error(`无法加载${evt.url}路由`);
        }
        return;
      }
      if (!(evt instanceof NavigationEnd || evt instanceof RouteConfigLoadEnd)) {
        return;
      }
      if (evt instanceof NavigationEnd) {
        this.isHideBreadcrumb =
          IGNORE_PATH_OF_BREADCRUMBS.filter((f) => {
            const reg = new RegExp(decodeURI(f).replace(/\/+/g, '\\/').normalize());
            // console.log('reg', reg);
            return reg.test(evt.url);
          }).length > 0;
        // console.log('isHideBreadcrumb', this.isHideBreadcrumb);
      }
      if (this.isFetching) {
        setTimeout(() => (this.isFetching = false), 500);
      }
    });
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .subscribe((state: BreakpointState) => (this.isSmallScreen = state.matches));

    this.authSub = this.auth.authChange.subscribe((authUser) => {
      this.authUser = authUser;
      // this.sidenavRef.opened = this.sidenavService.isOpen = this.authUser && !this.isSmallScreen;
      // if (this.authUser) {
      //   this.messaging.requestPermission(this.authUser._id);
      //   this.messaging.receiveMessage();
      //   this.message = this.messaging.currentMessage;
      // }
    });
    this.sidenavService.sidenavToggle.subscribe((res: boolean) => this.sidenavRef.toggle(res));
  }

  ngOnDestroy(): void {
    const { unsubscribe$ } = this;
    unsubscribe$.next();
    unsubscribe$.complete();
    this.authSub.unsubscribe();
  }

  onSidenavClose() {
    if (this.isSmallScreen && this.sidenavService.isOpen) {
      this.sidenavService.isOpen = false;
      this.sidenavRef.close();
    }
  }
}
