import { Subscription } from 'rxjs';

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService, Identify, SidenavService } from '@core';

@Component({
  selector: 'layout-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class LayoutPanelComponent implements OnInit, OnDestroy {
  @Output() sidenavClose = new EventEmitter<void>();

  isExpanded = true;
  authSub: Subscription;
  authUser: Identify;
  menus = [];
  pathIgnore = [
    '/device/list',
    '/designer/templates',
    '/designer/galleries',
    '/designer/orders',
    '/distributor/arrival',
    '/configure/companies',
  ];

  constructor(private auth: AuthService, sidenavService: SidenavService) {
    sidenavService.sidenavCollapse.subscribe((expanded) => (this.isExpanded = expanded));
  }

  ngOnInit() {
    this.authSub = this.auth.authChange.subscribe((authUser) => {
      this.authUser = authUser;
      if (this.authUser && this.authUser.menus.length) {
        this.menus = authUser.menus;
      }
    });
  }

  ngOnDestroy() {
    this.authSub.unsubscribe();
  }

  closeSide() {
    this.sidenavClose.emit();
  }
}
