import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'nex-loading',
  template: `
    <div class="sk-container" [ngStyle]="{ marginTop: spacing, marginBottom: spacing }">
      <div
        class="info delay-info"
        [ngStyle]="{ color: textColor ? textColor : '' }"
        *ngIf="isShowDelayText; else loadingTemplate"
      >
        <mat-icon [class]="iconClass">error_outline</mat-icon>
        <p>{{ delayText | translate }}</p>
      </div>
      <ng-template #loadingTemplate>
        <div class="sk-circle">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
        <div  class="info" [ngStyle]="{ color: textColor ? textColor : '' }">{{ loadingText | translate }}</div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  @Input() spacing = '50%';
  @Input() iconClass = 'mat-36';
  @Input() delay = 15000;
  @Input() delayText = 'COMMON_TEXT_DATA_LOAD_TIMEOUT';
  @Input() loadingText = 'LOADING_TEXT_DEFAULT';
  @Input() textColor: string;

  isShowDelayText = false;
  private timer: number;

  ngOnInit(): void {
    this.timer = this.delay >= 0 ? window.setTimeout(() => (this.isShowDelayText = true), this.delay) : null;
  }

  ngOnDestroy(): void {
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
  }
}
