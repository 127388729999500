import { AbstractControl } from '@angular/forms';

export function ValidateUsername(control: AbstractControl) {
  if (control.value.indexOf('@') !== -1) {
    // const regEmail = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
    // const regEmail = /^([\w\-\_\.]+)+@[A-z0-9\-]+\.([A-z]{2,6})$/;
    const regEmail = /^([\w\-\_\.]+)+@[A-z0-9\-\.]+\.([A-z]{2,6})$/;
    return regEmail.test(control.value) ? null : { username: true };
  }

  const reg = /^[0-9A-Za-z]{8,40}$/;

  return reg.test(control.value) ? null : { username: true };
}
