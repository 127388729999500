import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@env';

import { Identify } from '../models/identify';
import { RestService } from './rest.service';
import { UtilsService } from './utils.service';
import { KEEP_LOGIN, StorageService, USER, XTOKEN } from './storage.service';

export interface Token {
  token_type: string;
  access_token: string;
  expires_in: number;
  refresh_token?: string;
  code?: number;
  msgCode?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url = '/';
  private isDeviceChanged = false;
  private isAuthenticated = false;
  private user: Identify = null;
  private authState = new Subject<any>();

  public authChange = new BehaviorSubject<Identify>(null);
  public authWait = true;

  constructor(
    private router: Router,
    private rest: RestService,
    private storage: StorageService,
    private utils: UtilsService,
    private dialogRef: MatDialog,
  ) {}

  initAuthListener() {
    this.authState.subscribe((user: Identify) => {
      this.user = user;
      if (user) {
        const selectedLang = this.utils.getSelectedLang().code;
        if (user.languageCode !== selectedLang) {
          user.languageCode = selectedLang;
          this.updateLanguageCode(selectedLang);
        }
        user.landscape = user.landscape && user.landscape instanceof Array ? user.landscape : [];
        user.portrait = user.portrait && user.portrait instanceof Array ? user.portrait : [];
        this.isAuthenticated = true;
        this.authChange.next(user);
        this.storage.setUser(user);
        this.storage.set(KEEP_LOGIN, true);
        if (user.defaultPwd || user.passwordExpire) {
          this.router.navigate(['/startup/change-password']);
        } else if (user.tokenAccountVerify) {
          this.router.navigate(['/startup/verify']);
        } else if (!user.deviceType) {
          this.router.navigate(['/startup/mode']);
        } else {
          this.router.navigate([this.url]);
        }
      } else {
        this.authChange.next(null);
        this.storage.clear([XTOKEN, USER]);
        this.router.navigate(['/passport/login']);
        this.isAuthenticated = false;
      }
    });

    const token = this.storage.get(XTOKEN) as Token;
    if (token && token.access_token) {
      this.utils.showSpinner(true);
      this.rest.setToken(token.access_token);
      this.identify();
    } else {
      this.authWait = false;
    }
  }

  getUser() {
    return this.user;
  }

  getUrl() {
    return this.url;
  }

  setUrl(url: string) {
    this.url = url;
  }

  getDeviceStatus() {
    return this.isDeviceChanged;
  }

  setDeviceStatus(isChanged: boolean) {
    this.isDeviceChanged = isChanged;
  }

  isTokenValid() {
    const _xtoken = this.storage.get(XTOKEN);
    if (this.user && !_xtoken) {
      this.user = null;
      this.authChange.next(null);
    }
    return !!_xtoken;
  }

  isAuthorized() {
    return this.isAuthenticated && this.isTokenValid();
  }

  checkPermission(key: string): boolean {
    return key
      .split(',')
      .every((item: string) => item && Boolean((this.user && this.user.permissions ? this.user.permissions : []).includes(item)));
  }

  logout() {
    this.utils.showSpinner(true);
    this.rest.deleteJson('v3/user/logout').subscribe(
      () => this.finishLogout(),
      () => this.finishLogout(),
    );
  }

  finishLogout() {
    this.storage.clear([XTOKEN, USER, KEEP_LOGIN]);
    this.authState.next(null);
    this.authWait = false;
    this.utils.showSpinner(false);
    this.dialogRef.closeAll();
  }

  identify() {
    this.rest.getJson('v3/user/identify').subscribe(
      (res: any) => {
        this.setDeviceStatus(false);
        this.authState.next(res.data);
        this.utils.showSpinner(false);
      },
      () => {
        this.authState.next(null);
        this.utils.showSpinner(false);
      },
    );
  }

  updateLanguageCode(languageCode: string) {
    this.rest.putJson('v3/user/profile', { languageCode }).subscribe();
  }

  getCaptchaSource() {
    return this.rest.getApiPath() + 'v3/auth/validcodeimage';
  }

  verifyCaptcha(code: string) {
    return timer(500).pipe(switchMap(() => this.rest.postJson('v3/auth/verify', { verificationCode: code })));
  }

  verifyAsyncPassword(password: string, userId?: string) {
    return timer(100).pipe(switchMap(() => this.rest.postJson('v3/verify/password', { password, userId })));
  }

  verifyNumber(inputNumber: string, userId: string) {
    return timer(100).pipe(switchMap(() => this.rest.postJson('v3/user/checknumber', { number: inputNumber, userId })));
  }

  async getPasswordRuleKey() {
    const res = await this.rest.getJson('v3/company/passwordrule').toPromise();
    return res.data || 'PASSWORD_RULES_DEFAULT';
  }

  login(username: any, password: any, captcha?: string, identityId?: string): Observable<any> {
    const loginData = {
      username,
      password,
      verificationCode: captcha,
      identityId: identityId || '',
      grant_type: 'password',
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    };
    return new Observable((observer) => {
      this.rest.postForm('v3/auth/token', loginData).subscribe(
        (token: Token) => {
          if (token && token.access_token) {
            this.storage.set(XTOKEN, token);
            this.rest.setToken(token.access_token);
            this.identify();
          }
          observer.next(token);
          observer.complete();
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          observer.error(error);
          observer.complete();
        },
      );
    });
  }
}
