<mat-toolbar class="layout-toolbar" [ngClass]="{ toolbar: isSmallScreen }" color="primary">
  <ng-container *ngIf="authUser; else appTitle">
    <!-- <ng-template [ngTemplateOutlet]="appTitle" *ngIf="authUser.appuser"></ng-template> -->
    <ng-template [ngTemplateOutlet]="appTitle"></ng-template>

    <button
      mat-icon-button
      class="toggle-button"
      (click)="sidenavService.toggle()"
      [matTooltip]="(sidenavService.isOpen ? 'HEADER_BTN_CLOSE_PANEL' : 'HEADER_BTN_OPEN_PANEL') | translate"
    >
      <mat-icon>{{ sidenavService.isOpen ? 'format_indent_decrease' : 'format_indent_increase' }} </mat-icon>
    </button>

    <button
      fxHide.xs
      mat-icon-button
      class="toggle-button"
      (click)="sidenavService.collapse()"
      [matTooltip]="(sidenavService.isExpanded ? 'HEADER_BTN_COLLAPSE_ALL' : 'HEADER_BTN_EXPAND_ALL') | translate"
    >
      <mat-icon>{{ sidenavService.isExpanded ? 'unfold_less' : 'unfold_more' }} </mat-icon>
    </button>

    <!-- <h1 *ngIf="!authUser.appuser">
      <a class="title" (click)="gotoHome()">
        <span *ngIf="authUser">{{ authUser.companyName }}</span>
      </a>
    </h1> -->
  </ng-container>
  <ng-template #appTitle>
    <div class="logo">
      <img src="/assets/img/logo.png" alt="Nexnovo" (click)="gotoHome()" />
    </div>
  </ng-template>

  <div fxFlex fxLayout fxLayoutAlign="flex-end">
    <ng-container *ngIf="authUser">
      <header-mode></header-mode>
      <header-i18n></header-i18n>
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>account_circle</mat-icon>
        <span fxHide.xs class="nav-caption">
          {{ (authUser.displayName || '').trim() || authUser.username | truncate: [20, '...'] }}
        </span>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onAccountUpgrade()" *ngIf="authUser.appuser">
          <mat-icon class="mat-18">groups</mat-icon> {{ 'ACCOUNT_UPGRADE' | translate }}
        </button>
        <button mat-menu-item (click)="onlineSupport()">
          <mat-icon class="mat-18">live_help</mat-icon> {{ 'ACCOUNT_SUPPORT' | translate }}
        </button>
        <button mat-menu-item (click)="onSettingsClick()">
          <mat-icon class="mat-18">settings</mat-icon> {{ 'ACCOUNT_SETTINGS' | translate }}
        </button>
        <button mat-menu-item (click)="onLogout()">
          <mat-icon class="mat-18">eject</mat-icon> {{ 'APP_LOGOUT' | translate }}
        </button>
      </mat-menu>
    </ng-container>
  </div>
</mat-toolbar>
