import { Subscription } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService, Identify, SidenavService } from '@core';

@Component({
  selector: 'nex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Output() sidenavClose = new EventEmitter<void>();

  isExpanded = true;
  authSub: Subscription;
  authUser: Identify;
  menus = [];
  pathIgnore = [
    '/device/list',
    '/designer/templates',
    '/designer/galleries',
    '/designer/orders',
    '/distributor/arrival',
    '/configure/companies',
  ];

  constructor(private auth: AuthService, sidenavService: SidenavService, private http: HttpClient) {
    sidenavService.sidenavCollapse.subscribe((expanded) => (this.isExpanded = expanded));
  }

  ngOnInit() {
    // test
    // this.http.get('./assets/menu.json').subscribe((res: any) => {
    //   console.log(res.menus);
    //   this.menus = res.menus;
    // });
    this.authSub = this.auth.authChange.subscribe((authUser) => {
      this.authUser = authUser;
      if (this.authUser && this.authUser.menus.length) {
        this.menus = authUser.menus;
      }
    });
  }

  ngOnDestroy() {
    this.authSub.unsubscribe();
  }

  closeSide() {
    this.sidenavClose.emit();
  }
}
