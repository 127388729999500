import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilsService, RestService, ServerResponse, ResponseCode, AuthService, Identify, AlertService } from '@core';
import { GlobalVariable } from '@global';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { timer } from 'rxjs';
import { switchMap, tap, finalize } from 'rxjs/operators';

@Component({
  selector: 'nex-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss'],
})
export class TwoFactorComponent implements OnInit, OnDestroy {
  loginUser: Identify;
  verifyType: string;
  isLoading = false;
  counter: number;
  resendTimer: any;

  codeFormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,6}$')]);

  constructor(
    private utils: UtilsService,
    private translate: TranslateService,
    private rest: RestService,
    private alert: AlertService,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.loginUser = this.auth.getUser();
    this.verifyType = this.loginUser.tokenAccountVerify;
  }

  ngOnDestroy(): void {
    if (this.resendTimer) {
      window.clearInterval(this.resendTimer);
    }
  }

  get validAccount() {
    return this.loginUser[this.verifyType];
  }

  get validTypeI18nText() {
    if (this.verifyType === GlobalVariable.VERIFY_TYPE_PHONE) {
      return this.translate.instant('USER_TEXT_PHONE');
    }
    if (this.verifyType === GlobalVariable.VERIFY_TYPE_EMAIL) {
      return this.translate.instant('USER_TEXT_EMAIL');
    }
    return '-';
  }

  get minute() {
    return this.loginUser.messageExpireMinute || 5;
  }

  send() {
    this.isLoading = true;
    timer(800)
      .pipe(switchMap(() => this.getValidCode().pipe(finalize(() => (this.isLoading = false)))))
      .subscribe((res: ServerResponse) => {
        if (res.code === ResponseCode.Success) {
          this.counter = GlobalVariable.VERIFY_COUNTER;
          this.resendTimer = window.setInterval(() => {
            if (this.counter > 0) {
              this.counter--;
            } else {
              window.clearInterval(this.resendTimer);
            }
          }, 1000);
        }
      });
  }

  gotoLogin() {
    this.auth.finishLogout();
  }

  validCode() {
    const code = this.codeFormControl.value;
    if (!code) {
      this.alert.alertInfo('LOGIN_TEXT_CAPTCHA');
      return;
    }
    this.utils.showSpinner(true);
    this.rest.postJson('v3/auth/checkverifycode', { eventType: 2, type: this.verifyType, code }).subscribe(
      (res: ServerResponse) => {
        this.utils.showSpinner(false);
        if (res.code === ResponseCode.Warning) {
          this.codeFormControl.reset();
          return;
        }
        // this.alert.alertSuccess('TWO_FACTORY_VALIDATE_SUCCESS');
        this.auth.setUrl('/');
        this.auth.identify();
      },
      () => {
        this.utils.showSpinner(false);
        this.alert.alertFailure();
      },
    );
  }

  getValidCode() {
    return this.rest.postJson('v3/auth/getverifycode', { eventType: 2, type: this.verifyType });
  }
}
