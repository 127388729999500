import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { environment } from '@env';
import { Device } from '@core/models/device';
import { TranslateService } from '@ngx-translate/core';

declare const Microsoft: any;

@Component({
  selector: 'nex-bing-map',
  template: `<div #devicesMap style='width: 100%; height: 85vh;'></div>`
})
export class BingMapComponent implements OnInit, AfterViewInit {
  @ViewChild('devicesMap', { static: true }) devicesMapEl: ElementRef;
  @Input() devices: Device[] = [];

  map: any;
  infoBox: any;
  apiKey: string;
  locations: any[];
  clusterLayer: any;
  defaultLocation = { latitude: 22.727532, longitude: 114.402336 }; // 默认为晶泓公司的地址

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.apiKey = environment.bingApiKey;
    console.log('devices', this.devices);
  }

  ngAfterViewInit() {
    if (typeof Microsoft !== 'undefined') {
      console.log('BingMapComponent.ngAfterViewInit');
      this.loadMap();
    }
  }

  loadMap() {
    this.map = new Microsoft.Maps.Map(this.devicesMapEl.nativeElement, {
      credentials: this.apiKey
    });

    // Set the display range according to the current location of all devices
    this.setMapBounds(this.devices);

    // Create an infobox at the center of the map but don't show it.
    this.infoBox = new Microsoft.Maps.Infobox(this.map.getCenter(), {
      visible: false
    });
    // Assign the infobox to a map instance.
    this.infoBox.setMap(this.map);

    Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
      const pins = [];
      this.devices.forEach((d: Device) => {
        console.log('location', d.location);
        if (d.location) {

          // 如果为空，默认为公司经纬度
          if (JSON.stringify(d.location) === '{}') {
            Object.assign(d.location, this.defaultLocation);
          }
          console.log(d.location);
          const connectedText = d.connected ? 'COMMON_TEXT_ONLINE' : 'COMMON_TEXT_OFFLINE';
          const pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(d.location.latitude, d.location.longitude), {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            width="36" height="44.4" viewBox="0 0 30 37" xml:space="preserve">
            <rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/>
            <polygon fill="{color}" points="10,29 20,29 15,37 10,29"/>
            <text x="15" y="20" style="font-size:10px;-webkit-text-size-adjust:none;font-family:arial;fill:#fff;" text-anchor="middle">
            {text}</text></svg>`,
            anchor: new Microsoft.Maps.Point(18, 44),
            color: d.connected ? '#7db53b' : '#ff0000',
            text: `${this.translate.instant(connectedText)}`
          });

          pin.metadata = d;
          pin.setOptions({ title: d.name });
          Microsoft.Maps.Events.addHandler(pin, 'click', (e: Event) => { this.pushpinClicked(e); });
          pins.push(pin);
        }
      });

      // Create a ClusterLayer with options and add it to the map.
      this.clusterLayer = new Microsoft.Maps.ClusterLayer(pins, {
        clusteredPinCallback: (cluster: any) => {
          this.createCustomClusteredPin(cluster);
          // Add click event to clustered pushpin
          Microsoft.Maps.Events.addHandler(cluster, 'click', (e: Event) => { this.clusterClicked(e); });
        },
        gridSize: 80
      });
      this.map.layers.insert(this.clusterLayer);
    });
  }

  setMapBounds(cluster: any) {
    // array that stores our list of pin locations
    const locations = [];
    let maxLat = -90;
    let minLat = 90;
    let maxLon = -180;
    let minLon = 180;

    // populate the locations array with the passed in pins

    cluster.forEach((d: Device) => {
      const location = d.location;
      if (location) {

        // 如果为空，默认为公司经纬度
        if (JSON.stringify(location) === '{}') {
          Object.assign(location, this.defaultLocation);
        }

        console.log('location', location);

        locations.push(new Microsoft.Maps.Location(location.latitude, location.longitude));
        // update max lat/long values
        if (location.latitude > maxLat) { maxLat = location.latitude; }
        if (location.latitude < minLat) { minLat = location.latitude; }
        if (location.longitude > maxLon) { maxLon = location.longitude; }
        if (location.longitude < minLon) { minLon = location.longitude; }
      }
    });

    // add 2 locations that push the bounding box out by a % of its size
    const pctBuffer = 0.05;
    const latBuffer = (maxLat - minLat) * pctBuffer;
    const lonBuffer = (maxLon - minLon) * pctBuffer;

    // add the two fakes pins to our location array
    locations.push(new Microsoft.Maps.Location(minLat - latBuffer, maxLon + lonBuffer));
    locations.push(new Microsoft.Maps.Location(maxLat + latBuffer, minLon - lonBuffer));

    // create a bounding box based on our location array
    const bounds = Microsoft.Maps.LocationRect.fromLocations(locations);

    // set the map view to our bounding box
    this.map.setView({ bounds });
  }

  createCustomClusteredPin(cluster: any) {
    // Define variables for minimum cluster radius, and how wide the outline area of the circle should be.
    const minRadius = 12;
    const outlineWidth = 7;
    // Get the number of pushpins in the cluster
    const clusterSize = cluster.containedPushpins.length;
    // Calculate the radius of the cluster based on the number of pushpins in the cluster, using a logarithmic scale.
    const radius = Math.log(clusterSize) / Math.log(10) * 5 + minRadius;
    // Default cluster color is red.
    let fillColor = 'rgba(255, 40, 40, 0.5)';
    if (clusterSize < 10) {
      // Make the cluster green if there are less than 10 pushpins in it.
      fillColor = 'rgba(20, 180, 20, 0.5)';
    } else if (clusterSize < 100) {
      // Make the cluster yellow if there are 10 to 99 pushpins in it.
      fillColor = 'rgba(255, 210, 40, 0.5)';
    }
    // Create an SVG string of two circles, one on top of the other, with the specified radius and color.
    const svg = ['<svg xmlns="http://www.w3.org/2000/svg" width="', (radius * 2), '" height="', (radius * 2), '">',
      '<circle cx="', radius, '" cy="', radius, '" r="', radius, '" fill="', fillColor, '"/>',
      '<circle cx="', radius, '" cy="', radius, '" r="', radius - outlineWidth, '" fill="', fillColor, '"/>',
      '</svg>'];
    // Customize the clustered pushpin using the generated SVG and anchor on its center.
    cluster.setOptions({
      icon: svg.join(''),
      anchor: new Microsoft.Maps.Point(radius, radius),
      textOffset: new Microsoft.Maps.Point(0, radius - 8) // Subtract 8 to compensate for height of text.
    });
  }

  pushpinClicked(e: any) {
    // Make sure the infobox has metadata to display.
    if (e.target.metadata) {
      // Set the infobox options with the metadata of the pushpin.
      this.infoBox.setOptions({
        location: e.target.getLocation(),
        title: e.target.metadata.name,
        description: e.target.metadata.serial_number,
        visible: true
      });
    }
  }

  clusterClicked(e: any) {
    if (e.target.containedPushpins) {
      const locs = [];
      for (let i = 0, len = e.target.containedPushpins.length; i < len; i++) {
        // Get the location of each pushpin.
        locs.push(e.target.containedPushpins[i].getLocation());
      }

      // Create a bounding box for the pushpins.
      const bounds = Microsoft.Maps.LocationRect.fromLocations(locs);

      // Zoom into the bounding box of the cluster.
      // Add a padding to compensate for the pixel area of the pushpins.
      this.map.setView({ bounds, padding: 100 });
    }
  }

}
