import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';

import { MaterialModule } from './material.module';
import { TranslateLazyModule } from './translate-lazy.module';

import { NexEditorModule, NexPlayerModule } from '@nexnovo/nex-editor';
import { FileUploadModule } from 'ng2-file-upload';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { CalendarComponent } from './components/calendar/calendar.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { BingMapComponent } from './components/maps/bing-map/bing-map.component';
import { TreetableComponent } from './components/treetable/treetable.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SpritePlayerComponent } from './components/player/sprite-player.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { EmptyDataComponent } from './components/empty-data/empty-data.component';
import { DepartmentSelectorComponent } from './components/department-selector/department-selector.component';
import { FullscreenComponent } from './components/fullscreen/fullscreen.component';
import { PreviewerComponent } from './components/previewer/previewer.component';
import { VideoPlayerComponent } from './components/player/video-player.component';
import { DiyLoadingComponent } from './components/loading/diy-loading.component';
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';

import { DebounceClickDirective } from './directives/debounce-click.directive';
import { CheckImageDirective } from './directives/check-image.directive';
import { CancelCdkDragDirective } from './directives/cancel-drag.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';

import { ConvertBoolPipe } from './pipes/convert-bool.pipe';
import { DurationFilterPipe } from './pipes/duration-filter.pipe';
import { FilesizePipe } from './pipes/filesize.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { FormatNumberPipe } from './pipes/format-num.pipe';
import {
  EsGalleryContentTypeTextPipe,
  GalleryTypeTextPipe,
  GrantTypeTextPipe,
  ScreenOrientationTextPipe,
  ScreenTypeTextPipe,
  TemplateTypeTextPipe,
  DeviceTypeTextPipe,
  SocialAccountTypeTextPipe,
  DirectionTextPipe,
  SupportTypeTextPipe,
  SupportStatusTextPipe,
} from './pipes/type.pipe';
import { TrustHtmlPipe } from './pipes/trust-html.pipe';

export { Node, Options } from './components/treetable/models';

const THIRDMODULES = [
  FileUploadModule,
  NexEditorModule,
  NexPlayerModule,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatMomentModule,
  MatMomentDateModule,
  EditorModule,
];

const COMPONENTS = [
  CalendarComponent,
  BingMapComponent,
  TreetableComponent,
  ImageViewerComponent,
  LoadingComponent,
  SpritePlayerComponent,
  BreadcrumbComponent,
  ConfirmComponent,
  SnackbarComponent,
  EmptyDataComponent,
  DepartmentSelectorComponent,
  FullscreenComponent,
  VideoPlayerComponent,
  PreviewerComponent,
  DiyLoadingComponent,
  ColorSelectorComponent,
];

const DIRECTIVES = [DebounceClickDirective, CheckImageDirective, CancelCdkDragDirective, InfiniteScrollDirective];

const PIPES = [
  ConvertBoolPipe,
  DurationFilterPipe,
  FilesizePipe,
  TemplateTypeTextPipe,
  GalleryTypeTextPipe,
  EsGalleryContentTypeTextPipe,
  ScreenOrientationTextPipe,
  ScreenTypeTextPipe,
  GrantTypeTextPipe,
  TruncatePipe,
  FormatNumberPipe,
  DeviceTypeTextPipe,
  SocialAccountTypeTextPipe,
  DirectionTextPipe,
  SupportTypeTextPipe,
  SupportStatusTextPipe,
  TrustHtmlPipe,
];

const userLang = (navigator.languages ? navigator.languages[0] : navigator.language) || 'en-US';
const NEX_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const providers = [
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: NGX_MAT_DATE_FORMATS, useValue: NEX_DATE_FORMATS },
  { provide: MAT_DATE_LOCALE, useValue: userLang },
  { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    ...THIRDMODULES,
    TranslateLazyModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    ...THIRDMODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    TranslateLazyModule,
  ],
  providers,
})
export class SharedModule {
  static forRoot() {
    return { ngModule: SharedModule, providers: [...providers] };
  }
}
