import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import compact from 'lodash-es/compact';

@Component({
  selector: 'nex-video-player',
  template: `
    <div class="nex-video-player" [style.padding-bottom]="paddingStr">
      <video [autoplay]="autoplay" [loop]="loop" [controls]="controls" [poster]="poster" [muted]="muted">
        <source *ngFor="let item of sources" [src]="item.src" [type]="item.type" />
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
    </div>
  `,
  styles: [
    `
      .nex-video-player {
        width: 100%;
        position: relative;
        padding-bottom: 133.33%;
        height: 0;
      }
      .nex-video-player video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        outline: 0;
        background: #000;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent implements OnInit {
  @Input() poster: string;
  @Input() controls = true;
  @Input() autoplay = true;
  @Input() muted = true;
  @Input() loop = true;
  @Input() sources: Array<{ src: string; type: MimeType }>;

  paddingStr = '133.33%'; // 3:4

  constructor() {}

  @Input() set ratio(value: string) {
    if (value && value.indexOf(':') > -1) {
      const arr = compact(value.split(':'));
      const first = Number.parseInt(arr[0], 10);
      const second = Number.parseInt(arr[1], 10);
      this.paddingStr = ((second / first) * 100).toFixed(2) + '%';
    }
  }

  ngOnInit(): void {}
}
