import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AlertService,
  AuthService,
  ErrorMatcher,
  Identify,
  ResponseCode,
  RestService,
  ServerResponse,
  UtilsService,
} from '@core';
import { GlobalVariable } from '@global';
import { AsyncPasswordValidator } from '@shared/validators/async-password.validator';
import { ValidateConfirmPassword } from '@shared/validators/password.validator';

@Component({
  selector: 'nex-password-dialog',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  pwdMin = GlobalVariable.REG_USER_PWD_MIN;
  pwdMax = GlobalVariable.REG_USER_PWD_MAX;
  user: Identify;
  passwordForm: FormGroup;
  matcher = new ErrorMatcher();
  passwordRuleKey: string;

  constructor(
    private rest: RestService,
    private utils: UtilsService,
    private fb: FormBuilder,
    private auth: AuthService,
    private alert: AlertService,
  ) {}

  ngOnInit() {
    this.user = this.auth.getUser();
    this.passwordRuleKey = this.user.passwordRuleKey;

    this.passwordForm = this.fb.group(
      {
        oldPwd: ['', [Validators.required]],
        password: [
          '',
          [Validators.minLength(this.pwdMin), Validators.maxLength(this.pwdMax)],
          AsyncPasswordValidator.createValidator(this.auth, this.user._id),
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: ValidateConfirmPassword.bind(this) },
    );
  }

  onSubmitPassword() {
    if (this.passwordForm.invalid) {
      return;
    }
    const passwordData = {
      oldPwd: this.passwordForm.value.oldPwd || '',
      newPwd: this.passwordForm.value.password,
    };
    this.utils.showSpinner(true);
    this.rest.putJson('v3/user/changepassword', passwordData).subscribe(
      (res: ServerResponse) => {
        this.utils.showSpinner(false);
        if (res.code === ResponseCode.Warning) {
          return;
        }
        this.alert.alertSuccess('USER_CHANGE_PASSWORD_SUCCESS');
        this.finishLogout();
      },
      () => {
        this.utils.showSpinner(false);
        this.alert.alertFailure();
      },
    );
  }

  finishLogout() {
    this.auth.finishLogout();
  }

  getControl(controlName: string) {
    return this.passwordForm.get(controlName) as FormControl;
  }

  getPasswordErrorMessage(): string {
    const passwordCtrl = this.getControl('password');
    if (passwordCtrl.hasError('required')) {
      return 'COMMON_TEXT_REQUIRED';
    }
    if (passwordCtrl.hasError('minlength') || passwordCtrl.hasError('maxlength')) {
      return 'COMMON_TEXT_LENGTH_INCORRECT';
    }
    if (passwordCtrl.hasError('asyncPassword')) {
      return this.passwordRuleKey;
    }
    return '';
  }
}
