import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'nex-empty-data',
  template: `
    <div class="empty-data">
      <mat-icon class="mat-24">error_outline</mat-icon>&nbsp;<span> {{ i18nText | translate }} </span>
    </div>
  `,
  styleUrls: ['./empty-data.component.scss'],
})
export class EmptyDataComponent implements OnInit {
  @Input() i18nText = 'COMMON_TEXT_NO_DATA';

  constructor() {}

  ngOnInit(): void {}
}
