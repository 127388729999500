<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [class.activate]="node._id === selectedNode?._id"
    (click)="selectNode($event, node)">
    <button mat-icon-button disabled></button>
    <div class="node">
      <div>{{ node.name }}</div>
      <div style="width: 24px;">
        <mat-icon color="primary" class="mat-24" *ngIf="node._id === selectedNode?._id">done</mat-icon>
      </div>
    </div>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
    [class.activate]="node._id === selectedNode?._id" (click)="selectNode($event, node)">
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <div class="node">
      <div>{{ node.name }}</div>
      <mat-icon color="primary" class="mat-24" *ngIf="node._id === selectedNode?._id">done</mat-icon>
    </div>
  </mat-tree-node>
</mat-tree>
