import { DOCUMENT } from '@angular/common';
import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { PropertymapService, ResponseCode, RestService, StorageService, Option } from '@core';

@Component({
  selector: 'header-mode',
  template: `
    <button mat-button [matMenuTriggerFor]="deviceMode">
      <mat-icon>screen_rotation</mat-icon>
      <span fxHide.xs class="nav-caption"> {{ getSelectedMode().text || 'MENU_DEVICE_MODE' | translate }} </span>
    </button>
    <mat-menu #deviceMode="matMenu">
      <button mat-menu-item *ngFor="let mode of modeOptions" (click)="onModeClick(mode.value)">
        <img [src]="mode.icon" [width]="mode.width" style="vertical-align: middle;" /> &nbsp;
        <span>{{ mode.text | translate }}</span>
      </button>
    </mat-menu>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderModeComponent {
  modeOptions = [];

  constructor(
    @Inject(DOCUMENT) private doc: any,
    private propertymap: PropertymapService,
    private storage: StorageService,
    private rest: RestService,
  ) {
    this.modeOptions = this.propertymap.getDeviceTypeOptions();
  }

  getSelectedMode(): Option {
    return this.modeOptions.find((f) => f.value === this.storage.user.deviceType);
  }

  onModeClick(mode: number) {
    if (mode === this.storage.user.deviceType) {
      return;
    }
    this.rest.putJson('v3/user/devicetype', { deviceType: mode, screenOrientation: 1 }).subscribe((res) => {
      if (res.code === ResponseCode.Success) {
        setTimeout(() => this.doc.location.reload());
      }
    });
  }
}
