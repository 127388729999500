import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ResponseCode, UtilsService } from '@core';
import { TranslateService } from '@ngx-translate/core';

import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
    private alert: AlertService,
    private utils: UtilsService,
    private dialogRef: MatDialog,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const token: string = this.rest.getToken();

    // if (token) {
    //   request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    // }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    // }

    // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->', event);
          if (event.status === 200 && event.body.code === ResponseCode.Warning) {
            let msgCode = event.body.msgCode;
            let delay = 3000;

            // 设备信息变更跳转
            const deviceChangeCode = 'MSG_NUMBER_OF_SCREENS_HAS_CHANGED';
            if (msgCode === deviceChangeCode && !this.auth.getDeviceStatus()) {
              this.auth.setDeviceStatus(true);
              this.utils.showConfirm(msgCode, 'COMMON_TEXT_TITLE_INFO', '', 'COMMON_TEXT_GET_IT').subscribe((ok) => {
                if (ok) {
                  this.auth.setUrl('/');
                  this.auth.identify();
                }
              });
              return event;
            }

            const passwordCode = 'MSG_ACCOUNT_DISABLED_WRONG_PASSWORD';
            if (msgCode === passwordCode) {
              const lang = this.utils.getSelectedLang().code;
              const time = event.body.msg;//new Date(parseInt(event.body.msg, 10)).toLocaleString(lang, { hour12: false });
              msgCode = this.translate.instant(passwordCode, { time });
              delay = 5000;
            }

            // 同一个页面会有多个请求，只拦截了第一个，其他会流出
            if (msgCode !== deviceChangeCode) {
              this.alert.alertWarning(msgCode, delay);
            }

            if (msgCode === 'MSG_PASSWORD_EXPIRE') {
              this.auth.identify();
              return event;
            }

            // 如果没有权限，跳转到首页
            if (msgCode === 'MSG_PERMISSION_DENIED') {
              this.dialogRef.closeAll();
              this.router.navigate(['/']);
            }

            const codes = ['MSG_TOKEN_INVALID', 'MSG_TOKEN_EXPIRED', 'MSG_TOKEN_ISEMPTY', passwordCode];
            if (codes.indexOf(event.body.msgCode) >= 0) {
              this.auth.finishLogout();
            }
          }
        }
        return event;
      }),
    );
  }
}
