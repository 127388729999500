import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import * as screenfull from 'screenfull';

@Component({
  selector: 'nex-fullscreen',
  template: `
    <button
      mat-icon-button
      [matTooltip]="(status ? 'BUTTON_EXIT_FULLSCREEN' : 'BUTTON_FULLSCREEN') | translate"
      matTooltipPosition="before"
    >
      <mat-icon class="mat-24">{{ status ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullscreenComponent {
  status = false;
  private get sf(): screenfull.Screenfull {
    return screenfull as screenfull.Screenfull;
  }

  @HostListener('window:resize')
  _resize() {
    this.status = this.sf.isFullscreen;
  }

  @HostListener('click')
  _click() {
    if (this.sf.isEnabled) {
      this.sf.toggle();
    }
  }
}
