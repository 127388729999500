import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Language, UtilsService, AuthService } from '@core';

@Component({
  selector: 'header-i18n',
  template: `
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>language</mat-icon>
      <span fxHide.xs class="nav-caption"> {{ selectedLang?.title || ('APP_LANGUAGES' | translate) }}</span>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let item of languages" (click)="onSelectLanguage(item)">
        <span>{{ item.title }}</span>
      </button>
    </mat-menu>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderI18nComponent {
  get languages() {
    return this.utils.getLanguages();
  }

  get selectedLang() {
    return this.utils.getSelectedLang();
  }

  constructor(private utils: UtilsService, private auth: AuthService) {}

  onSelectLanguage(item: Language) {
    this.utils.setLanguage(item.code);
    if (this.auth.isAuthorized()) {
      this.auth.identify();
    }
  }
}
