<h1 mat-dialog-title>{{ dialogI18nTitle }}</h1>

<mat-dialog-content>
  <form [formGroup]="verifyForm" class="form">
    <div class="msg" *ngIf="data.msg">{{ 'VERIFY_TURN_ON_CHECK' | translate: { showType: dialogI18nTitle } }}</div>
    <div class="text-info" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="data.showType !== 'bind'">
      <div class="label">
        <ng-container *ngIf="isVerify">
          {{ 'VERIFY_DIALOG_VERIFY_TEXT' | translate: { type: verifyTypeI18nObj[data.verifyType] | translate } }}
        </ng-container>
        <ng-container *ngIf="data.showType === 'change'">
          {{ 'VERIFY_DIALOG_CHANGE_TEXT' | translate: { type: verifyTypeI18nObj[data.verifyType] | translate } }}
        </ng-container>
      </div>
      <div class="text">{{ data.number }}</div>
    </div>

    <mat-form-field class="full-width" *ngIf="!this.isVerify && isPhone">
      <input matInput formControlName="phone" [placeholder]="'VERIFY_DIALOG_INPUT_PHONE' | translate" required />
      <mat-error *ngIf="getCtrl('phone')?.hasError('required')">
        {{ 'COMMON_TEXT_FIELD_REQUIRED' | translate }}
      </mat-error>
      <mat-error *ngIf="getCtrl('phone')?.hasError('customMobile')">
        {{ 'INVALID_TEXT_MOBILE' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="!this.isVerify && isEmail">
      <input matInput formControlName="email" [placeholder]="'VERIFY_DIALOG_INPUT_EMAIL' | translate" required />
      <mat-error *ngIf="getCtrl('email')?.hasError('required')">
        {{ 'COMMON_TEXT_FIELD_REQUIRED' | translate }}
      </mat-error>
      <mat-error *ngIf="getCtrl('email')?.hasError('email')">
        {{ 'INVALID_TEXT_EMAIL' | translate }}
      </mat-error>
    </mat-form-field>

    <div *ngIf="isNeedVerify" fxLayout fxLayoutAlign="space-between baseline">
      <mat-form-field fxFlex="60%">
        <input matInput formControlName="code" placeholder="{{ 'COMMON_TEXT_VERIFICATION_CODE' | translate }}" required />
        <mat-hint> {{ 'TWO_FACTORY_VERIFICATION_CODE' | translate }} </mat-hint>
        <mat-error *ngIf="getCtrl('code')?.hasError('required')">
          {{ 'LOGIN_TEXT_CAPTCHA' | translate }}
        </mat-error>
        <mat-error *ngIf="getCtrl('code')?.hasError('pattern')">
          {{ 'TWO_FACTORY_VERIFICATION_CODE' | translate }}
        </mat-error>
      </mat-form-field>
      <div fxFlex="37px">
        <img *ngIf="isLoading" style="vertical-align: middle;" src="./assets/img/spinner_37_1s.gif" />
      </div>
      <button
        mat-stroked-button
        (click)="getValidCode($event)"
        [disabled]="isLoading || counter > 0 || getCtrl(this.data.verifyType)?.invalid"
      >
        {{ 'REGISTER_GET_VALIDATE_CODE' | translate }} <span *ngIf="counter > 0">({{ counter }})</span>
      </button>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button matDialogClose>{{ 'BUTTON_CANCEL' | translate }}</button>
  <button mat-raised-button color="primary" [disabled]="verifyForm.invalid" (click)="onSave()">
    {{ 'BUTTON_SAVE' | translate }}
  </button>
</mat-dialog-actions>
