import { Component } from '@angular/core';
import { AuthService, Identify } from '@core';

@Component({
  selector: 'nex-layout-startup',
  template: `
    <div class="container">
      <mat-toolbar color="primary" fxLayout fxLayoutAlign="space-between center">
        <div class="logo">
          <img src="/assets/img/logo.png" alt="nexnovo" />
        </div>
        <div>
          <header-i18n></header-i18n>
          <button mat-button (click)="onLogout()" *ngIf="!loginUser.deviceType">
            <mat-icon class="mat-18">eject</mat-icon> {{ 'APP_LOGOUT' | translate }}
          </button>
        </div>
      </mat-toolbar>
      <div class="wrap">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styleUrls: ['./startup.component.scss'],
})
export class LayoutStartupComponent {
  loginUser: Identify;
  constructor(private auth: AuthService) {
    this.loginUser = auth.getUser();
  }

  onLogout() {
    this.auth.logout();
  }
}
