import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestService } from '@core';
import { MergedFile } from '@core/models/support';

@Component({
  selector: 'nex-file-preview',
  template: `
    <nex-image-viewer
      *ngIf="!isVidedo"
      [source]="pictureSrc"
      [width]="300"
      [height]="400"
      [fullWidth]="true"
      [readonly]="true"
    >
    </nex-image-viewer>
    <video style="width: 100%;outline:none;" *ngIf="isVidedo" #videoFile controls autoplay muted></video>
  `,
})
export class FilePreviewComponent implements AfterViewInit {
  @ViewChild('videoFile') videoFile: ElementRef;

  pictureSrc: any;
  isFile: boolean;
  isVidedo: boolean;
  videoElement: HTMLVideoElement;

  constructor(@Inject(MAT_DIALOG_DATA) public data: File | MergedFile, public rest: RestService) {
    this.isFile = data instanceof File;
    this.pictureSrc = this.isFile ? data : this.rest.caseImage((this.data as MergedFile).filename);
    this.isVidedo = (this.isFile && data.type.includes('video')) || data.type === 'video';
  }

  ngAfterViewInit(): void {
    if (!this.isVidedo) {
      return;
    }
    this.videoElement = this.videoFile.nativeElement;

    if (this.isFile) {
      if (this.videoElement.canPlayType(this.data.type)) {
        const URL = window.URL || window.webkitURL;
        this.videoElement.src = URL.createObjectURL(this.data);
      }
    } else {
      this.videoElement.src = this.rest.caseVideo((this.data as MergedFile).filename);
    }
  }
}
