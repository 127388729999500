
import { AbstractControl, FormGroup } from '@angular/forms';

// AP的密码规则
export function ValidateApPassword(control: AbstractControl) {
  if (!control.value) { return null; }

  const regExp = /^[A-Za-z0-9]+$/;
  // const isCorrect = regExp.test(control.value) && control.value !== '12345678';
  const isCorrect = regExp.test(control.value);

  return isCorrect ? null : { appassword: true };
}

// 用户注册、登录、修改的密码规则
export function ValidateUserPassword(control: AbstractControl) {

  const regExp = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/; // 8到16位数字字母符号组成的字符串
  const isCorrect = regExp.test(control.value) && !/\s/.test(control.value);

  return isCorrect ? null : { userPassword: true };
}

// 两次密码是否一致
export function ValidateConfirmPassword(group: FormGroup) {
  const pass = group.controls.password.value;
  const confirmPass = group.controls.confirmPassword.value;

  return pass === confirmPass ? null : { notSame: true };
}
