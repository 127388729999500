<nex-video-player
  *ngIf="showType === PreviewType.Video"
  class="video-player"
  ratio="3:4"
  [poster]="videoPoster"
  [sources]="videoSources"
></nex-video-player>

<ng-container *ngIf="showType === PreviewType.Diy">
  <div class="diy-player" (mouseenter)="mouseEnter = true" (mouseleave)="mouseEnter = false">
    <nex-player
      #diyPlayer
      [scale]="scale"
      [slides]="slides"
      [screenWidth]="screenWidth"
      [screenHeight]="screenHeight"
      [showProgress]="true"
      (progressEvent)="onProgress($event)"
      (events)="onEvents($event)"
    >
    </nex-player>
    <nex-loading-diy [loadingText]="loadingProgress" *ngIf="!ready"></nex-loading-diy>
    <mat-icon class="btnplay" (click)="onPlayStateClick()" *ngIf="(ready && !playing) || (playing && mouseEnter)">
      {{ playing ? 'pause' : 'play_arrow' }}
    </mat-icon>
  </div>
</ng-container>

<nex-image-viewer
  *ngIf="showType === PreviewType.Font"
  [source]="fontPreviewImage"
  [fullWidth]="true"
  [height]="120"
  [readonly]="true"
  background="#fff"
>
</nex-image-viewer>

<nex-sprite-player
  *ngIf="showType === PreviewType.Animate && spriteData"
  [source]="spriteSrc"
  [sprite]="spriteData"
  [width]="spriteData.width"
  [height]="spriteData.height"
  [canvasMaxWidth]="spriteData.width"
>
</nex-sprite-player>
