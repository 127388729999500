import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DepartmentNode, DepartmentFlatNode } from '@core';

@Component({
  selector: 'nex-department-selector',
  templateUrl: './department-selector.component.html',
  styleUrls: ['./department-selector.component.scss'],
})
export class DepartmentSelectorComponent {
  @Input() selectedNode?: DepartmentFlatNode;
  @Output() nodeClick = new EventEmitter();

  treeControl = new FlatTreeControl<DepartmentFlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  @Input('treeData')
  set setTreeData(data: DepartmentNode[]) {
    if (data && data.length) {
      this.dataSource.data = data || [];
      this.treeControl.expandAll();
    }
  }

  hasChild = (_: number, node: DepartmentFlatNode) => node.expandable;

  selectNode(event: Event, node: DepartmentFlatNode) {
    event.preventDefault();
    this.selectedNode = node;
    this.nodeClick.emit(this.selectedNode);
  }

  private _transformer(node: DepartmentNode, level: number) {
    return {
      _id: node._id,
      name: node.name,
      level,
      expandable: !!node.children && node.children.length > 0,
    };
  }
}
