import { timer } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, ErrorMatcher, ProtocolType, ResponseCode, RestService, UtilsService } from '@core';
import { GlobalVariable } from '@global';
import { TranslateService } from '@ngx-translate/core';
import { ValidateMobile } from '@shared/validators/common.validator';
import { ValidateConfirmPassword, ValidateUserPassword } from '@shared/validators/password.validator';
import { ValidateUsername } from '@shared/validators/username.validator';

import { CountryDialogComponent } from './country-dialog.component';
import { PolicyDialogComponent } from './policy-dialog.component';

@Component({
  selector: 'nex-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  pwdMin = GlobalVariable.REG_USER_PWD_MIN;
  pwdMax = GlobalVariable.REG_USER_PWD_MAX;
  unameMin = GlobalVariable.REG_USER_NAME_MIN;
  unameMax = GlobalVariable.REG_USER_NAME_MAX;

  signupForm: FormGroup;
  matcher = new ErrorMatcher();
  provision: string[] = [];
  selectedCountry: any;
  counter: number;
  resendTimer: any;
  isLoading = false;

  get protocolType() {
    return ProtocolType;
  }

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private dialog: MatDialog,
    private rest: RestService,
    private router: Router,
    private utils: UtilsService,
    private alert: AlertService,
  ) {
    this.translate.stream('REGISTRATION_AGREEMENT').subscribe((res: string) => {
      const i18n = res || 'I agree to all||terms of service|| and ||privacy policy';
      this.provision = i18n.split('||');
    });
  }

  ngOnInit() {
    this.signupForm = this.fb.group(
      {
        username: [
          '',
          [Validators.required, Validators.minLength(this.unameMin), Validators.maxLength(this.unameMax), ValidateUsername],
        ],
        password: ['', [Validators.minLength(this.pwdMin), Validators.maxLength(this.pwdMax), ValidateUserPassword]],
        confirmPassword: ['', [Validators.required]],
        country: [null, [Validators.required]],
        countryCode: ['', [Validators.required]],
        service: 'nexnovo',
        languageCode: this.utils.getSelectedLang().code,
        policy: [true, [Validators.required]],
      },
      { validator: ValidateConfirmPassword.bind(this) },
    );

    this.signupForm.get('countryCode').valueChanges.subscribe((code) => {
      if (!code) {
        return;
      }
      if (code === 'CN') {
        this.signupForm.removeControl('email');
        this.signupForm.addControl('phone', new FormControl('', [Validators.required, ValidateMobile]));
      } else {
        this.signupForm.removeControl('phone');
        this.signupForm.addControl('email', new FormControl('', [Validators.required, Validators.email]));
      }
      const codeCtrl = this.signupForm.get('code');
      if (!codeCtrl) {
        this.signupForm.addControl('code', new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,6}$')]));
      } else {
        codeCtrl.reset();
      }
    });
  }

  ngOnDestroy() {
    if (this.resendTimer) {
      window.clearInterval(this.resendTimer);
    }
  }

  openCountryDialog(event: Event) {
    event.preventDefault();

    const dialogRef = this.dialog.open(CountryDialogComponent, {
      width: '50vw',
      minWidth: '320px',
      maxWidth: '500px',
      disableClose: true,
      data: this.selectedCountry,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedCountry = result;
        this.getControl('country').setValue(`${result.country}(${result.nativeName})`);
        this.getControl('countryCode').setValue(result.acronym);
      }
    });
  }

  getVerifyCode(event) {
    event.preventDefault();
    const isCN = this.selectedCountry && this.selectedCountry.acronym === 'CN';
    const verifyType = isCN ? GlobalVariable.VERIFY_TYPE_PHONE : GlobalVariable.VERIFY_TYPE_EMAIL;
    if (this.getControl(verifyType).invalid) {
      return;
    }
    const _url = 'v3/auth/getverifycode/register';
    const _params = {
      type: verifyType,
      number: this.getControl(verifyType).value,
      lang: this.utils.getSelectedLang().code,
    };
    this.isLoading = true;
    timer(800)
      .pipe(switchMap(() => this.rest.postJson(_url, _params).pipe(finalize(() => (this.isLoading = false)))))
      .subscribe((res) => {
        if (res.code === ResponseCode.Success) {
          this.counter = GlobalVariable.VERIFY_COUNTER;
          this.resendTimer = window.setInterval(() => {
            if (this.counter > 0) {
              this.counter--;
            } else {
              window.clearInterval(this.resendTimer);
            }
          }, 1000);
        }
      });
  }

  showPolicy(event: Event, type: string) {
    event.preventDefault();
    if (!type) {
      return;
    }
    const dialogRef = this.dialog.open(PolicyDialogComponent, {
      width: '50vw',
      minWidth: '320px',
      maxWidth: '500px',
      disableClose: true,
      data: type,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.signupForm.get('policy').setValue(result);
    });
  }

  signup() {
    if (this.signupForm.invalid) {
      return;
    }
    const registerData = JSON.parse(JSON.stringify(this.signupForm.value));
    delete registerData.country;
    delete registerData.policy;
    this.rest.setVersion(2);
    this.utils.showSpinner(true);
    this.rest.postJson('v3/user/register', registerData).subscribe(
      (res) => {
        this.utils.showSpinner(false);
        this.rest.setVersion(0);
        if (res.code === ResponseCode.Success) {
          this.alert.alertSuccess('MSG_USER_REGISTER_SUCCESS');
          this.router.navigate(['/passport/login']);
        }
      },
      () => {
        this.utils.showSpinner(false);
        this.alert.alertWarning('MSG_USER_REGISTER_FAILED');
      },
    );
  }

  getControl(controlName: string) {
    return this.signupForm.get(controlName) as FormControl;
  }

  getUsernameErrorMessage(): string {
    const usernameCtrl = this.getControl('username');
    if (usernameCtrl.hasError('required')) {
      return 'COMMON_TEXT_REQUIRED';
    }

    if (usernameCtrl.hasError('minlength') || usernameCtrl.hasError('maxlength') || usernameCtrl.hasError('username')) {
      return 'USER_HINT_USERNAME';
    }
    return '';
  }

  getPasswordErrorMessage(): string {
    const passwordCtrl = this.getControl('password');
    if (passwordCtrl.hasError('required')) {
      return 'COMMON_TEXT_REQUIRED';
    }
    if (passwordCtrl.hasError('minlength') || passwordCtrl.hasError('maxlength') || passwordCtrl.hasError('userPassword')) {
      return 'USER_HINT_PASSWORD';
    }
    return '';
  }
}
