import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PropertymapService, ScreenOrientation } from '@core';

@Component({
  selector: 'nex-screen-selector',
  templateUrl: './screen-selector.component.html',
  styleUrls: ['./screen-selector.component.scss'],
})
export class ScreenSelectorComponent {
  orientationOptions = [];
  countOptions = [];
  Orientation = ScreenOrientation;

  selectedType: number;
  selectedCount: number;

  get isDone() {
    return this.selectedType && this.selectedCount;
  }

  constructor(private dialogRef: MatDialogRef<ScreenSelectorComponent>, private propertymap: PropertymapService) {
    this.orientationOptions = this.propertymap.getUserScreenOrientationOptions();
    this.selectedType = this.propertymap.getUserEsDefault().orientation;
    this.countOptions = this.propertymap.getUserScreenCountOptions(this.selectedType);
  }

  onSelectedIndexChange(index: number) {
    this.selectedCount = undefined;
    this.selectedType = this.orientationOptions[index].value;
    this.countOptions = this.propertymap.getUserScreenCountOptions(this.selectedType);
  }

  done() {
    if (this.isDone) {
      this.dialogRef.close({ screenOrientation: this.selectedType, screenCount: this.selectedCount });
    }
  }
}
