import { timer } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AlertService,
  AuthService,
  ErrorMatcher,
  ProtocolType,
  ResponseCode,
  RestService,
  StorageService,
  UtilsService,
} from '@core';
import { environment } from '@env';
import { GlobalVariable } from '@global';
import { TranslateService } from '@ngx-translate/core';
import { SocialAccountTypeTextPipe } from '@shared/pipes/type.pipe';
import { ValidateMobile } from '@shared/validators/common.validator';
import { ValidateConfirmPassword, ValidateUserPassword } from '@shared/validators/password.validator';
import { ValidateUsername } from '@shared/validators/username.validator';

import { CountryDialogComponent } from '../signup/country-dialog.component';
import { PolicyDialogComponent } from '../signup/policy-dialog.component';

const XTOKEN = 'xtoken';

@Component({
  selector: 'nex-bind',
  templateUrl: './bind.component.html',
  styleUrls: ['./bind.component.scss'],
  providers: [SocialAccountTypeTextPipe],
})
export class BindComponent implements OnInit, OnDestroy {
  pwdMin = GlobalVariable.REG_USER_PWD_MIN;
  pwdMax = GlobalVariable.REG_USER_PWD_MAX;
  unameMin = GlobalVariable.REG_USER_NAME_MIN;
  unameMax = GlobalVariable.REG_USER_NAME_MAX;

  isAuthorized = true;
  certifyData: any;
  identityTypeText: string;

  hide = true;
  signinForm: FormGroup;
  signupForm: FormGroup;

  matcher = new ErrorMatcher();
  provision: string[] = [];

  initTimestamp: number;

  selectedCountry: any;
  isLoading = false;
  counter: number;
  resendTimer: any;

  get protocolType() {
    return ProtocolType;
  }

  constructor(
    private rest: RestService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private fb: FormBuilder,
    private auth: AuthService,
    private alert: AlertService,
    private utils: UtilsService,
    private router: Router,
    private storage: StorageService,
    private socialAccType: SocialAccountTypeTextPipe,
  ) {
    this.translate.stream('REGISTRATION_AGREEMENT').subscribe((res: string) => {
      const i18n = res || 'I agree to all||terms of service|| and ||privacy policy';
      this.provision = i18n.split('||');
    });
  }

  ngOnInit() {
    this.initTimestamp = Date.now();
    const identityType = Number.parseInt(`${this.rest.getQueryString('type')}`, 10);
    this.socialAccType.transform(identityType).subscribe((value) => {
      this.identityTypeText = value;
    });

    this.getWechatOauth({
      code: this.rest.getQueryString('code'),
      identityType,
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
      state: this.rest.getQueryString('state'),
    }).subscribe((res) => {
      console.log('oauth result:', res);
      if (!(res && res.code === ResponseCode.Success && res.data)) {
        this.router.navigate(['/passport/login']);
        return;
      }

      this.certifyData = res.data;

      if (this.certifyData.status === 1) {
        this.initForm();
      }

      if (Date.now() - this.initTimestamp < 3000) {
        const timeoutId = window.setTimeout(() => {
          // 未绑定
          if (this.certifyData.status === 1) {
            this.isAuthorized = false;
          }

          // 已绑定
          if (this.certifyData.status === 2 && this.certifyData.access_token) {
            this.passAuth(this.certifyData, false);
          }

          window.clearTimeout(timeoutId);
        }, 3000);
      }
    });
  }

  ngOnDestroy() {
    if (this.resendTimer) {
      window.clearInterval(this.resendTimer);
    }
  }

  initForm() {
    this.signinForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.signupForm = this.fb.group(
      {
        username: [
          '',
          [Validators.required, Validators.minLength(this.unameMin), Validators.maxLength(this.unameMax), ValidateUsername],
        ],
        password: ['', [Validators.minLength(this.pwdMin), Validators.maxLength(this.pwdMax), ValidateUserPassword]],
        confirmPassword: ['', [Validators.required]],
        country: [null, [Validators.required]],
        countryCode: ['', [Validators.required]],
        policy: [true, [Validators.required]],
      },
      { validator: ValidateConfirmPassword.bind(this) },
    );

    this.signupForm.get('countryCode').valueChanges.subscribe((code) => {
      if (!code) {
        return;
      }
      if (code === 'CN') {
        this.signupForm.removeControl('email');
        this.signupForm.addControl('phone', new FormControl('', [Validators.required, ValidateMobile]));
      } else {
        this.signupForm.removeControl('phone');
        this.signupForm.addControl('email', new FormControl('', [Validators.required, Validators.email]));
      }
      const codeCtrl = this.signupForm.get('code');
      if (!codeCtrl) {
        this.signupForm.addControl('code', new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,6}$')]));
      } else {
        codeCtrl.reset();
      }
    });
  }

  getVerifyCode(event) {
    event.preventDefault();
    const isCN = this.selectedCountry && this.selectedCountry.acronym === 'CN';
    const verifyType = isCN ? GlobalVariable.VERIFY_TYPE_PHONE : GlobalVariable.VERIFY_TYPE_EMAIL;
    if (this.getControl(verifyType).invalid) {
      return;
    }
    const _url = 'v3/auth/getverifycode/register';
    const _params = {
      type: verifyType,
      number: this.getControl(verifyType).value,
      lang: this.utils.getSelectedLang().code,
    };
    this.isLoading = true;
    timer(800)
      .pipe(switchMap(() => this.rest.postJson(_url, _params).pipe(finalize(() => (this.isLoading = false)))))
      .subscribe((res) => {
        if (res.code === ResponseCode.Success) {
          this.counter = GlobalVariable.VERIFY_COUNTER;
          this.resendTimer = window.setInterval(() => {
            if (this.counter > 0) {
              this.counter--;
            } else {
              window.clearInterval(this.resendTimer);
            }
          }, 1000);
        }
      });
  }

  openCountryDialog(event: Event) {
    event.preventDefault();

    const dialogRef = this.dialog.open(CountryDialogComponent, {
      width: '50vw',
      minWidth: '320px',
      maxWidth: '500px',
      disableClose: true,
      data: this.selectedCountry,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedCountry = result;
        this.getControl('country').setValue(`${result.country}(${result.nativeName})`);
        this.getControl('countryCode').setValue(result.acronym);
      }
    });
  }

  showPolicy(event: Event, type: string) {
    event.preventDefault();
    if (!type) {
      return;
    }
    const dialogRef = this.dialog.open(PolicyDialogComponent, {
      width: '50vw',
      minWidth: '320px',
      maxWidth: '500px',
      disableClose: true,
      data: type,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.signupForm.get('policy').setValue(result);
    });
  }

  // 登录绑定已有账号
  signinBinding() {
    if (!this.certifyData || this.signinForm.invalid) {
      return;
    }

    // this.auth.authChange.subscribe((user) => {
    //   if (user && !user.tokenAccountVerify) {
    //     this.alert.alertSuccess('LOGIN_TEXT_ALERT_SUCCESS');
    //   }
    // });

    const username = this.signinForm.value.username;
    const password = this.signinForm.value.password;

    this.utils.showSpinner(true);
    this.auth.login(username, encodeURI(password), '', this.certifyData.identityId).subscribe(
      (res: any) => {
        this.utils.showSpinner(false);
        if (res && res.access_token) {
          return;
        }
        if (res && res.code === ResponseCode.Warning) {
          this.alert.alertWarning(res.msgCode);
          return;
        }
        this.alert.alertWarning('USER_BIND_FAILED');
      },
      (err: HttpErrorResponse) => {
        console.log('signinBinding error:', err);
        this.utils.showSpinner(false);
        this.alert.alertFailure();
      },
    );
  }

  // 注册并绑定
  signupBinding(type?: string) {
    console.log('this.signupForm', this.signupForm.value);
    if (!this.certifyData) {
      return;
    }
    let postData = {
      languageCode: this.utils.getSelectedLang().code,
      identityId: this.certifyData.identityId,
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    };

    if (type !== 'auto') {
      if (this.signupForm.invalid) {
        return;
      }
      const registerData = JSON.parse(JSON.stringify(this.signupForm.value));

      delete registerData.country;
      delete registerData.policy;

      postData = Object.assign({}, postData, registerData);
    }

    this.rest.setVersion(2);
    this.utils.showSpinner(true);
    this.rest.postJson('v3/user/other/platform/register', postData).subscribe(
      (res) => {
        this.utils.showSpinner(false);
        this.rest.setVersion(0);
        if (res && res.data && res.data.access_token) {
          this.passAuth(res.data, true);
          this.alert.alertSuccess('REGISTER_AND_BIND_SUCCESS');
          return;
        }

        if (res && res.code === ResponseCode.Warning) {
          this.alert.alertWarning(res.msgCode);
          return;
        }

        this.alert.alertWarning('USER_BIND_FAILED');
      },
      (err) => {
        this.utils.showSpinner(false);
      },
    );
  }

  getControl(controlName: string) {
    return this.signupForm.get(controlName) as FormControl;
  }

  getUsernameErrorMessage(): string {
    const usernameCtrl = this.getControl('username');
    if (usernameCtrl.hasError('required')) {
      return 'COMMON_TEXT_REQUIRED';
    }

    if (usernameCtrl.hasError('minlength') || usernameCtrl.hasError('maxlength') || usernameCtrl.hasError('username')) {
      return 'USER_HINT_USERNAME';
    }
    return '';
  }

  getPasswordErrorMessage(): string {
    const passwordCtrl = this.getControl('password');
    if (passwordCtrl.hasError('required')) {
      return 'COMMON_TEXT_REQUIRED';
    }
    if (passwordCtrl.hasError('minlength') || passwordCtrl.hasError('maxlength') || passwordCtrl.hasError('userPassword')) {
      return 'USER_HINT_PASSWORD';
    }
    return '';
  }

  private passAuth(authData: any, isNewUser: boolean) {
    this.storage.set(XTOKEN, {
      access_token: authData.access_token,
      token_type: authData.token_type,
      expires_in: authData.expires_in,
    });

    this.rest.setToken(authData.access_token);

    if (isNewUser) {
      this.auth.setUrl('/account/settings');
    }
    this.auth.identify();
  }

  private getWechatOauth(params: any) {
    return this.rest.postJson('v3/other/oauth', params);
  }
}
