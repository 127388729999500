/**
 * Decorator that sets an Input() propertiy as required, will
 * throw an error if the property is not specified in the template.
 * I.e. @Input() @Required myProp: number;
 */
// tslint:disable-next-line: ban-types
// export function Required(target: Object, property: string): void {
//   Object.defineProperty(target, property, {
//     get() {
//       throw new Error(`Input '${property}' is required. Have you forgotten to add [${property}] = ... in your template?`);
//     },
//     set(value) {
//       Object.defineProperty(target, property, {
//         value,
//         writable: true,
//         configurable: true
//       });
//     },
//   });
// }

// Map of component name -> list of required properties
const requiredInputs = new Map<string, string[]>();

/**
 * Mark @Input() as required.
 *
 * Supports inheritance chains for components.
 *
 * Example:
 *
 * import { Required, checkRequired } from '../requiredInput';
 *
 *  export class MyComp implements OnInit {
 *
 *    // Chain id paramter we check for from the wallet
 *    @Input()
 *    @Required
 *    requiredChainId: number;
 *
 *    ngOnInit(): void {
 *      checkRequired(this);
 *    }
 *  }
 *
 * @param target Object given by the TypeScript decorator
 * @param prop Property name from the TypeScript decorator
 */
export function Required(target: any, prop: string) {
  // Maintain a global table which components require which inputs
  const className = target.constructor.name;
  requiredInputs[className] = requiredInputs[className] || [];
  requiredInputs[className].push(prop);
  // console.log(className, prop, requiredInputs[className]);
}

/**
 * Check that all required inputs are filled.
 */
export function checkRequired(component: any) {
  let className = component.constructor.name;
  let nextParent = Object.getPrototypeOf(component);

  // Walk through the parent class chain
  while (className !== 'Object') {
    for (const prop of requiredInputs[className] || []) {
      const val = component[prop];
      if (val === null || val === undefined) {
        console.error(component.constructor.name, prop, 'is required, but was not provided, actual value is', val);
      }
    }

    className = nextParent.constructor.name;
    nextParent = Object.getPrototypeOf(nextParent);
    // console.log("Checking", component, className);
  }
}
