import { Observable } from 'rxjs';

import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  private apiPath: string;
  private s3Domain: string;
  private token: string;
  private version: number;

  constructor(private http: HttpClient) {
    this.apiPath = environment.apiPath;
    this.s3Domain = environment.s3Domain;
  }

  videoScreensMP4 = (fileid: string) => this.s3Domain + 'screens/' + fileid; // 原视频
  videoPreviewMP4 = (fileid: string) => this.s3Domain + 'thumbs/' + fileid; // 缩略视频
  videoPreviewJPG = (fileid: string) => this.s3Domain + 'thumbs/preview/' + fileid; // 视频封面
  userImagesPath = (fileid: string) => this.s3Domain + 'user/images/' + fileid; // 用户原图
  userImagesPreview = (fileid: string) => this.s3Domain + 'user/preview/' + fileid; // 用户图片封面
  imagesPath = (fileid: string) => this.s3Domain + 'images/' + fileid; // 设计师原图
  imagesPreview = (fileid: string) => this.s3Domain + 'images/preview/' + fileid; // 设计师图片封面
  tempPreviewJPG = (fileid: string) => this.s3Domain + 'temp/preview/' + fileid;
  imagesGif = (fileid: string) => this.s3Domain + 'images/gif/' + fileid; // 设计师gif封面
  fontsZip = (fileid: string) => this.s3Domain + 'fonts/' + fileid; // 设计师字体包
  fontsFiles = (fileid: string) => this.s3Domain + 'fonts/files/' + fileid; // 设计师字体路径
  fontsPreview = (fileid: string) => this.s3Domain + 'fonts/preview/' + fileid; // 设计师字体封面

  caseImage = (filename: string) => this.s3Domain + 'case/image/images/' + filename; // 案例图片原图
  caseImagePreview = (filename: string) => this.s3Domain + 'case/image/preview/' + filename; // 案例缩略图
  caseVideo = (filename: string) => this.s3Domain + 'case/video/videos/' + filename; // 案例原视频
  caseVideoPreview = (filename: string) => this.s3Domain + 'case/video/preview/' + filename; // 案例视频缩略图
  caseVideoThumb = (filename: string) => this.s3Domain + 'case/video/thumbs/' + filename; // 案例缩略视频

  autoImageUrl(fileid: string, hasgif: boolean, ifuser: boolean) {
    if (hasgif) {
      return this.imagesPath(fileid);
    }
    return ifuser ? this.userImagesPath(fileid) : this.imagesPath(fileid);
  }

  urlEncode(obj) {
    let urlSearchParams = '';
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        urlSearchParams += key + '=' + obj[key] + '&';
      }
    }
    return urlSearchParams;
  }

  getQueryString(name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    const r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  }

  setS3Domain(s3Domain: string) {
    this.s3Domain = s3Domain;
  }

  getS3Domain(): string {
    return this.s3Domain;
  }

  getApiPath() {
    return this.apiPath;
  }

  getQueryEncode(obj: any): string {
    let urlParams = '?';
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (urlParams !== '?') {
          urlParams += '&';
        }
        if (typeof obj[key] !== 'object') {
          urlParams += key + '=' + obj[key];
        } else {
          (obj[key] as Array<string>).forEach((value, index) => {
            if (index > 0) {
              urlParams += '&';
            }
            urlParams += key + '=' + value;
          });
        }
      }
    }
    return urlParams;
  }

  serializeObjectToUrlParams(params: any = {}) {
    if (!params || JSON.stringify(params) === '{}') {
      return '';
    }
    const queryString = '?';
    return (
      queryString +
      Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&')
    );
  }

  setToken(token: string) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  setVersion(version: number) {
    this.version = version;
  }

  postFile(resourcePath: string, formData: FormData = null): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    const url = this.apiPath + resourcePath;
    return this.http.post(url, formData, {
      headers,
      observe: 'events',
      reportProgress: true,
      responseType: 'json',
    });
  }

  postForm(resourcePath: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const options = { headers, withCredentials: true };
    const body = data ? this.urlEncode(data) : null;
    return this.http.post(this.apiPath + resourcePath, body, options);
  }

  getJson(resourcePath: string, data: any = null, credentials = true): Observable<any> {
    const requestObject = this.getRequestJSONObject(resourcePath + this.serializeObjectToUrlParams(data));
    const options = { headers: requestObject.headers, withCredentials: credentials };
    return this.http.get(requestObject.url, options);
  }

  deleteJson(resourcePath: string, data: any = null, credentials = true): Observable<any> {
    const body = data ? JSON.stringify(data) : null;
    const requestObject = this.getRequestJSONObject(resourcePath);
    const options = { headers: requestObject.headers, withCredentials: credentials, body };
    return this.http.delete(requestObject.url, options);
  }

  postJson(resourcePath: string, data: any = null, credentials = true): Observable<any> {
    const body = data ? JSON.stringify(data) : null;
    const requestObject = this.getRequestJSONObject(resourcePath);
    const options = { headers: requestObject.headers, withCredentials: credentials };
    return this.http.post(requestObject.url, body, options);
  }

  putJson(resourcePath: string, data: any = null, credentials = true): Observable<any> {
    const body = data ? JSON.stringify(data) : null;
    const requestObject = this.getRequestJSONObject(resourcePath);
    const options = { headers: requestObject.headers, withCredentials: credentials };
    return this.http.put(requestObject.url, body, options);
  }

  private getRequestJSONObject(resource: string) {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + this.token,
    });
    if (this.version) {
      headers = headers.append('version', `${this.version}`);
    }
    return { url: this.apiPath + resource, headers };
  }

  localGet(path: string, t?: number): Observable<any> {
    t = t || 5000;
    return this.http.get(path).pipe(timeout(t));
  }

  localPost(path: string, data: any): Observable<any> {
    const body = data ? JSON.stringify(data) : null;
    return this.http.post(path, body);
  }

  getUploadJSONObject(resource: string, allowedFileType: Array<string>, maxFileSize: number): object {
    return {
      method: 'POST',
      url: this.apiPath + resource,
      allowedFileType,
      maxFileSize,
      headers: [
        { name: 'Content-Type', undefined },
        { name: 'Authorization', value: 'Bearer ' + this.token },
      ],
    };
  }
}
