<div class="month">
  <button mat-stroked-button [disabled]="disabledPrev" (click)="onMonthToggle(-1)" *ngIf="showMonthButton == 'true'">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <div class="month-title">{{ monthTitle }}</div>
  <button mat-stroked-button [disabled]="disabledNext" (click)="onMonthToggle(1)" *ngIf="showMonthButton == 'true'">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
<div class="weeks" *ngIf="showWeek">
  <ul>
    <li *ngFor="let week of weeks">{{ week }}</li>
  </ul>
</div>
<div class="days">
  <ul (touchmove)="touchMove($event)" (touchstart)="touchStart($event)">
    <li
      *ngFor="let item of dayItems; let i = index"
      [id]="item.day"
      (touchend)="touchEnd($event, item.day)"
      [class.disabled]="item.disabled"
    >
      <span
        [class.selected]="item.selected"
        (click)="onClickDay(item, $event)"
        [ngStyle]="{ backgroundColor: item.color, color: item.color ? '#fff' : '#000' }"
      >
        {{ item.day }}
      </span>
    </li>
  </ul>
</div>
