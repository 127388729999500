import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  private eventSource: EventSource;

  constructor(private ngZone: NgZone) { }

  getServerSentEvent(url: string) {

    return new Observable(observer => {
      this.eventSource = new EventSource(url);

      const run = (event: any) => {
        try {
          const body = { type: event.type, data: null };
          if (['open', 'error'].indexOf(event.type) < 0) {
            body.data = JSON.parse(event.data);
          }
          this.ngZone.run(() => {
            observer.next(body);
          });
        } catch (error) {
          console.log(error);
        }
      };

      this.eventSource.onmessage = event => {
        run(event);
      };

      this.eventSource.onerror = error => {
        run(error);
      };

      this.eventSource.onopen = event => {
        run(event);
      };

      // 设备详情：当前播放信息
      this.eventSource.addEventListener('playinfo', (event) => {
        run(event);
      });

      // 设备详情：实时播放信息
      this.eventSource.addEventListener('currentplay', (event) => {
        run(event);
      });

      // 批量设置设备
      this.eventSource.addEventListener('setting', (event) => {
        run(event);
      });

    });
  }

  closeServerSentEvent() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

}
