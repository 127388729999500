import { map } from 'rxjs/operators';

import { AbstractControl } from '@angular/forms';
import { AuthService, ResponseCode } from '@core';

export class CaptchaValidator {
  static createValidator(auth: AuthService) {
    return (control: AbstractControl) => {
      return auth
        .verifyCaptcha(control.value)
        .pipe(map((res: any) => (res.code === ResponseCode.Success ? null : { captcha: true })));
    };
  }
}
