import { AbstractControl } from '@angular/forms';

export function ValidateEmail(inputData: any): any {
  // const reg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
  const reg = /^([\w\-\_\.]+)+@[A-z0-9\-\.]+\.([A-z]{2,6})$/;
  if (inputData && inputData.value) {
    return reg.test((inputData as AbstractControl).value) ? null : { customEmail: true };
  }
  return reg.test(inputData as string);
}

export function ValidateMobile(inputData: any): any {
  const reg = /^1[3-9]\d{9}$/;
  if (inputData && inputData.value) {
    return reg.test((inputData as AbstractControl).value) ? null : { customMobile: true };
  }
  return reg.test(inputData as string);
}
