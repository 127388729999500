import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nex-cover-preview',
  template: `<nex-previewer
    [contentType]="data.type"
    [contentData]="data.item"
    [deviceType]="data.deviceType"
  ></nex-previewer>`,
})
export class CoverPreviewComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
