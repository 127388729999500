import { timer } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, ErrorMatcher, ResponseCode, RestService, ServerResponse } from '@core';
import { GlobalVariable } from '@global';
import { TranslateService } from '@ngx-translate/core';
import { ValidateConfirmPassword } from '@shared/validators/password.validator';

@Component({
  selector: 'nex-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnDestroy {
  stage = 0;
  stages = ['FORGET_PWD_STAGE_ONE', 'FORGET_PWD_STAGE_TWO', 'FORGET_PWD_STAGE_THREE', 'FORGET_PWD_STAGE_FOUR'];
  isLoadingNext = false;
  trustedUser: any;
  verifyTypeTextObj = {
    phone: 'USER_TEXT_PHONE',
    email: 'USER_TEXT_EMAIL',
  };

  usernameCtrl: FormControl = new FormControl('', Validators.required);
  numberCtrl: FormControl = new FormControl('', Validators.required);
  codeCtrl: FormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,6}$')]);

  pwdMin = GlobalVariable.REG_USER_PWD_MIN;
  pwdMax = GlobalVariable.REG_USER_PWD_MAX;
  matcher = new ErrorMatcher();
  passwordForm: FormGroup;
  isDone = false;
  counter: number;
  jumpTimer: any;

  constructor(
    private fb: FormBuilder,
    private alert: AlertService,
    private rest: RestService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  ngOnDestroy() {
    if (this.jumpTimer) {
      window.clearInterval(this.jumpTimer);
    }
  }

  get passwordRuleKey() {
    return this.trustedUser && this.trustedUser.passwordRuleKey ? this.trustedUser.passwordRuleKey : 'PASSWORD_RULES_DEFAULT';
  }

  get verifyTypeText() {
    if (this.trustedUser && this.trustedUser.verifyType) {
      return this.translate.instant(this.verifyTypeTextObj[this.trustedUser.verifyType.toLowerCase()]);
    }
    return '';
  }

  get hasNumber() {
    return this.trustedUser && this.trustedUser.number;
  }

  verifyData(url: string, params: any) {
    this.isLoadingNext = true;
    return timer(1000).pipe(switchMap(() => this.rest.postJson(url, params).pipe(finalize(() => (this.isLoadingNext = false)))));
  }

  createPwdForm() {
    return this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(this.pwdMin), Validators.maxLength(this.pwdMax)]],
        confirmPassword: ['', Validators.required],
      },
      { validator: ValidateConfirmPassword.bind(this) },
    );
  }

  nextStep() {
    if (this.stage === 0) {
      // 核对账户
      if (this.usernameCtrl.invalid) {
        this.usernameCtrl.markAsTouched({ onlySelf: true });
        return;
      }
      const _url = 'v3/forgetpassword';
      const _params = { username: this.usernameCtrl.value };
      this.verifyData(_url, _params).subscribe(
        (res: ServerResponse) => {
          if (res.code === ResponseCode.Warning) {
            return;
          }
          if (!res.data) {
            this.usernameCtrl.setErrors({ invalidUser: true });
            this.usernameCtrl.markAsTouched({ onlySelf: true });
            return;
          }
          this.trustedUser = res.data;
          this.stage++;
        },
        (err) => {
          console.log('verify username error:', err);
          this.alert.alertFailure();
        },
      );
    } else if (this.stage === 1) {
      // 核对手机
      if (this.numberCtrl.invalid) {
        this.numberCtrl.markAsTouched({ onlySelf: true });
        return;
      }
      const _url = 'v3/forgetpassword/checknumber';
      const _params = { userId: this.trustedUser.userId, number: this.numberCtrl.value };
      this.verifyData(_url, _params).subscribe(
        (res: ServerResponse) => {
          if (res.code === ResponseCode.Warning) {
            return;
          }
          if (!res.data) {
            this.numberCtrl.setErrors({ invalidNumber: true });
            this.numberCtrl.markAsTouched({ onlySelf: true });
            return;
          }
          this.stage++;
        },
        (err) => {
          console.log('verify number error:', err);
          this.alert.alertFailure();
        },
      );
    } else if (this.stage === 2) {
      // 核对验证码
      if (this.codeCtrl.invalid) {
        this.codeCtrl.markAsTouched({ onlySelf: true });
        return;
      }
      const _url = 'v3/forgetpassword/verifycode';
      const _params = { userId: this.trustedUser.userId, code: this.codeCtrl.value };
      this.verifyData(_url, _params).subscribe(
        (res: ServerResponse) => {
          if (res.code === ResponseCode.Warning) {
            return;
          }
          if (!res.data) {
            this.codeCtrl.setErrors({ invalidCode: true });
            this.codeCtrl.markAsTouched({ onlySelf: true });
            return;
          }
          this.passwordForm = this.createPwdForm();
          this.stage++;
        },
        (err) => {
          console.log('verify code error:', err);
          this.alert.alertFailure();
        },
      );
    }
  }

  reset() {
    this.stage = 0;
    this.usernameCtrl.reset();
    this.numberCtrl.reset();
    this.codeCtrl.reset();
    if (this.passwordForm) {
      this.passwordForm.reset();
    }
  }

  gotoLogin() {
    this.router.navigate(['/passport/login']);
  }

  done() {
    if (this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    }
    const _url = 'v3/forgetpassword/changepassword';
    const _params = { userId: this.trustedUser.userId, password: this.passwordForm.value.password };
    this.verifyData(_url, _params).subscribe(
      (res: ServerResponse) => {
        if (res.code === ResponseCode.Warning) {
          return;
        }
        if (!res.data) {
          const pwdCtrl = this.passwordForm.get('password');
          pwdCtrl.setErrors({ invalidPassword: true });
          pwdCtrl.markAsTouched({ onlySelf: true });
          return;
        }
        this.isDone = true;
        this.reset();
        this.counter = 3;
        this.jumpTimer = window.setInterval(() => {
          if (this.counter > 0) {
            this.counter--;
            if (this.counter === 0) {
              this.gotoLogin();
            }
          } else {
            window.clearInterval(this.jumpTimer);
          }
        }, 1000);
      },
      (err) => {
        console.log('resend verify code error:', err);
        this.alert.alertFailure();
      },
    );
  }

  resendVerifyCode() {
    const _url = 'v3/forgetpassword/checknumber';
    const _params = { userId: this.trustedUser.userId, number: this.numberCtrl.value };
    this.rest.postJson(_url, _params).subscribe(
      (res: ServerResponse) => {
        if (res.code === ResponseCode.Warning || !res.data) {
          return;
        }
        this.alert.alertInfo('FORGET_PWD_HAS_BEEN_SENT');
      },
      (err) => {
        console.log('resend verify code error:', err);
        this.alert.alertFailure();
      },
    );
  }

  getPasswordErrorMessage(): string {
    const passwordCtrl = this.passwordForm.get('password');
    if (passwordCtrl.hasError('required')) {
      return 'COMMON_TEXT_REQUIRED';
    }
    if (passwordCtrl.hasError('minlength') || passwordCtrl.hasError('maxlength')) {
      return 'COMMON_TEXT_LENGTH_INCORRECT';
    }
    if (passwordCtrl.hasError('invalidPassword')) {
      return this.passwordRuleKey;
    }
    return '';
  }
}
